import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, InputGroup, Modal, OverlayTrigger, Popover, Row, Spinner } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../../Helper/ErrorHandler";
import { format, formatDistance, parseISO } from "date-fns";
import { de, enUS } from "date-fns/locale";
import usePalette from "../../../hooks/usePalette";
// import { writeFile } from "xlsx";
import json2csv from 'json2csv';
import { HelpCircle } from "react-feather";
import Chart from "react-apexcharts";
import Select from "react-select";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { utcToZonedTime } from "date-fns-tz";
import WatchTowerList from "../../Watchtower/WatchTowerList";

const AssetDigitalTwinChartModal = ({ desired_start_time, desired_end_time, asset_data, asset_id, desired_iot_node_id, show, handleClose }) => {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

  // const startOfToday = startOfDay(new Date())
  const aWeekAgo = new Date(new Date() - 24 * 60 * 60 * 1000 * 7) // new Date(new Date().getFullYear(), (new Date()).getMonth() - 1, 2)

  const startDate = new Date(new Date() - 12 * 60 * 60 * 1000)
  const now = new Date()

  const [PeriodStartDatePicker, setPeriodStartDatePicker] = useState(startDate);
  const [PeriodEndDatePicker, setPeriodEndDatePicker] = useState(now);
  // const [PeriodStartDate, setPeriodStartDate] = useState(zonedTimeToUtc(startOfToday).toISOString());
  // const [PeriodStartDate, setPeriodStartDate] = useState();
  // const [PeriodEndDate, setPeriodEndDate] = useState();

  // const [Entries, setEntries] = useState(100);
  // const [clustering_unit, setClustering_unit] = useState();
  // const [ShowTrendView, setShowTrendView] = useState(false);
  // const [ChartDataClustered, setChartDataClustered] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [chartType, setChartType] = useState();

  const [showRawData, setShowRawData] = useState(false);

  const [showAnnotations, setShowAnnotations] = useState(true);
  const [CustomAnnotations, setCustomAnnotations] = useState([]);
  const [CustomMarkers, setCustomMarkers] = useState([]);


  const [showInOneChart, setShowInOneChart] = useState(true);
  /// COMPOSER
  const [WatchtowerMetrics, setWatchtowerMetrics] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  // Handler for when the selection changes
  const handleSelectChange = (selected) => {
    setSelectedOptions(selected || []); // Update the state with the selected options
    fetchDataClustered(selected, null, null)
  };

  function resetFields() {

    setSelectedOptions([]);
    setCompareCharts([]);

    setPeriodStartDatePicker(startDate)
    setPeriodEndDatePicker(now)
    // setEntries(100);
    // setClustering_unit("auto");
  }

  const [foundIoTNodeData, setFoundIoTNodeData] = useState();

  function handleChangeDatesCurrentMonth() {
    const startOfLastMonth = new Date(new Date().getFullYear(), (new Date()).getMonth(), 1)
    setPeriodStartDatePicker(startOfLastMonth)
    setPeriodEndDatePicker(now)

    fetchDataClustered(null, startOfLastMonth, now)
  }

  // Get the browser's current time zone
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [selectedTimeZone, setSelectedTimeZone] = useState(browserTimeZone);

  function handleChangeTimeZone(event) {
    setSelectedTimeZone(event.target.value);
  }

  function handleChangeDates7Days() {
    // const startOfLastMonth = new Date(new Date().getFullYear(), (new Date()).getMonth(), 1)
    setPeriodStartDatePicker(aWeekAgo)
    setPeriodEndDatePicker(now)
    fetchDataClustered(null, aWeekAgo, now)
  }

  function handleChangeDates28Days() {
    let time30days = new Date(new Date() - 24 * 60 * 60 * 1000 * 28)
    setPeriodEndDatePicker(now)
    fetchDataClustered(null, time30days, now)
  }

  function handleChangeDates24h() {
    let time24hago = new Date(new Date() - 24 * 60 * 60 * 1000)
    setPeriodStartDatePicker(time24hago)
    setPeriodEndDatePicker(now)
    fetchDataClustered(null, time24hago, now)
  }

  function handleChangeDates6h() {
    let time24hago = new Date(new Date() - 6 * 60 * 60 * 1000)
    setPeriodStartDatePicker(time24hago)
    setPeriodEndDatePicker(now)
    fetchDataClustered(null, time24hago, now)
  }

  function handleChangeDates1h() {
    let time1hago = new Date(new Date() - 1 * 60 * 60 * 1000)
    setPeriodStartDatePicker(time1hago)
    setPeriodEndDatePicker(now)
    fetchDataClustered(null, time1hago, now)
  }

  useEffect(() => {

    resetFields();

    setIsLoading(true);

    // Ensure chart is rendered before interacting
    setTimeout(() => {
      // Your chart interactions or event binding here
      console.log("Chart is ready for interaction.");
    }, 500); // Delay to ensure rendering

    // console.log("Now showing modal", foundIoTNodeData, foundIoTNodeData?.display_name)

    setTitle(`${t(foundIoTNodeData?.log_type?.technical_name || foundIoTNodeData?.log_type?.display_name || "n/a")} (${renderUnit(foundIoTNodeData?.log_type?.unit)})`)

    setChartType(foundIoTNodeData?.chartType)

    let mainSelected = [{
      value: foundIoTNodeData?.log_type?.plc_node_id, // or any unique identifier
      label: `${foundIoTNodeData?.log_type?.technical_name || foundIoTNodeData?.log_type?.display_name || foundIoTNodeData?.log_type?.plc_node_id} (${renderUnit(foundIoTNodeData?.log_type?.unit) || "-"})` // fallback to plc_node_id if names are null
    }]
    setSelectedOptions(mainSelected || []); // Update the state with the selected options
    fetchDataClustered(mainSelected, desired_start_time || null, desired_end_time || null)

    setError({ showMessage: false, errorData: null })

    // axios
    //   .get(getCoreBaseURL() + "/openapi.json")
    //   .then(function (response) {
    //     // handle success
    //     console.log(response.status, "Loaded JSON");
    //     setClusterUnit(response.data["components"]["schemas"]["TimeClusteringUnitExt"]["enum"]);
    //   })
    //   .catch(function (error) {
    //     // handle error
    //     console.log(error);
    //   });

    // handleSubmitClustered(null, null, null)

  }, [foundIoTNodeData]);

  useEffect(() => {

    if (show) {

      setIsLoading(true);

      axios
        .get("/assets/" + asset_id + "/iot_nodes")
        .then(function (response) {
          // handle success
          console.log(response.status, "/iot_nodes");

          // console.log("Desired iot_node_id:", desired_iot_node_id)
          setFoundIoTNodeData(response.data?.find((iot_node_id_data) => iot_node_id_data?.id === desired_iot_node_id))
          console.log("Showing data for ------> ", foundIoTNodeData?.log_type?.technical_name, "desired", desired_iot_node_id)

          const formattedOptions = response.data?.filter((item) => item.log_type.unit !== "bool")?.sort((a, b) => (a.log_type.technical_name > b.log_type.technical_name)).map(item => ({
            value: item.log_type.plc_node_id, // or any unique identifier
            label: `${item?.log_type?.technical_name || item?.log_type?.display_name || item.log_type?.plc_node_id} (${renderUnit(item.log_type?.unit) || "-"})` // fallback to plc_node_id if names are null
          }));

          // setSelectedMetrics([{ value: data?.title, label: data?.plc_node_id }])
          setWatchtowerMetrics(formattedOptions);

          // set_log_type_number(response.data?.find(element => element.plc_node_id === props?.plc_node_id)?.log_type_number)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          setIsLoading(false);
        });
    }
  }, [show]);

  function renderUnit(unit) {

    switch (unit) {
      case "°C":
        return i18n.language === "us" ? "°F" : "°C"
      case "bar":
        return i18n.language === "us" ? "PSI" : "bar"
      default:
        return unit
    }
  }

  function reloadData(event) {
    event.preventDefault(); // Prevent the default form submission behavior
    fetchDataClustered(null, null, null)
  }
  const [compareCharts, setCompareCharts] = useState([]);

  const fetchDataClustered = async (selected, start, end) => {

    setIsLoading(true);

    const plcNodeIds = selected || selectedOptions || [];

    const requests = plcNodeIds?.map(({ value, label }, index) =>
      axios
        .get(`/assets/${asset_id}/asset_logs/clustered`, {
          params: {
            "period_start": start || PeriodStartDatePicker.toISOString(),
            "period_end": end || PeriodEndDatePicker.toISOString(),
            "fill_gaps": showRawData ? "null" : "lv",
            // "clustering_unit": clustering_unit,
            "plc_node_id": value,
            "unit_language": i18n.language === "us" ? "us" : "de",
            "desc": false,
          },
        })
    );

    try {
      const responses = await Promise.all(requests);

      // Process the responses and update the state
      const newChartsData = responses.map((response, index) => {

        // console.log("------->", response.data)

        return ({
          label: plcNodeIds[index].label,
          data: response.data
        })
      });
      // Update the allCharts state

      setSubtitle(t("Time span") + ": " + (newChartsData[0]?.data.length > 0 ? formatDistance(parseISO(newChartsData[0]?.data?.[0]?.interval_start), parseISO(newChartsData[0]?.data?.[newChartsData[0]?.data.length - 1]?.interval_start), { locale: i18n.language === "de" ? de : null }) : "-"))

      setCompareCharts(newChartsData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const [combinedChartData, setCombinedChartData] = useState([]);

  useEffect(() => {
    var newChartData = [];

    compareCharts.forEach(chart => {
      newChartData.push({
        name: chart?.label,
        type: "line",
        data: chart?.data
      });
    });

    setCombinedChartData(newChartData);
  }, [compareCharts, showInOneChart, title, t]);

  useEffect(() => {
    console.log("Changed show raw data")

    fetchDataClustered(null, desired_start_time || null, desired_end_time || null)

  }, [showRawData]);

  const UsageChart = (props) => {
    const palette = usePalette();
    const { t, i18n } = useTranslation();

    const handleZoom = (chartContext, { xaxis }) => {
      const zoomedRangeMin = xaxis.min;
      const zoomedRangeMax = xaxis.max;

      let start = compareCharts[0]?.data?.[zoomedRangeMin]?.interval_start;
      let end = compareCharts[0]?.data?.[zoomedRangeMax]?.interval_start;

      if (start && end) {
        fetchDataClustered(null, start, end);
        console.log("Zooming")
      } else {
        console.error("Invalid data range for zooming.");
      }
    };

    const data = props?.data?.map(entry => ({
      name: entry?.name,
      type: entry?.type,
      data: entry?.data?.map(d => d.amount)
    })) || [];

    // Generate discrete markers configuration for each data point
    const generateDiscreteMarkers = (data) => {
      let discreteMarkers = [];

      if (compareCharts?.length > 1) {
        console.log("Don't show markers!")
      } else if (foundIoTNodeData?.upper_limit || foundIoTNodeData?.lower_limit) {
        data.forEach((series, seriesIndex) => {
          series.data.forEach((value, dataIndex) => {
            if (value > foundIoTNodeData?.upper_limit) {
              // console.log("--->", value, foundIoTNodeData?.upper_limit)
              discreteMarkers.push({
                seriesIndex: seriesIndex,
                dataPointIndex: dataIndex,
                size: 2,
                fillColor: '#FF4560',
                strokeColor: '#FF4560',
                shape: "circle"
              });
            } else if (value < foundIoTNodeData?.lower_limit) {
              // console.log("->", value, foundIoTNodeData?.lower_limit)
              discreteMarkers.push({
                seriesIndex: seriesIndex,
                dataPointIndex: dataIndex,
                size: 1.5,
                fillColor: '#FFA500',
                strokeColor: '#FFA500',
                shape: "circle"
              });
            }
          });
        })
      };

      // console.log("---->", discreteMarkers.length)
      return discreteMarkers;
    };

    const generateAnnotations = () => {
      let annotations = [];

      if (foundIoTNodeData?.upper_limit) {

        annotations.push({
          y: foundIoTNodeData?.upper_limit,
          y2: foundIoTNodeData?.upper_limit * 2,
          borderColor: '#000',
          fillColor: '#FF4560',
          opacity: 0.1,
          label: {
            text: '',
            style: {
              color: '#fff',
              background: '#FF4560'
            }
          }
        }, {
          y: foundIoTNodeData?.upper_limit,
          borderColor: '#FF4560',
          label: {
            borderColor: '#FF4560',
            style: {
              color: '#fff',
              background: '#FF4560'
            },
            text: t('Max. operating limit')
          }
        });
      }

      if (foundIoTNodeData?.lower_limit) {

        annotations.push({
          y: 0,
          y2: foundIoTNodeData?.lower_limit,
          borderColor: '#000',
          fillColor: '#FFA500',
          opacity: 0.1,
          label: {
            text: '',
            style: {
              color: '#fff',
              background: '#FFA500'
            }
          }
        }, {
          y: foundIoTNodeData?.lower_limit,
          borderColor: '#FFA500',  // Changed to orange
          label: {
            borderColor: '#FFA500',  // Changed to orange
            style: {
              color: '#fff',
              background: '#FFA500'  // Changed to orange
            },
            text: t('Min. operating limit')
          }
        })
      }

      // console.log("---> All annotations: ", annotations)

      return annotations
    }

    const discreteMarkers = generateDiscreteMarkers(data);

    // Only show annotations if there is one data set
    const customAnnotations = data?.length === 1 ? generateAnnotations() : []

    const getTickAmount = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth > 1200) {
        return 15; // More ticks for larger screens
      } else if (screenWidth > 768) {
        return 7; // Medium amount of ticks for tablet screens
      } else {
        return 5; // Fewer ticks for mobile screens
      }
    };

    const options = {
      chart: {
        animations: {
          enabled: false,
        },
        toolbar: {
          show: true
        },
        events: {
          // click: (event) => {
          //   const target = event?.target;
          //   const parentNode = target?.parentNode;

          //   if (parentNode && parentNode.classList) {
          //     // Only add the class if the target and parentNode exist and have classList
          //     parentNode.classList.add('some-class');
          //   } else {
          //     console.warn('No valid target or parentNode for the event.');
          //   }
          // },
          zoomed: handleZoom,  // Adding the zoom event handler here
        },
      },
      colors: ['#1D3A24', '#RDA701'], // Example colors
      stroke: {
        width: [2, 2, 2, 2, 2, 2],
      },
      plotOptions: {
        bar: {
          columnWidth: "100%",
        },
      },
      fill: {
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      labels: props?.labels,
      annotations: {
        yaxis: customAnnotations
      },
      markers: {
        size: 0.1,
        fillColor: palette.info,
        strokeColor: palette.info,
        shape: "circle",
        discrete: discreteMarkers,
      },
      xaxis: {
        tickAmount: getTickAmount(),
        labels: {
          trim: false,
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: palette.black,
          },
          formatter: function (value) {
            return value?.replace(":00", "");
          },
        },
      },
      yaxis: {
        labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: palette.black,
          },
          formatter: (value) => value.toLocaleString(i18n.language === "de" ? de : "en", { style: "decimal", maximumFractionDigits: 2 }),
        },
      },
      legend: {
        position: 'top',
        labels: {
          colors: palette.black,
          useSeriesColors: false
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            return y?.toLocaleString(i18n.language === "de" ? de : "en", { style: "decimal", maximumFractionDigits: 2 });
          },
        },
      },
      colors: [
        palette.info,
        palette.success,
        palette.warning,
        palette.primary,
        palette.danger,
      ],
    };

    const showLoadingSpinner = () => {
      return (
        <div className="text-center">
          <Spinner animation="border" variant="secondary" size="sm" /> {t("Loading data")}
        </div>
      )
    }


    function handleSetShowRaw(event) {
      setShowRawData(!showRawData)

      // handleSubmitClustered(null, PeriodStartDatePicker.toISOString(), PeriodEndDatePicker.toISOString())
    }



    return (
      <div>
        <Card className="flex-fill w-100">
          <Card.Header>
            <Row>
              <Col xs="auto" className="d-none d-sm-block">
                <Card.Title tag="h5">{t(props?.title)}</Card.Title>
                <h6>{subtitle}</h6>
              </Col>
              <Col xs="auto" className="ms-auto text-end">
                {/* <Button
              variant="outline-primary"
              className="shadow-sm me-1"
              size="sm"
              onClick={() => resetFields()}>
              {t("Reset")}
            </Button> */}
                {/* <Button size="sm" variant="outline-primary" className="shadow-sm me-1" onClick={handleChangeDatesCurrentMonth}>
              {t("Current Month")}
            </Button> */}

                <div className="mb-1">
                  <Button size="sm" variant="outline-primary" className="shadow-sm me-1 mt-1" onClick={handleChangeDates28Days}>
                    {t("Last 28d")}
                  </Button>
                  <Button size="sm" variant="outline-primary" className="shadow-sm me-1 mt-1" onClick={handleChangeDates7Days}>
                    {t("7d")}
                  </Button>
                  <Button size="sm" variant="outline-primary" className="shadow-sm me-1 mt-1" onClick={handleChangeDates24h}>
                    {t("24h")}
                  </Button>
                  <Button size="sm" variant="outline-primary" className="shadow-sm me-1 mt-1" onClick={handleChangeDates6h}>
                    {t("6h")}
                  </Button>
                  <Button size="sm" variant="outline-primary" className="shadow-sm me-1 mt-1" onClick={handleChangeDates1h}>
                    {t("1h")}
                  </Button>
                  {/* <Button
                  onClick={() => downloadFile(combinedChartData, 'csv', [['interval_start', 'amount'], ['interval_start', 'amount']])}
                  variant="outline-primary"
                  size="sm"
                  className="shadow-sm mt-1"
                >
                  <Download className="feather" /> Download .csv
                </Button> */}
                </div>

              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            {
              isLoading ?

                showLoadingSpinner() :

                props?.data.length === 0

                  ?

                  <h6 className="card-subtitle text-muted">
                    {t("No data available.")}
                  </h6>

                  :

                  <div className="chart">
                    <Chart
                      options={options}
                      series={data}
                      type="line"
                      height="340"
                    />


                    <Row>
                      <Col xs="auto" className="ms-auto text-end mt-n1">
                        <span style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                          {compareCharts?.length > 1 && <Form.Check
                            checked={showInOneChart}
                            onChange={() => setShowInOneChart(!showInOneChart)}
                            label={t("Show in one chart")}
                            type="checkbox" />}
                          <Form.Check
                            type="checkbox"
                            onChange={handleSetShowRaw}
                            defaultChecked={showRawData}
                            label={t("Raw data points")}
                          />
                          <OverlayTrigger
                            placement='auto'
                            overlay={
                              <Popover>
                                <Popover.Header as='h3'>Info</Popover.Header>
                                <Popover.Body>
                                  {t("Gaps do not necessarily indicate missing data. They usually indicate that a value did not change within an interval, since only value changes are being reported and displayed for performance reasons.")}
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <HelpCircle className="feather" />
                          </OverlayTrigger>
                        </span>
                      </Col>
                    </Row>
                  </div>
            }

            {/* <Line data={data} options={options} /> */}
          </Card.Body>
        </Card>
      </div>
    );
  };

  const downloadFile = (datasets, format, allFields) => {
    if (format === 'csv') {
      let combinedData = [];

      // Iterate over each dataset
      datasets.forEach((dataset, index) => {
        const fields = Array.isArray(allFields[index]) ? allFields[index] : [];
        dataset.data.forEach(item => {
          let entry = { name: dataset.name };
          // Use fields to map values from the item
          fields.forEach(field => {
            entry[field] = item[field];
          });
          combinedData.push(entry);
        });
      });

      // Parse the combined data into CSV
      try {
        const csvData = json2csv.parse(combinedData, { fields: allFields.flat(), delimiter: ';' });
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `combined_data_export.csv`;
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error generating CSV:', error);
      }
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'white' // Set background color to white
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'white' // Set menu background color to white
    })
    // You can add more custom styles for other parts if needed
  };

  return (
    <Modal size="xl" show={show}
      style={{ zIndex: 1050 }} // Higher z-index to ensure it appears above the first modal
      onHide={handleClose} >
      <Modal.Header closeButton>{t("Data View")}</Modal.Header>
      <Modal.Body className="m-3" style={{ background: "#f5f5f5", padding: "20px" }}>
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="flex-fill">
              <Card.Body>
                <Form onSubmit={reloadData}>
                  <Row>
                    <Col md="6">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("Date range")}</Form.Label><br />
                        <InputGroup className='flex-nowrap rounded pe-5'>
                          {/* <InputGroup.Text>
                            <i className='fi-calendar'></i>
                          </InputGroup.Text> */}
                          <Form.Control
                            as={DatePicker}
                            showTimeSelect
                            selectsStart
                            startDate={PeriodStartDatePicker}
                            endDate={PeriodEndDatePicker}
                            selected={PeriodStartDatePicker}
                            locale={i18n.language === "de" ? de : null}
                            onChange={(date) => setPeriodStartDatePicker(date)}
                            dateFormat={i18n.language === "de" ? 'MMMM d, yyyy HH:mm' : 'MMMM d, yyyy h:mm aa'}
                            className='rounded-0'//'rounded pe-5'
                            placeholderText='From date'
                          />

                          <Form.Control
                            as={DatePicker}
                            showTimeSelect
                            selectsEnd
                            startDate={PeriodStartDatePicker}
                            endDate={PeriodEndDatePicker}
                            selected={PeriodEndDatePicker}
                            locale={i18n.language === "de" ? de : null}
                            minDate={PeriodStartDatePicker}
                            onChange={(date) => setPeriodEndDatePicker(date)}
                            dateFormat={i18n.language === "de" ? 'MMMM d, yyyy HH:mm' : 'MMMM d, yyyy h:mm aa'}
                            className='rounded-0' // 'rounded pe-5'
                            placeholderText='To date'
                          />

                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col md="3" >
                      <Form.Group className="mb-3">
                        <Form.Label>{t("Time zone")}</Form.Label>
                        <Form.Select onChange={event => handleChangeTimeZone(event)}>
                          <option key={"browser"} value={browserTimeZone}>{t("Your time") + " (" + browserTimeZone + ")"}</option>
                          <option key={"asset_local"} disabled={!asset_data?.timezone} value={asset_data?.timezone}>{t("Asset time") + " (" + (asset_data?.timezone || "Bitte beim Asset spezifizieren") + ")"}</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md="3" xs="auto" className="ms-auto text-end mt-n1">
                      <br />
                      <Button variant="outline-primary" className="shadow-sm me-1 mt-2" onClick={() => resetFields()}>
                        {t("Reset")}
                      </Button>
                      <Button
                        className="me-1 mt-2"
                        type="submit"
                        variant="primary">
                        {t("Update")}
                      </Button>
                    </Col>
                    {/* <Col>
                              <Form.Group className="mb-3">
                                <Form.Label>{t("Entries")}</Form.Label>
                                <Form.Control
                                  value={Entries}
                                  onChange={e => setEntries(e.target.value)}
                                  type="number"
                                />
                              </Form.Group>
                            </Col> */}
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Form.Group className="mb-3">
              <Form.Label>{t("Compare Metrics")}</Form.Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                options={WatchtowerMetrics}
                isMulti
                styles={customStyles}
                placeholder={t("Select Metric")}
                value={selectedOptions} // Set the value to the selected options
                onChange={handleSelectChange}
              />
            </Form.Group>

          </Col>
        </Row>

        {showInOneChart
          ? <Row>
            <Col md="12">
              <UsageChart
                unit={renderUnit(foundIoTNodeData?.log_type?.unit)}
                chartType={foundIoTNodeData?.chartType || "line"}
                title={title}
                isMainChart
                format={"decimal"}
                data={combinedChartData}
                labels={combinedChartData[0]?.data?.map((entry) => {
                  return format(utcToZonedTime(parseISO(entry.interval_start), selectedTimeZone), i18n.language === "de" ? "d MMM, HH:mm:ss" : "d MMM, hh:mm:ss a", { timeZone: selectedTimeZone, locale: i18n.language === "de" ? de : enUS })
                })
                }
              />
            </Col>
          </Row>
          : <Row>
            <Col md="12">
              {compareCharts?.map((item, indx) => {
                return <UsageChart key={indx}
                  max={Math.round(Math.max(...item?.data?.map(o => o.amount)) * 1.10)}
                  format={"decimal"}
                  title={item?.label}
                  data={[{
                    name: t(`${item?.label}`),
                    type: "line",
                    data: item?.data
                  }
                  ]}
                  labels={item?.data?.map((entry) => { return format(utcToZonedTime(parseISO(entry.interval_start), selectedTimeZone), i18n.language === "de" ? "d MMM, HH:mm:ss" : "d MMM, hh:mm:ss a", { timeZone: selectedTimeZone, locale: i18n.language === "de" ? de : enUS }) })}
                />
              })}
            </Col>
          </Row>}

        <Row>
          <WatchTowerList slim showAddButton watchtowers={foundIoTNodeData?.watchtowers} selected_asset_id={asset_data?.id} selected_iot_node={foundIoTNodeData} />
        </Row>

        <hr />
        <br />
        <Row>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button variant="primary" onClick={handleClose}>
              {t("Close")}
            </Button>
          </Col>
        </Row>
      </Modal.Body >
    </Modal >
  );
};

export default AssetDigitalTwinChartModal;
