import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import React from "react";
import Chart from "react-apexcharts";
import { Card, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import usePalette from "../../../hooks/usePalette";

const CycleTimesChart = (props) => {
  const palette = usePalette();
  const { t, i18n } = useTranslation();

  const data = [
    {
      name: t("Cycle Time"),
      type: 'line',
      data: props.cycleTimes?.sort((a, b) => (a.interval_start > b.interval_start)).map((e) => { return e.cycle_time }),
    },
    {
      name: t("Process Time"),
      type: 'line',
      data: props.cycleTimes?.sort((a, b) => (a.interval_start > b.interval_start)).map((e) => { return e.process_time }),
    },
  ];

  const showLoadingSpinner = () => {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="secondary" size="sm" /> {t("Loading data")}
      </div>
    )
  }

  const options = {
    chart: {
      animations: {
        enabled: true,
      },
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 4,
      // colors: ["transparent"],
    },
    labels: props.cycleTimes?.map((e) => { return format(parseISO(e.interval_start), "E, d MMM yyyy", { locale: i18n.language === "de" ? de : null }) }),
    xaxis: {
      // type: "time",
      // max: 100,
      labels: {
        show: true,
        align: 'right',
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: palette.black,
        },
      },
    },
    yaxis:
    {
      title: {
        text: "Durschnittliche Takzeit in Sekunden",
      },
      labels: {
        show: true,
        align: 'right',
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: palette.black,
        },
        formatter: (value) => { return value?.toLocaleString(i18n.language === "de" ? de : "en", { maximumFractionDigits: 0, }) },
      },
    },
    legend: {
      position: 'top',
      labels: {
        colors: palette.black,
        useSeriesColors: false
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val?.toLocaleString(i18n.language === "de" ? de : "en", { maximumFractionDigits: 1, }) + " " + t("seconds")
          // return formatDuration(intervalToDuration({ start: 0, end: val * 1000 }), { format: ['days', 'hours', 'minutes', "seconds"], locale: i18n.language === "de" ? de : null });
        },
      },
    },
    colors: [
      palette.primary,
      palette.success,
      // palette.danger,
      // palette.success,
      // palette.primary,
      // palette.primary,
    ],
  };

  return (

    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title tag="h5">{t("Average Cycle Time")}</Card.Title>
      </Card.Header>
      <Card.Body>
        {
          props.isLoading === true ?

            showLoadingSpinner() :

            props.cycleTimes.length === 0

              ?

              <h6 className="card-subtitle text-muted">
                {t("No cycle times available.")}
              </h6>

              :

              <div className="chart">
                <Chart options={options} series={data} height="350" />
              </div>
        }
      </Card.Body>
    </Card>
  );
};

export default CycleTimesChart;
