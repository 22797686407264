import { Card, Table } from "react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";
import { Download, Trash } from "react-feather";
import axios from "axios";
import UserDataManager from "../Helper/UserDataManager";

const FileTable = ({ data, title, parent_id, parent_type }) => {
  const { t } = useTranslation();

  function handleDelete(e, file_id) {
    e.preventDefault();
    axios
      .delete(parent_type + parent_id + "/files/" + file_id)
      .then(function (response) {
        console.log(response.status, "Load");
        // handle success
        refreshPage();
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  const renderTable = () => {

    return data?.map((file) => {
      return (
        <tr key={file.id}>
          <td>{file.title}</td>
          <td>{file.file_type}</td>
          {/* <td>{file.created_at}</td> */}
          <td className="table-action">
            {/* <Search alt="Show" className="align-middle" size={18} onClick={() => window.open(file.url, '_blank')} style={{ cursor: "pointer" }} />
            &nbsp;
            &nbsp; */}
            <Download alt="Download" className="align-middle" size={18} onClick={() => open(file.url)} style={{ cursor: "pointer" }} />
            &nbsp;
            &nbsp;
            {UserDataManager.getInstance().isAdmin() && <Trash alt="Delete" className="align-middle" size={18} onClick={e => window.confirm(t("Are you sure you wish to delete this file?")) && handleDelete(e, file.id)} style={{ cursor: "pointer" }} />}
          </td>
        </tr>
      );
    });
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          {t(title)}
        </Card.Title>
      </Card.Header>
      {/* <Card.Body> */}
      {
        data !== null && data?.length > 0
          ?
          <Table responsive hover>
            <thead>
              <tr>
                <th scope="col">{t("Title")}</th>
                <th scope="col">{t("File type")}</th>
                {/* <th scope="col">{t("Uploaded")}</th> */}
              </tr>
            </thead>
            <tbody>{renderTable()}</tbody>
          </Table>
          :
          <Card.Body>
            <p>{t("No data found.")}</p>
          </Card.Body>
      }
      {/* </Card.Body> */}
    </Card>
  );
};


function open(url) {
  const win = window.open(url, "_blank");
  if (win != null) {
    win.focus();
  }
}

function refreshPage() {
  window.location.reload(false);
}

export default FileTable;
