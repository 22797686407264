import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import axios from "axios";
import NotyfContext from "../../contexts/NotyfContext";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";

const EditPlannedProduction = () => {
  const notyf = useContext(NotyfContext);
  const navigate = useNavigate();
  let { id } = useParams();
  const { t } = useTranslation();
  const [usageArray, setUsageArray] = useState([]);
  var usageLogs = [];
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

  function handleChangePlannedProduction(event) {
    console.log(event.target.id, event.target.value);

    usageLogs = [];
    usageLogs = usageArray;

    if (event.target.id === undefined || event.target.id === "") {
      usageLogs[0]["planned_usage"] = parseInt(event.target.value);
    } else {
      usageLogs[event.target.id]["planned_usage"] = parseInt(event.target.value);
    }

    setUsageArray(usageLogs)
  }

  useEffect(() => {
    axios
      .get("/webservice/assets/" + id + "/base")
      .then(function (response) {
        // handle success
        console.log(response.status, "Loaded asset");
        setUsageArray(response.data?.planned_usages);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
    }, []);

  function handleSubmit(e) {
    e.preventDefault();

    setError({ showMessage: false, errorData: null })

    axios
      .put("/assets/" + id + "/planned_usages", usageArray)
      .then(function (response) {
        // handle success
        navigate("/assets/" + id);
        console.log(response.status, "Edited Planned_usages");

        notyf.open({
          type: "success",
          message: t("Planned production updated."),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })

      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  const renderRows = () => {

    if (usageArray?.length !== 0) {
      return usageArray?.map((entry, index) => {
        return (
          <Row>
            <Col md={1}>
              <Form.Group className="mb-3">
                {/* <Form.Control value={index + 1} /> */}
                <Form.Label>{index + 1}</Form.Label>
              </Form.Group>
            </Col>
            <Col md={1}>
            </Col>
            <Col md={2}>
              <Form.Group className="mb-3">
                {/* <Form.Control
                  value={entry.month} /> */}
                <Form.Label>{entry.month}</Form.Label>

              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group className="mb-3">
                {/* <Form.Control
                  value={entry.year} /> */}
                <Form.Label>{entry.year}</Form.Label>
              </Form.Group>
            </Col>
            <Col md={1}>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Control
                  onChange={handleChangePlannedProduction}
                  id={index}
                  defaultValue={entry.planned_usage} />
              </Form.Group>
            </Col>
          </Row>
        );
      });
    } else {
      console.log("Empty!");
    }
  };

  return (
    <React.Fragment>
      <Helmet title={t("Edit asset")} />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">{t("Edit asset")}</h1>
        <Row>
          <Col lg="12">
            <Card>
              <Card.Header>
                <ErrorHandler error={error} />
                <br />
                <h5>{t("Edit planned production")}</h5>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={1}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("Number")}</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col md={1}>
                    </Col>
                    <Col md={2}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("Month")}</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("Year")}</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col md={1}>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("Planned Production")}</Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  {renderRows()}
                  <br />
                  <hr />
                  <br />
                  <Row>
                    <Col>
                      <Button variant="outline-primary" className="shadow-sm me-1" onClick={() => navigate(-1)}>
                        {t("Cancel")}
                      </Button>
                      <Button type="submit" variant="primary"
                      >
                        {t("Save")}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default EditPlannedProduction;