import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Card,
  Col,
  Container,
  Row,
  Breadcrumb,
  Form,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {
  Wifi,
  RefreshCw,
  Eye
} from "react-feather";
import { Button } from "react-bootstrap";
import usePalette from "../../hooks/usePalette";
import { endOfDay, format, parseISO, startOfDay, } from 'date-fns'
import { useTranslation } from "react-i18next";
import { de } from 'date-fns/locale';
import ErrorHandler from "../Helper/ErrorHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Bar } from "react-chartjs-2";
import { zonedTimeToUtc } from "date-fns-tz";
import OEEChart from "./Charts/OEEChart";
import OperatingTimesChart from "./Charts/OperatingTimesChart";
import PowerConsumptionChart from "./Charts/PowerConsumptionChart";
import { isMobile } from "react-device-detect";
import CycleTimesChart from "./Charts/CycleTimesChart";
import PLCNodeDataChart from "./Charts/PLCNodeDataChart";
import PLCNodeDataChartClustered from "./Charts/PLCNodeDataChartClustered";
import { getCoreBaseURL } from "../../config";

const AssetUsageDetails = ({ assetData, show }) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [operatingTimes, setOperatingTimes] = useState([]);
  const [powerData, setPowerData] = useState([]);
  const [cycleTimes, setCycleTimes] = useState([]);
  const [PeriodStartDatePicker, setPeriodStartDatePicker] = useState(''); // Initialize with an empty string or a default date
  const [PeriodEndDatePicker, setPeriodEndDatePicker] = useState(''); // Initialize with an empty string or a default date
  const [unit, setUnit] = useState("1 day");
  const fortnightAgo = new Date(startOfDay(new Date() - 24 * 60 * 60 * 1000 * 14)) // new Date(new Date().getFullYear(), (new Date()).getMonth() - 1, 2)
  const endOfToday = endOfDay(new Date())
  const [PeriodStartDate, setPeriodStartDate] = useState(zonedTimeToUtc(parseISO(fortnightAgo.toISOString().split('T')[0]), "GMT").toISOString());
  const [PeriodEndDate, setPeriodEndDate] = useState(zonedTimeToUtc(endOfToday).toISOString());

  const [OEE_Array, setOEE_Array] = useState([{
    "interval_start": "2022-05-23T15:43:24.230Z",
    "availability": 0,
    "performance": 0,
    "quality": 0,
    "oee": 0
  }]);
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [ClusteredUsageLogs, setClusteredUsageLogs] = useState([]);

  useEffect(() => {

    if (show) {
      resetFields();

      setIsLoading(true);

      handleSubmit(null)

      axios
        .get(getCoreBaseURL() + "/openapi.json")
        .then(function (response) {
          // handle success
          console.log(response.status, "/openapi");
          setUnits(response.data["components"]["schemas"]["TimeClusteringUnit"]["enum"]);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }

  }, [show]);

  function handleChangeStartDatePicker(event) {
    setPeriodStartDatePicker(event.target.value)
    setPeriodStartDate(zonedTimeToUtc(parseISO(event.target.value), "GMT").toISOString())
  }

  function handleChangeEndDatePicker(event) {
    setPeriodEndDatePicker(event.target.value)
    setPeriodEndDate(zonedTimeToUtc(parseISO(event.target.value), "GMT").toISOString())
  }

  function handleSubmit(item) {
    item?.preventDefault();

    setIsLoading(true);
    setError({ showMessage: false, errorData: null })

    axios
      .get("/assets/" + id + "/usage_logs/clustered", {
        params: {
          "asset_id": id,
          "period_start": PeriodStartDate,
          "period_end": PeriodEndDate,
          "clustering_unit": unit,
          "fill_gaps": true
        }
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "/usage_logs/clustered");
        setClusteredUsageLogs(response.data.sort((a, b) => (a.interval_start > b.interval_start)))
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });

    axios
      .get("/assets/" + id + "/state_periods/clustered", {
        params: {
          "asset_id": id,
          // "state": "",
          "period_start": PeriodStartDate,
          "period_end": PeriodEndDate,
          "clustering_unit": unit,
          "fill_gaps": true
        }
      })
      .then(function (response) {
        // handle success

        const times = response.data.sort((a, b) => (a.state_name > b.state_name))

        console.log(response.status, "/state_periods");
        setOperatingTimes(times)

      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });

    axios
      .get("/assets/" + id + "/oee", {
        params: {
          "asset_id": id,
          "period_start": PeriodStartDate?.split('.')[0],
          "period_end": PeriodEndDate?.split('.')[0]
        }
      })
      .then(function (response) {
        console.log(response.status, "/oee. Items: ", response.data.length);
        setOEE_Array(response.data.sort((a, b) => (a.interval_start > b.interval_start)))
        setIsLoading(false);
        // handle success
      })
      .catch(function (error) {
        // handle error
        setIsLoading(false);
        console.log(error);
      });

    axios
      .get("/webservice/assets/" + id + "/clustered_power_usage", {
        params: {
          "asset_id": id,
          "period_start": PeriodStartDate,
          "period_end": PeriodEndDate,
          "clustering_unit": unit,
        }
      })
      .then(function (response) {
        console.log(response.status, "/clustered_power_usage"); //JSON.stringify(response.data)

        setPowerData(response.data.sort((a, b) => (a.interval_start > b.interval_start)))
        setIsLoading(false);
        // handle success
      })
      .catch(function (error) {
        // handle error
        setIsLoading(false);
        console.log(error);
      });

    axios
      .get("/assets/" + id + "/usage_cycles/clustered_cycle_time/", {
        params: {
          "asset_id": id,
          "period_start": PeriodStartDate?.split('.')[0],
          "period_end": PeriodEndDate?.split('.')[0],
          "clustering_unit": unit,
        }
      })
      .then(function (response) {
        console.log(response.status, "/clustered_cycle_time", response.data.length);
        setCycleTimes(response.data.sort((a, b) => (a.interval_start > b.interval_start)))
        setIsLoading(false);
        // handle success
      })
      .catch(function (error) {
        // handle error
        setIsLoading(false);
        console.log(error);
      });
  }

  const UsageBarChart = (x_data, y_data, y_maxValue, title, style, description) => {
    const palette = usePalette();
    const { t } = useTranslation();

    const data = {
      labels: y_data,
      datasets: [
        {
          label: t("Units"),
          fill: true,
          backgroundColor: palette.primary,
          color: palette.primary,
          borderColor: palette.primary,
          borderDash: [4, 4],
          data: x_data,
        },
      ],
    };

    const options = {
      chart: {
        type: 'bar',
        height: 350
      },
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      tooltips: {
        intersect: false,
        callbacks: {
          label: function (tooltipItem, data) {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';
            if (label) {
              label += ': ';
            }
            label += tooltipItem.yLabel.toLocaleString(i18n.language === "de" ? de : "en", { maximumFractionDigits: 0, });
            return label
          }
        }

      },
      hover: {
        intersect: true,
      },
      plugins: {
        filler: {
          propagate: false,
        },
      },
      scales: {
        xAxes: [
          {
            reverse: false,
            gridLines: {
              color: "rgba(0,0,0,0.05)",
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              // stepSize: 1000,
              callback: function (value, index, ticks) {
                return value.toLocaleString();
              }
            },
            display: true,
            borderDash: [5, 5],
            gridLines: {
              color: "rgba(0,0,0,0)",
              fontColor: "#fff",
            },
            // max: max(AssetLogISTArray, AssetLogSOLLArray) * 1.5
          },
        ],
      },
    };

    return (
      <Card className="flex-fill">
        <Card.Header>
          <Card.Title tag="h5">{t("Asset Usage")}</Card.Title>
        </Card.Header>
        <Card.Body>
          {
            y_data != null && y_data.length > 0
              ?
              <div className="chart">
                <Bar data={data} options={options} />
                {/* <Chart options={options} series={data} type="bar" height="340" /> */}
              </div>
              :
              <Card.Body>
                <p>{t("No data found.")}</p>
              </Card.Body>
          }
        </Card.Body>
      </Card>
    );
  };

  const [units, setUnits] = useState([]);

  const loadUnits = () => {
    return units?.map((entry) => {
      return (
        <option key={entry} value={entry}>{entry}</option>
      );
    });
  };

  function resetFields() {

    setPeriodStartDatePicker(fortnightAgo.toISOString().split('T')[0])
    setPeriodEndDatePicker(endOfToday.toISOString().split('T')[0])

    setPeriodStartDate(zonedTimeToUtc(parseISO(fortnightAgo.toISOString().split('T')[0]), "GMT").toISOString())
    setPeriodEndDate(zonedTimeToUtc(endOfToday).toISOString())
    setUnit("1 day");
  }

  return (
    <>
      <Row>
        <Col md={"9"} className="d-flex">
          {UsageBarChart(
            ClusteredUsageLogs.map((entry) => { return entry.amount }),
            ClusteredUsageLogs.map((entry) => { return format(parseISO(entry.interval_start), "EEE dd MM, HH:mm", { locale: i18n.language === "de" ? de : null }) }),
            Math.max(...ClusteredUsageLogs.map(o => o.amount)) * 1.15, t("Units"))}
        </Col>
        <Col md={"3"} className="d-flex">
          <Card className="flex-fill">
            <Card.Header>
              <Card.Title tag="h5">{t("Asset Usage Configuration")}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Period start date")}</Form.Label>
                      <Form.Control
                        value={PeriodStartDatePicker}
                        // value={"2022-12-03"}
                        onChange={handleChangeStartDatePicker}
                        type="date"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Period end date")}</Form.Label>
                      <Form.Control
                        value={PeriodEndDatePicker}
                        // value={"2022-12-03"}
                        onChange={handleChangeEndDatePicker}
                        type="date"
                      />
                    </Form.Group>
                  </Col>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Unit/Interval")}</Form.Label>
                    <Form.Select
                      defaultValue={unit}
                      onChange={e => setUnit(e.target.value)}>
                      {loadUnits()}
                    </Form.Select>
                  </Form.Group>
                </Row>
                <br />
                <Row>
                  <Col>
                    <Button className="mt-1 shadow-sm me-1" variant="outline-primary" onClick={() => resetFields()}>
                      {t("Reset")}
                    </Button>
                    <Button className="mt-1" type="submit" variant="primary">
                      {t("Query")}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {assetData?.usage_enabled && <Row>
        <Col>
          <CycleTimesChart cycleTimes={cycleTimes} isLoading={isLoading} />
        </Col>
      </Row>}

      <Row>
        <Col>
          <OperatingTimesChart operatingTimes={operatingTimes} isLoading={isLoading} />
        </Col>
      </Row>

      <Row>
        <Col>
          <OEEChart OEE_Array={OEE_Array} isLoading={isLoading} />
        </Col>
      </Row>

      <Row>
        <Col>
          <PowerConsumptionChart PowerData={powerData} isLoading={isLoading} />
        </Col>
      </Row>
    </>
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default AssetUsageDetails;
