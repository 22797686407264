import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import React from "react";
import Chart from "react-apexcharts";
import { Card, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import usePalette from "../../../hooks/usePalette";

const PowerConsumptionChart = (props) => {
  const palette = usePalette();
  const { t, i18n } = useTranslation();

  const data = [
    {
      name: t("Stromverbrauch am Tag"),
      type: 'bar',
      data: props.PowerData?.sort((a, b) => (a.interval_start > b.interval_start)).map((e) => { return e.power_usage }),
    },
    {
      name: t("Stromverbrauch pro produzierter Einheit"),
      type: 'line',
      data: props.PowerData?.sort((a, b) => (a.interval_start > b.interval_start)).map((e) => { return e.power_per_use }),
    },
  ];

  const showLoadingSpinner = () => {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="secondary" size="sm" /> {t("Loading data")}
      </div>
    )
  }

  const options = {
    chart: {
      animations: {
        enabled: true,
      },
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: "rounded",
        columnWidth: "70%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: [2, 4],
      // colors: ["transparent"],
    },
    labels: props.PowerData?.map((e) => { return format(parseISO(e.interval_start), "E, d MMM yyyy", { locale: i18n.language === "de" ? de : null }) }),
    xaxis: {
      // type: "time",
      // max: 100,
      labels: {
        show: true,
        align: 'right',
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: palette.black,
        },
      },
    },
    yaxis:
      [
        {
          title: {
            text: "Power Consumption in kWh",
          },
          labels: {
            show: true,
            align: 'right',
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: palette.black,
            },
            formatter: (value) => { return (value / 1000)?.toLocaleString(i18n.language === "de" ? de : "en", { maximumFractionDigits: 0, }) },
          },
        },
        {
          opposite: true,
          max: (value) => { return (value * 1.5 ) },
          title: {
            text: t("Power Consumption") + " / " + t("unit") + "  (in kWh)"
          },
          labels: {
            show: true,
            align: 'right',
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: palette.black,
            },
            formatter: (value) => { return (value / 1000)?.toLocaleString(i18n.language === "de" ? de : "en", { maximumFractionDigits: 0, }) },
          },
        }
      ],
    legend: {
      position: 'top',
      labels: {
        colors: palette.black,
        useSeriesColors: false
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return (val / 1000)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 }) + " kWh"
        },
      },
    },
    colors: [
      palette.primary,
      palette.success,
      // palette.danger,
      // palette.success,
      // palette.primary,
      // palette.primary,
    ],
  };

  return (

    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title tag="h5">{t("Power Consumption")}</Card.Title>
      </Card.Header>
      <Card.Body>
        {
          props.isLoading === true ?

            showLoadingSpinner() :

            props.PowerData.length === 0

              ?

              <h6 className="card-subtitle text-muted">
                {t("No power consumption available.")}
              </h6>

              :

              <div className="chart">
                <Chart options={options} series={data} height="350" />
              </div>
        }
      </Card.Body>
    </Card>
  );
};

export default PowerConsumptionChart;
