import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Col, Dropdown, Form, Modal, Row, Table } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Check, Edit2, Search, Trash2, X } from "react-feather";
import NotyfContext from "../../../contexts/NotyfContext";
import ErrorHandler from "../../Helper/ErrorHandler";
import AsyncSelect from 'react-select/async'
import { components } from "react-select";
import { getIAMBaseURL } from "../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import avatar1 from "../../../assets/img/avatars/avatar.png";
import { faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import CardDropdown from "../../../components/CardDropdown";
import UserDataManager from "../../Helper/UserDataManager";

const EditTenantModal = (props) => {
  let { id } = useParams();
  const [selectedFile, setSelectedFile] = useState();
  const hiddenFileInput = React.useRef(null);
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [openModal, setOpenModal] = useState(false);
  const [tenantData, setTenantData] = useState(props?.tenant_data);

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  const notyf = useContext(NotyfContext);
  const [tenantUsers, setTenantUsers] = useState([]);


  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 
  const handleChange = event => {
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {

    if (openModal) {
      setTenantData(props?.tenant_data)
      refreshTenantUsers();
    }

  }, [openModal]);

  function refreshTenantUsers() {
    axios
      .get(getIAMBaseURL() + "/tenants/" + tenantData?.id + "/users/")
      .then(function (response) {
        // handle success
        console.log(response.status, "tenant users");
        setTenantUsers(response.data?.filter(item => item?.role !== "device")?.sort((a, b) => {
          if (a.role === b.role) {
            return b.is_tenant_admin - a.is_tenant_admin; // Sort by is_tenant_admin (true first)
          }
          return a.role > b.role ? 1 : -1; // Sort by role
        }));
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  function refreshTenantData() {
    axios
      .get(getIAMBaseURL() + "/tenants/" + tenantData?.id)
      .then(function (response) {
        // handle success
        console.log(response.status, "Load");
        setTenantData(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  const [name, setName] = useState(tenantData?.name);
  const [url, setUrl] = useState(tenantData?.url);

  function handleSubmit(e) {
    e.preventDefault();

    setError({ showMessage: false, errorData: null })

    axios
      .put(getIAMBaseURL() + "/tenants/" + tenantData?.id, {
        "name": name,
        "url": url,
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "Load");

        notyf.open({
          type: "success",
          message: t("User \"") + name + t("\" has been updated."),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })

        refreshPage();
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  function refreshPage() {
    window.location.reload(false);
  }

  const ShowAccountManagersList = () => {

    return (
      <Table size="sm" responsive hover>
        <thead>
          <tr>
            <th scope="col">{""}</th>
            <th scope="col">{t("Email")}</th>
            <th scope="col">{t("Role")}</th>
            <th scope="col">{t("Tenant Admin")}</th>
          </tr>
        </thead>
        <tbody>{tenantUsers?.map((user) => {
          return (
            <tr key={user?.id} style={{ cursor: "pointer" }}>
              <td><img
                src={user?.avatar_url}
                alt="avatar"
                className="me-3 text-center rounded-circle"
                width={40} height={40}
              /></td>
              <td>{user.email}</td>
              <td>{user.role}</td>
              <td>{user.is_tenant_admin ? <Check /> : <X />}</td>

              <td className="table-action">
                <Trash2
                  alt={t("Remove")}
                  className="align-middle"
                  size={16}
                  style={{ cursor: "pointer" }}
                  onClick={e => window.confirm(t("Are you sure you wish to remove this user?")) && handleRemoveOwner(user?.id)}
                />

                <Button
                  size="sm"
                  variant={user?.is_tenant_admin ? "secondary" : "outline-secondary"}
                  className="ms-4"
                  onClick={() => updateTenantUser(user?.id, user?.is_tenant_admin ? false : true)}
                >
                  {user?.is_tenant_admin ? t("Revoke Tenant-Admin") : t("Make Tenant-Admin")}
                </Button>
              </td>

              {/* <td className="text-end">
                <CardDropdown>
                  <div className="py-2">
                    {UserDataManager.getInstance().isAdmin() && <Dropdown.Item onClick={() => updateTenantUser(user?.id, user?.is_tenant_admin ? false : true)}>
                      {user?.is_tenant_admin ? t("Unset Tenant Admin") : t("Set Tenant Admin")}
                    </Dropdown.Item>}
                  </div>
                </CardDropdown>
              </td> */}
            </tr>
          )
        })}</tbody>
      </Table>
    )
  }

  function handleRemoveOwner(userId) {

    axios
      .delete(getIAMBaseURL() + "/tenants/" + tenantData?.id + "/users/" + userId)
      .then(function (response) {
        // handle success
        console.log(response.status, "Deleted successfully");
        refreshTenantUsers();
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
      });
  }

  const loadOptions = (inputValue, callback) => {
    // perform a request

    var requestResults = []

    setError({ showMessage: false, errorData: null })

    clearTimeout(id);
    if (inputValue.length >= 3) {
      id = setTimeout(function (x) {
        if (inputValue && inputValue.length > 0)
          axios
            .get(getIAMBaseURL() + "/users/lookup?search_string=" + inputValue)
            .then(function (response) {
              // handle success
              requestResults = response.data.map(e => ({ label: e.first_name + " " + e.last_name + " (" + e.email + ")", value: null, id: e.id }))
              callback(requestResults)
              console.log(response.status, "Search data. Found: ", response.data.length);
            })
            .catch(function (error) {
              // handle error
              callback(requestResults)
              console.log(error);
            });
      }, 500) //0,5 Sekunden
    } else {
      callback(requestResults)
    }
  }

  function handleSelectSearchResult(event) {
    if (event === null || event?.id === null) {
      console.log("Error");
    } else {

      setError({ showMessage: false, errorData: null })

      axios
        .post(getIAMBaseURL() + "/tenants/" + tenantData?.id + "/users/", {
          user_id: event?.id,
          is_tenant_admin: false
        })
        .then(function (response) {
          // handle success
          console.log(response.status, "Edited successfully");
          refreshTenantUsers();
        })
        .catch(function (error) {
          // handle error
          setError({ showMessage: true, errorData: error })
          console.log(error);
        });
    }
  }

  function updateTenantUser(user_id, shouldBeAdmin) {

    setError({ showMessage: false, errorData: null })

    axios
      .post(getIAMBaseURL() + "/tenants/" + tenantData?.id + "/users/", {
        user_id: user_id,
        is_tenant_admin: shouldBeAdmin
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "Edited successfully");
        refreshTenantUsers();
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });

  }

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <Search className="feather" />
      </components.DropdownIndicator>
    );
  };

  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        {props.selectProps.inputValue.length === 0 && <span className="custom-css-class">{t("What are you looking for?")}</span>}
        {props.selectProps.inputValue.length > 0 && props.selectProps.inputValue.length < 3 && <span className="custom-css-class">{t("Please enter more characters.")}</span>}
      </components.NoOptionsMessage>
    );
  };

  function uploadAvatar(e) {
    e.preventDefault();

    console.log("->", selectedFile);

    if (selectedFile === undefined) {
      setError({ showMessage: true, errorData: error, customMessage: t("Please select a new avatar first") })
    } else {
      var formData = new FormData();
      formData.append("file", selectedFile);

      axios
        .put(getIAMBaseURL() + "/tenants/" + tenantData?.id + "/avatar", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(function (response) {
          // handle success
          console.log("File uploaded");
          notyf.open({
            type: "success",
            message: t("Updated avatar successfully"),
            duration: "4500",
            ripple: false,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          })
          refreshTenantData();
        })
        .catch(function (error) {
          // handle error
          // window.confirm(error)
          console.log(error);
          setError({ showMessage: true, errorData: error })
        });
    }
  }

  function removeAvatar(e) {
    e.preventDefault();

    axios
      .delete(getIAMBaseURL() + "/tenants/" + tenantData?.id + "/avatar", {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(function (response) {
        // handle success
        console.log("File deleted");
        notyf.open({
          type: "success",
          message: t("Deleted avatar successfully"),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })
        refreshTenantData();
      })
      .catch(function (error) {
        // handle error
        // window.confirm(error)
        console.log(error);
        setError({ showMessage: true, errorData: error })
      });

  }

  return (
    <>
      <Edit2
        alt="Edit"
        className="align-middle"
        size={18}
        style={{ cursor: "pointer" }}
        onClick={e => toggle()}

      />
      <Modal size="xl" show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t("Edit tenant")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Name")}</Form.Label>
                  <Form.Control
                    onChange={(e) => setName(e.target.value)}
                    defaultValue={name}
                    required
                    placeholder={t("Name")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("URL")}</Form.Label>
                  <Form.Control
                    onChange={(e) => setUrl(e.target.value)}
                    defaultValue={url}
                    placeholder={t("URL")} />
                </Form.Group>
              </Col>
              <Col md="6">
                <div className="text-center">
                  <img
                    src={tenantData?.avatar_url === null ? avatar1 : tenantData?.avatar_url}
                    className="rounded-circle img-responsive mt-2"
                    width="128"
                    height="128"
                    alt="avatar"
                  />
                  <div className="mt-5">
                    <Form.Group className="mb-3">
                      <Form.Control type="file" ref={hiddenFileInput} onChange={handleChange} />
                      <Form.Text className="text-muted">Max. 5 MB</Form.Text>
                    </Form.Group>
                    <Button variant="outline-primary" className="me-1"
                      onClick={event => removeAvatar(event)}>
                      <FontAwesomeIcon icon={faTrash} /> {t("Remove")}
                    </Button>
                    <Button
                      onClick={event => uploadAvatar(event)}>
                      <FontAwesomeIcon icon={faUpload} /> {t("Upload")}
                    </Button>
                  </div>
                </div>

              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                {t("Add new users to this tenant. Note: All cap-on admin users can access tenants anyway.")}
                <AsyncSelect
                  closeMenuOnSelect={true}
                  // classNamePrefix="testSelect"
                  isClearable
                  // formatOptionLabel={country => (
                  //   <div className="country-option">
                  //     <span><strong>{country.value}</strong>&nbsp;&nbsp;</span>
                  //     <span>{country.label}</span>
                  //     {/* <Button className="me-1 btn btn-secondary btn-sm" onClick={e => console.log("test")}>Test</Button> */}
                  //   </div>
                  // )}

                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 3,
                    colors: {
                      ...theme.colors,
                      // primary25: 'hotpink',
                      primary: 'primary',
                    },
                  })}
                  components={{ DropdownIndicator, NoOptionsMessage }}
                  loadOptions={loadOptions}
                  loadingMessage={() => t('Searching users...')}
                  placeholder={t("Search...")}
                  onChange={handleSelectSearchResult}
                  styles={{
                    group: provided => ({
                      ...provided,
                      borderBottom: '1px solid #ccc',
                    }),
                    placeholder: base => ({
                      ...base,
                      fontSize: "1em",
                      color: "gray",
                      fontWeight: 300
                    })
                  }} />

                <br />
                <ShowAccountManagersList />
              </Col>
            </Row>
            <br />
            <hr />
            <Row>
              <Col xs="auto" className="ms-auto text-end mt-n1">
                <Button variant="secondary" onClick={() => toggle(false)}>
                  {t("Cancel")}
                </Button>{" "}
                <Button variant={"primary"} type="submit">
                  {t("Save")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditTenantModal;
