import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Card, Col, Badge, Container, Row, Table, ProgressBar, Spinner, Dropdown } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { format, formatDistanceToNow, parseISO } from 'date-fns'
import { DollarSign, HardDrive, Clipboard, Wifi } from "react-feather";
import usePalette from "../../hooks/usePalette";
import { VectorMap } from "react-jvectormap";
import { de } from "date-fns/locale";
import ErrorHandler from "../Helper/ErrorHandler";
import CardDropdown from "../../components/CardDropdown";
import NotyfContext from "../../contexts/NotyfContext";
import LoadingSpinner from "../Helper/LoadingSpinner";

const Dashboard = () => {
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState([]);
  const [assetGeoData, setAssetGeoData] = useState([]);
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const notyf = useContext(NotyfContext);

  useEffect(() => {

    axios
      .get("/webservice/dashboard/")
      .then(function (response) {
        // handle success
        console.log(response.status, "/dashboard");
        setDashboardData(response.data);

        var geoData = [];
        for (let i = 0; i < response.data.asset_list.length; i++) {
          const item = {
            "latLng": response.data?.asset_list[i]?.location?.coordinates || [],
            "name": response.data.asset_list[i].title + " (" + response.data.asset_list[i].client_name + ")",
            "id": response.data.asset_list[i].id
          }
          item?.latLng[0] !== 0 && geoData.push(item);
        }
        setAssetGeoData(geoData);
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error)
        if (error?.response?.status === "403") {
          setError({ showMessage: true, errorData: error })
        }
        setIsLoading(false);
      });
  }, []);

  function getStatus(status) {
    switch (status) {
      case "approved":
        return t("Approved")
      case "active":
        return t("Active")
      case "request":
        return t("Request")
      case "inactive":
        return t("Inactive")
      case "draft":
        return t("Draft")
      default:
        return t(status)
    }
  }

  const renderContractsTable = () => {
    if (dashboardData?.contracts_list != null) {
      return dashboardData?.contracts_list?.map((contract) => {
        return (
          <tr key={contract.id} onClick={() => navigate("/contracts/" + contract.id)} style={{ cursor: "pointer" }}>
            {/* <td>{contract.time_progress}</td> */}
            <td>
              <ProgressBar
                className="mb-3"
                variant="primary"
                striped
                animated
                now={contract.time_progress * 100}
                label={(contract.time_progress).toLocaleString(i18n.language === "de" ? de : "en", {
                  style: 'percent',
                  maximumFractionDigits: 0
                })}
              />
            </td>
            <td>
              <Badge bg="" className={contract.status === "active" || contract.status === "approved" ? "badge-soft-success me-2" : "badge-soft-warning me-2"} >{getStatus(contract.status)}</Badge>
            </td>
            {/* <td>{contract.contract_number}</td> */}
            <td>{contract.title}</td>
            <td>{contract.client_name}</td>
          </tr>
        );
      });
    }
  };

  const renderInvoiceState = (invoice) => {

    switch (invoice.payment_status) {
      case "paid":
        return (
          <Badge bg="" className="badge-soft-success me-2">{t(invoice.payment_status)}</Badge>
        )
      case "unpaid":
        return (
          <Badge bg="" className="badge-soft-warning me-2">{t(invoice.payment_status)}</Badge>
        )
      case "overdue":
        return (
          <Badge bg="" className="badge-soft-danger me-2">{t(invoice.payment_status)}</Badge>
        )
      default:
        break;
    }
  }

  const renderInvoiceTable = () => {
    if (dashboardData?.pending_invoices != null) {
      return dashboardData?.pending_invoices?.map((invoice) => {
        return (
          <tr key={invoice.id} onClick={() => navigate("/invoices/" + invoice.id)} style={{ cursor: "pointer" }}>
            <td>{renderInvoiceState(invoice)}</td>
            <td>{format(parseISO(invoice.due_date), "dd MMMM yyyy", { locale: i18n.language === "de" ? de : null })}</td>
            <td>{invoice.total.toLocaleString(i18n.language === "de" ? de : "en", {
              style: 'currency',
              currency: 'EUR',
            })}</td>
          </tr>
        );
      });
    }
  };

  function handleClipboard(id) {
    navigator.clipboard.writeText(id)

    notyf.open({
      type: "success",
      message: t("Asset-ID copied!"),
      duration: "4500",
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "bottom",
      },
    })
  }

  const renderAssetTable = () => {
    if (dashboardData?.asset_list != null) {
      return dashboardData?.asset_list?.map((asset) => {
        return (
          <tr key={asset?.id} style={{ cursor: "pointer" }}>
            <td onClick={() => navigate("/assets/" + asset?.id)} >{asset?.title || "-"}</td>
            <td onClick={() => navigate("/assets/" + asset?.id)} >{asset?.client_name || "-"}</td>
            <td onClick={() => navigate("/assets/" + asset?.id)} >{asset?.investment_price?.toLocaleString(i18n.language === "de" ? de : "en", {
              style: 'currency',
              maximumFractionDigits: 0,
              currency: 'EUR',
            }) || "-"}</td>
            <td onClick={() => navigate("/assets/" + asset?.id)} >{asset?.aggregated_seven_day_usage || "-"}</td>
           
            <td className="text-end">
              <CardDropdown>
                <div className="py-2">
                  <Dropdown.Item onClick={() => navigate("/assets/" + asset?.id)}>
                    {t("Details")}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleClipboard(asset?.id)}>
                    {t("Copy ID")}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => navigate("/assets/" + asset?.id + "/?focus=digital-twin")}>
                    {t("Asset Live View")}
                  </Dropdown.Item>
                  {/* <Dropdown.Item onClick={() => navigate("/assets/" + asset?.id + "/usage")}>
                    {t("Usage Details")}
                  </Dropdown.Item> */}
                  <Dropdown.Item onClick={() => navigate("/assets/" + asset?.id + "/insights")}>
                    {t("Insights")}
                  </Dropdown.Item>
                  {/* {UserDataManager.getInstance().isAdmin() &&
                  <Dropdown.Item onClick={() => window.confirm(t("Dies funktioniert nur, wenn Sie sich im cap-on VPN befinden.")) && handleButtonClick("http://" + gateway?.internal_ip + ":1880")}>
                    {t("Open NR")}
                  </Dropdown.Item>}
                  {/* <Dropdown.Item className='text-danger'>Löschen</Dropdown.Item> */}
                </div>
              </CardDropdown>
            </td>

          </tr>
        );
      });
    }
  };

  const renderRevenueTable = () => {
    if (dashboardData?.monthly_revenues != null) {
      return dashboardData?.monthly_revenues?.map((revenue) => {
        return (
          <tr key={revenue.first_of_month}>
            <td>{format(parseISO(revenue.first_of_month), "MMMM yyyy", { locale: i18n.language === "de" ? de : null })}</td>
            <td>{revenue?.amount?.toLocaleString(i18n.language === "de" ? de : "en", {
              style: 'currency',
              currency: 'EUR',
            })}</td>
          </tr>
        );
      });
    }
  };

  const renderConnectionState = (status) => {

    switch (status) {
      case "connected":
        return (
          <Badge bg="" className="badge-soft-success me-2">{t(status)}</Badge>
        )
      case "connection_lost":
        return (
          <Badge bg="" className="badge-soft-warning me-2">{t(status)}</Badge>
        )
      default:
        return (
          <Badge bg="" className="badge-soft-warning me-2">{t("Not connected")}</Badge>
        )
    }
  }

  const renderGatewayTable = () => {

    if (dashboardData?.iot_gateways != null) {
      return dashboardData?.iot_gateways?.map((gateway) => {
        return (
          <tr key={gateway?.id} onClick={() => navigate("/gateways/" + gateway?.id)} style={{ cursor: "pointer" }}>
            <td>
              {gateway == null ? "n/a" : renderConnectionState(gateway.connection_status)}
            </td>
            <td>{gateway?.serial}</td>
            <td>{gateway?.last_handshake == null ? "-" : formatDistanceToNow(parseISO(gateway.last_handshake.time), { locale: i18n.language === "de" ? de : null, addSuffix: true })}</td>
          </tr>
        );
      });
    }
  };

  const palette = usePalette();

  const options = {
    map: "world_mill", //"europe_mill",
    normalizeFunction: "polynomial",
    hoverOpacity: 0.7,
    hoverColor: false,
    zoomOnScroll: false,
    regionStyle: {
      initial: {
        fill: palette["gray-200"],
      },
    },
    containerStyle: {
      width: "100%",
      height: "100%",
    },
    markerStyle: {
      initial: {
        r: 9,
        fill: palette.primary,
        "fill-opacity": 0.9,
        stroke: palette.white,
        "stroke-width": 7,
        "stroke-opacity": 0.4,
      },
      hover: {
        fill: palette.primary,
        "fill-opacity": 0.9,
        stroke: palette.primary,
        "stroke-width": 7,
        "stroke-opacity": 0.4,
      },
    },
    backgroundColor: "transparent",
    markers: assetGeoData,
    onMarkerClick: ((event, code) => navigate("/assets/" + assetGeoData[code].id))
  };

  const DashboardAdminContent = () => {
    return (
      <div>
        {loadHeader()}
        <Row>
          <Col lg="6" xl="6" className="d-flex">
            <Row>
              <Col sm="6" lg="12" className="d-flex col-xxl-6">
                <Card className="flex-fill">
                  <Card.Body>
                    <Row>
                      <Col className="mt-0">
                        <h5 className="card-title">{t("Investment Volume • Current Year")}</h5>
                      </Col>

                      <Col xs="auto">
                        <div className="stat stat-sm" onClick={() => navigate("/invoices/")} style={{ cursor: "pointer" }} >
                          <DollarSign className="align-middle text-success" />
                        </div>
                      </Col>
                    </Row>
                    <span className="h1 d-inline-block mt-1 mb-4">{dashboardData == null ? "-" : dashboardData?.investment_volume == null ? "-" : dashboardData?.investment_volume.current_year == null ? "-" : dashboardData?.investment_volume.current_year.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      maximumFractionDigits: 0,
                      currency: 'EUR',
                    })}</span>
                    <div className="mb-0">
                      <Badge bg="" className="badge-soft-success me-2">
                        {dashboardData == null ? "-" : dashboardData?.investment_volume == null ? "-" : dashboardData?.investment_volume.percentage_change == null ? "-" : dashboardData?.investment_volume.percentage_change.toLocaleString(i18n.language === "de" ? de : "en", {
                          style: 'percent', signDisplay: 'always',
                          maximumFractionDigits: 0,
                        })}
                      </Badge>
                      <span className="text-muted">{t("Since last year")}</span>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="6" lg="12" className="d-flex col-xxl-6">
                <Card className="flex-fill">
                  <Card.Body>
                    <Row>
                      <Col className="mt-0">
                        <h5 className="card-title">{t("Revenue • Current Year")}</h5>
                      </Col>

                      <Col xs="auto">
                        <div className="stat stat-sm" onClick={() => navigate("/invoices/")} style={{ cursor: "pointer" }} >
                          <DollarSign className="align-middle text-success" />
                        </div>
                      </Col>
                    </Row>
                    <span className="h1 d-inline-block mt-1 mb-4">{dashboardData == null ? "-" : dashboardData?.revenue_overview == null ? "-" : dashboardData?.revenue_overview.current_year == null ? "-" : dashboardData?.revenue_overview.current_year.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      maximumFractionDigits: 0,
                      currency: 'EUR',
                    })}</span>
                    <div className="mb-0">
                      <Badge bg="" className="badge-soft-success me-2">
                        {dashboardData == null ? "-" : dashboardData?.revenue_overview == null ? "-" : dashboardData?.revenue_overview.percentage_change == null ? "-" : dashboardData?.revenue_overview.percentage_change.toLocaleString(i18n.language === "de" ? de : "en", {
                          style: 'percent', signDisplay: 'always',
                          maximumFractionDigits: 0,
                        })}
                      </Badge>
                      <span className="text-muted">{t("Since last year")}</span>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="6" lg="12" className="d-flex col-xxl-6">
                <Card className="flex-fill">
                  <Card.Body>
                    <Row>
                      <Col className="mt-0">
                        <h5 className="card-title">{t("Total Invest. Volume")}</h5>
                      </Col>

                      <Col xs="auto">
                        <div className="stat stat-sm" onClick={() => navigate("/invoices/")} style={{ cursor: "pointer" }} >
                          <DollarSign className="align-middle text-success" />
                        </div>
                      </Col>
                    </Row>
                    <span className="h1 d-inline-block mt-1 mb-4">{dashboardData == null ? "-" : dashboardData?.investment_volume == null ? "-" : dashboardData?.investment_volume.total == null ? "-" : dashboardData?.investment_volume.total.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      maximumFractionDigits: 0,
                      currency: 'EUR',
                    })}</span>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="6" lg="12" className="d-flex col-xxl-6">
                <Card className="flex-fill">
                  <Card.Body>
                    <Row>
                      <Col className="mt-0">
                        <h5 className="card-title">{t("Total Asset Value")}</h5>
                      </Col>

                      <Col xs="auto">
                        <div className="stat stat-sm" onClick={() => navigate("/assets/")} style={{ cursor: "pointer" }} >
                          <HardDrive className="align-middle text-success" />
                        </div>
                      </Col>
                    </Row>
                    <span className="h1 d-inline-block mt-1 mb-4">{dashboardData == null ? "-" : dashboardData?.current_total_asset_value == null ? "-" : dashboardData?.current_total_asset_value.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      maximumFractionDigits: 0,
                      currency: 'EUR',
                    })}</span>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col lg="6" xl="6" className="d-flex">
            <Card className="flex-fill w-100">
              <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                  {t("Assets")}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="map-container" style={{ height: 300 }}>
                  <VectorMap {...options} />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="6" >
            <Row>
              <Col>
                <Card className="flex-fill w-100">
                  <Card.Header>
                    <Card.Title tag="h5" className="mb-0">
                      {t("Assets")}
                    </Card.Title>
                  </Card.Header>
                  {/* <Card.Body> */}
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th scope="col">{t("Title")}</th>
                        <th scope="col">{t("Client")}</th>
                        <th scope="col">{t("Asset Price")}</th>
                        <th scope="col">{t("7 day usage")}</th>
                        {/* <th scope="col">{t("Live View")}</th> */}
                      </tr>
                    </thead>
                    <tbody>{renderAssetTable()}</tbody>
                  </Table>
                  {/* </Card.Body> */}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="flex-fill w-100">
                  <Card.Header>
                    <Card.Title tag="h5" className="mb-0">
                      {t("Contracts")}
                    </Card.Title>
                  </Card.Header>
                  {/* <Card.Body> */}
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th scope="col">{t("Progress")}</th>
                        <th scope="col">{t("Status")}</th>
                        {/* <th scope="col">#</th> */}
                        <th scope="col">{t("Contract")}</th>
                        <th scope="col">{t("Client")}</th>
                        {/* <th scope="col">No. of Assets</th>
                      <th scope="col">Investment</th>
                      <th scope="col">Pending Invoices</th> */}
                      </tr>
                    </thead>
                    <tbody>{renderContractsTable()}</tbody>
                  </Table>
                  {/* </Card.Body> */}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="flex-fill w-100">
                  <Card.Header>
                    <Card.Title tag="h5" className="mb-0">
                      {t("Pending or Overdue Invoices")}
                    </Card.Title>
                  </Card.Header>
                  {/* <Card.Body> */}

                  {
                    dashboardData?.pending_invoices?.length > 0 ?
                      <Table responsive hover>
                        <thead>
                          <tr>
                            <th scope="col">{t("Status")}</th>
                            <th scope="col">{t("Due date")}</th>
                            <th scope="col">{t("Amount")}</th>
                          </tr>
                        </thead>
                        <tbody>{renderInvoiceTable()}</tbody>
                      </Table> :
                      <Card.Body>
                        <p>{t("No data found.")}</p>
                      </Card.Body>
                  }

                  {/* </Card.Body> */}
                </Card>
              </Col>
            </Row>
          </Col>
          <Col lg="6">
            <Row>
              <Col>
                <Card className="flex-fill w-100">
                  <Card.Header>
                    <Card.Title tag="h5" className="mb-0">
                      {t("IoT-Gateways")}
                    </Card.Title>
                  </Card.Header>
                  {/* <Card.Body> */}
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th scope="col">{t("Connection")}</th>
                        <th scope="col">{t("Serial #")}</th>
                        <th scope="col">{t("Last handshake")}</th>
                      </tr>
                    </thead>
                    <tbody>{renderGatewayTable()}</tbody>
                  </Table>
                  {/* </Card.Body> */}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="flex-fill w-100">
                  <Card.Header>
                    <Card.Title tag="h5" className="mb-0">
                      {t("Revenue • Last 12 months")}
                    </Card.Title>
                  </Card.Header>
                  {/* <Card.Body> */}
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th scope="col">{t("Month")}</th>
                        <th scope="col">{t("Amount")}</th>
                      </tr>
                    </thead>
                    <tbody>{renderRevenueTable()}</tbody>
                  </Table>
                  {/* </Card.Body> */}
                </Card>
              </Col>
            </Row>

          </Col>
        </Row>
      </div>
    );
  };

  const loadHeader = () => {

    var numberOfAssets = dashboardData?.asset_list?.length;
    var active_inactive_gateways = dashboardData?.iot_gateways?.filter(gateway => gateway.connection_status === "connected").length / dashboardData?.iot_gateways?.length;
    var overdue_invoices = dashboardData?.pending_invoices?.filter(invoice => invoice.payment_status === "overdue").length;
    var numberOfContracts = dashboardData?.contracts_list?.length;

    return (
      <Row>
        <Col sm="6" lg="3" className="d-flex col-xxl-3">
          <Card className="flex-fill">
            <Card.Body className=" py-3">
              <Row>
                <Col className="mt-0">
                  <span className="h1 d-inline-block mt-1 mb-4">{numberOfAssets}</span>
                </Col>
                <Col xs="auto">
                  <div className="stat stat-sm" onClick={() => navigate("/assets/")} style={{ cursor: "pointer" }} >
                    <HardDrive className="align-middle text-success" />
                  </div>
                </Col>
              </Row>
              <div className="mb-0">
                <span className="text-muted">{t("No. of Assets")}</span>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" lg="3" className="d-flex col-xxl-3">
          <Card className="flex-fill">
            <Card.Body className=" py-3">
              <Row>
                <Col className="mt-0">
                  <span className="h1 d-inline-block mt-1 mb-4">{isNaN(parseFloat(active_inactive_gateways)) ? "0" : active_inactive_gateways.toLocaleString(i18n.language === "de" ? de : "en", {
                    style: 'percent',
                    currency: 'EUR',
                  })}</span>
                </Col>
                <Col xs="auto">
                  <div className="stat stat-sm" onClick={() => navigate("/gateways/")} style={{ cursor: "pointer" }}>
                    <Wifi className="align-middle text-success" />
                  </div>
                </Col>
              </Row>
              <div className="mb-0">
                <span className="text-muted">{t("Connected Gateways")}</span>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" lg="3" className="d-flex col-xxl-3">
          <Card className="flex-fill">
            <Card.Body className=" py-3">
              <Row>
                <Col className="mt-0">
                  <span className="h1 d-inline-block mt-1 mb-4">{overdue_invoices}</span>
                </Col>
                <Col xs="auto">
                  <div className="stat stat-sm" onClick={() => navigate("/invoices/")} style={{ cursor: "pointer" }}>
                    <DollarSign className="align-middle text-success" />
                  </div>
                </Col>
              </Row>
              <div className="mb-0">
                <span className="text-muted">{t("Overdue Invoices")}</span>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" lg="3" className="d-flex col-xxl-3">
          <Card className="flex-fill">
            <Card.Body className=" py-3">
              <Row>
                <Col className="mt-0">
                  <span className="h1 d-inline-block mt-1 mb-4">{numberOfContracts}</span>
                </Col>
                <Col xs="auto">
                  <div className="stat stat-sm" onClick={() => navigate("/contracts/")} style={{ cursor: "pointer" }} >
                    <Clipboard className="align-middle text-success" />
                  </div>
                </Col>
              </Row>
              <div className="mb-0">
                <span className="text-muted">{t("No. of Contracts")}</span>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    )
  }

  const DashboardCapOnAgentContent = () => {
    return (
      <div>
        {loadHeader()}
        <Row>
          <Col lg="6" className="flex-fill">
            <Row>
              <Col>
                <Card className="flex-fill w-100">
                  <Card.Header>
                    <Card.Title tag="h5" className="mb-0">
                      {t("Assets")}
                    </Card.Title>
                  </Card.Header>
                  {/* <Card.Body> */}
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th scope="col">{t("Title")}</th>
                        <th scope="col">{t("Client")}</th>
                        <th scope="col">{t("Asset Price")}</th>
                        <th scope="col">{t("7 day usage")}</th>
                      </tr>
                    </thead>
                    <tbody>{renderAssetTable()}</tbody>
                  </Table>
                  {/* </Card.Body> */}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="flex-fill w-100">
                  <Card.Header>
                    <Card.Title tag="h5" className="mb-0">
                      {t("IoT-Gateways")}
                    </Card.Title>
                  </Card.Header>
                  {/* <Card.Body> */}
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th scope="col">{t("Connection")}</th>
                        <th scope="col">{t("Serial #")}</th>
                        <th scope="col">{t("Client")}</th>
                        <th scope="col">{t("Last handshake")}</th>
                      </tr>
                    </thead>
                    <tbody>{renderGatewayTable()}</tbody>
                  </Table>
                  {/* </Card.Body> */}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="flex-fill w-100">
                  <Card.Header>
                    <Card.Title tag="h5" className="mb-0">
                      {t("Pending or Overdue Invoices")}
                    </Card.Title>
                  </Card.Header>
                  {/* <Card.Body> */}
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th scope="col">{t("Status")}</th>
                        <th scope="col">{t("Due date")}</th>
                        <th scope="col">{t("Amount")}</th>
                      </tr>
                    </thead>
                    <tbody>{renderInvoiceTable()}</tbody>
                  </Table>
                  {/* </Card.Body> */}
                </Card>
              </Col>
            </Row>
          </Col>
          <Col lg="6">
            <Row>
              <Col>
                <Card className="flex-fill">
                  <Card.Header>
                    <Card.Title tag="h5" className="mb-0">
                      {t("Assets")}
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <div className="map-container" style={{ height: 300 }}>
                      <VectorMap {...options} />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="flex-fill w-100">
                  <Card.Header>
                    <Card.Title tag="h5" className="mb-0">
                      {t("Contracts")}
                    </Card.Title>
                  </Card.Header>
                  {/* <Card.Body> */}
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th scope="col">{t("Progress")}</th>
                        <th scope="col">{t("Status")}</th>
                        <th scope="col">#</th>
                        <th scope="col">{t("Contract")}</th>
                        <th scope="col">{t("Client")}</th>
                        {/* <th scope="col">No. of Assets</th>
                  <th scope="col">Investment</th>
                  <th scope="col">Pending Invoices</th> */}
                      </tr>
                    </thead>
                    <tbody>{renderContractsTable()}</tbody>
                  </Table>
                  {/* </Card.Body> */}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="flex-fill w-100">
                  <Card.Header>
                    <Card.Title tag="h5" className="mb-0">
                      {t("Revenue • Last 12 months")}
                    </Card.Title>
                  </Card.Header>
                  {/* <Card.Body> */}
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th scope="col">{t("Month")}</th>
                        <th scope="col">{t("Amount")}</th>
                      </tr>
                    </thead>
                    <tbody>{renderRevenueTable()}</tbody>
                  </Table>
                  {/* </Card.Body> */}
                </Card>
              </Col>
            </Row>
          </Col>

        </Row>
      </div>
    );
  };

  const DashboardRegularUserContent = () => {

    var numberOfAssets = dashboardData?.asset_list.length;
    var pending_invoices = dashboardData?.pending_invoices.length;

    return (
      <div>
        {loadHeader()}
        {/* <Row>
          <Col md="6" xl className="d-flex">
            <Card className="flex-fill">

              <Card.Body className=" py-4">
                <Row>
                  <Col className="mt-0">
                    <span className="h1 d-inline-block mt-1 mb-4">{numberOfAssets}</span>
                  </Col>
                  <Col xs="auto">
                    <div className="stat stat-sm" onClick={() => navigate("/assets/")} style={{ cursor: "pointer" }} >
                      <HardDrive className="align-middle text-success" />
                    </div>
                  </Col>
                </Row>
                <div className="mb-0">
                  <span className="text-muted">{t("No. of Assets")}</span>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" xl className="d-flex">
            <Card className="flex-fill">

              <Card.Body className=" py-4">
                <Row>
                  <Col className="mt-0">
                    <span className="h1 d-inline-block mt-1 mb-4">{pending_invoices}</span>
                  </Col>
                  <Col xs="auto">
                    <div className="stat stat-sm" onClick={() => navigate("/invoices/")} style={{ cursor: "pointer" }} >
                      <DollarSign className="align-middle text-success" />
                    </div>
                  </Col>
                </Row>
                <div className="mb-0">
                  <span className="text-muted">{t("Pending or Overdue Invoices")}</span>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row> */}
        <Row>
          <Col lg="12">
            <Card className="flex-fill w-100">
              <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                  {t("Assets")}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="map-container" style={{ height: 300, width: "auto" }}>
                  <VectorMap {...options} />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="6" >
            <Row>
              <Col>
                <Card className="flex-fill w-100">
                  <Card.Header>
                    <Card.Title tag="h5" className="mb-0">
                      {t("Assets")}
                    </Card.Title>
                  </Card.Header>
                  {/* <Card.Body> */}
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th scope="col">{t("Title")}</th>
                        <th scope="col">{t("Client")}</th>
                        <th scope="col">{t("Asset Price")}</th>
                        <th scope="col">{t("7 day usage")}</th>
                      </tr>
                    </thead>
                    <tbody>{renderAssetTable()}</tbody>
                  </Table>
                  {/* </Card.Body> */}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="flex-fill w-100">
                  <Card.Header>
                    <Card.Title tag="h5" className="mb-0">
                      {t("IoT-Gateways")}
                    </Card.Title>
                  </Card.Header>
                  {/* <Card.Body> */}
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th scope="col">{t("Connection")}</th>
                        <th scope="col">{t("Serial #")}</th>
                        <th scope="col">{t("Last handshake")}</th>
                      </tr>
                    </thead>
                    <tbody>{renderGatewayTable()}</tbody>
                  </Table>
                  {/* </Card.Body> */}
                </Card>
              </Col>
            </Row>

          </Col>
          <Col lg="6" >
            <Row>
              <Col>
                <Card className="flex-fill w-100">
                  <Card.Header>
                    <Card.Title tag="h5" className="mb-0">
                      {t("Contracts")}
                    </Card.Title>
                  </Card.Header>
                  {/* <Card.Body> */}
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th scope="col">{t("Progress")}</th>
                        <th scope="col">{t("Status")}</th>
                        {/* <th scope="col">#</th> */}
                        <th scope="col">{t("Contract")}</th>
                        <th scope="col">{t("Client")}</th>
                        {/* <th scope="col">No. of Assets</th>
                  <th scope="col">Investment</th>
                  <th scope="col">Pending Invoices</th> */}
                      </tr>
                    </thead>
                    <tbody>{renderContractsTable()}</tbody>
                  </Table>
                  {/* </Card.Body> */}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col >
                <Card className="flex-fill w-100">
                  <Card.Header>
                    <Card.Title tag="h5" className="mb-0">
                      {t("Pending or Overdue Invoices")}
                    </Card.Title>
                  </Card.Header>
                  {/* <Card.Body> */}
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th scope="col">{t("Status")}</th>
                        <th scope="col">{t("Due date")}</th>
                        <th scope="col">{t("Amount")}</th>
                      </tr>
                    </thead>
                    <tbody>{renderInvoiceTable()}</tbody>
                  </Table>
                  {/* </Card.Body> */}
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Container fluid className="p-0">
        {/* <Card.Header> */}
        <ErrorHandler error={error} />
        {/* </Card.Header> */}
        {
          isLoading === true ?
            <LoadingSpinner /> :
            dashboardData?.user_role === "admin" ?
              DashboardAdminContent() :
              dashboardData?.user_role === "cap-on agent" ?
                DashboardCapOnAgentContent() :
                dashboardData?.user_role === "regular" ?
                  DashboardRegularUserContent() :
                  <p></p>
        }
      </Container>
    </React.Fragment>
  );
};

export default Dashboard;
