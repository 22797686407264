import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Modal, Row, Table } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../../Helper/ErrorHandler";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import UserDataManager from "../../Helper/UserDataManager";

const AnomalyReportModal = ({ report_id, asset_id, show, handleClose }) => {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

  const [title, setTitle] = useState("");
  const [anomalyData, setAnomalyData] = useState("");

  useEffect(() => {

    setTitle("Intelligence View (BETA)")

    if (show && report_id) {
      axios
        .get("/assets/" + asset_id + "/anomaly_reports/" + report_id)
        .then(function (response) {
          // handle success
          console.log(response.status, "/report");
          setAnomalyData(response.data)
        })
        .catch(function (error) {
          // handle error
          setError({ showMessage: true, errorData: error })
          console.log(error);
        });
    }

  }, [show]);

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header closeButton>{anomalyData?.title}</Modal.Header>
      <Modal.Body className="m-3">
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        {/* <Row>
          <Col md="6">
            <Alert variant="secondary">
              <div className="alert-message">
                {anomalyData?.title}
              </div>
            </Alert>
          </Col>
        </Row> */}

        {UserDataManager.getInstance().isAdmin() && <Row>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1" className="bg-white">
              <Accordion.Header>{t("Technical Details")}</Accordion.Header>
              <Accordion.Body className="bg-white">
                <Table>
                  <tbody>
                    <tr>
                      <th>{t("Assistant's Instructions")}</th>
                      <td>{anomalyData?.assistant_instructions || "-"}</td>
                    </tr>
                    <tr>
                      <th>{t("Cluster")}</th>
                      <td>{anomalyData?.cluster_density || "-"}</td>
                    </tr>
                    <tr>
                      <th>{t("CSV Columns")}</th>
                      <td>{anomalyData?.csv_columns || "-"}</td>
                    </tr>
                    <tr>
                      <th>{t("Gap Fill Mode")}</th>
                      <td>{anomalyData?.gap_fill || "-"}</td>
                    </tr>
                    <tr>
                      <th>{t("Prompt")}</th>
                      <td>{anomalyData?.prompt || "-"}</td>
                    </tr>
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Row>}
        <Row>
          <Col className="mt-4">
            <h3>{anomalyData?.title}</h3>
            <hr />
            <br />
            <ReactMarkdown>
              {anomalyData?.summary}
            </ReactMarkdown>
          </Col>
        </Row>
        <hr />
        <br />
        <Row>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button variant="secondary" onClick={handleClose}>
              {t("Close")}
            </Button>
          </Col>
        </Row>
      </Modal.Body >
    </Modal >
  );
};

export default AnomalyReportModal;
