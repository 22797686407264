import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../../Helper/ErrorHandler";
import NotyfContext from "../../../contexts/NotyfContext";

const ImportFromTemplateModal = ({ type }) => {
  let { id } = useParams();
  const notyf = useContext(NotyfContext);
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [openModal, setOpenModal] = useState(false);
  const [assets, setAssets] = useState();
  const [selected_template_asset, set_selected_template_asset] = useState();

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  useEffect(() => {
    if (openModal) {
      axios
        .get("/assets/")
        .then(function (response) {
          // handle success
          console.log(response.status, "/assets");
          setAssets(response.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }
  }, [openModal]);


  function handleSubmit(e) {
    e.preventDefault();
    setError({ showMessage: false, errorData: null })

    axios
      .post("/assets/" + id + `/${type}/from_template?template_asset_id=${selected_template_asset}`)
      .then(function (response) {
        // handle success
        console.log(response.status, "/updated");

        notyf.open({
          type: "success",
          message: t(`Updated details successfully.`),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })

        window.location.reload(false);

      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  const loadAssets = () => {
    return assets?.map((asset) => {
      return (
        <option key={asset.id} value={asset.id} selected={asset.id === id}>{asset.title}</option>
      );
    });
  };

  return (
    <>
      <Dropdown.Item onClick={() => toggle()}>{t(`Import ${type === "iot_nodes" ? "IoT-Nodes" : "IoT-Configuration"} from an existing asset`)}</Dropdown.Item>
      <Modal size="m" show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t("Import data from an existing asset")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>
          <Form onSubmit={handleSubmit}>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>{t("Asset")}</Form.Label>
                <Form.Select
                  name="state"
                  required
                  onChange={e => set_selected_template_asset(e.target.value)}>
                  <option value="">{t("-- Select --")}</option>
                  {loadAssets()}
                </Form.Select>
              </Form.Group>
            </Col>
            <br />
            <hr />
            <Row>
              <Col xs="auto" className="ms-auto text-end mt-n1">
                <Button className="mb-2" variant="secondary" onClick={() => toggle(false)}>
                  {t("Cancel")}
                </Button>{" "}
                <Button className="mb-2" variant="primary" type="submit">
                  {t("Save")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ImportFromTemplateModal;
