import React, { useContext, useEffect, useState } from "react";
import { Accordion, Badge, Button, Col, Form, InputGroup, Modal, Nav, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import NotyfContext from "../../contexts/NotyfContext";
import { getCoreBaseURL } from "../../config";
import { Link } from "react-router-dom";
import UserDataManager from "../Helper/UserDataManager";
import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";

const SingleMaintenanceEventModal = ({ show, selected_event, asset_id, handleClose }) => {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const notyf = useContext(NotyfContext);

  const [data, setData] = useState();

  const [report, set_report] = useState();
  const [service_partner, set_service_partner] = useState();
  const [appointment, set_appointment] = useState();
  const [status, set_status] = useState();
  const [description, set_description] = useState();

  const [allStatus, setAllStatus] = useState();

  useEffect(() => {

    if (show) {

      console.log("Selected:", selected_event)

      if (selected_event) {

        axios
          .get("/assets/" + asset_id + "/service_events/" + selected_event?.id)
          .then(function (response) {
            // handle success
            console.log(response.status, "/service_event");
            setData(response.data);
          })
          .catch(function (error) {
            // handle error
            setError({ showMessage: true, errorData: error })
            console.log(error);
          });

      } else {
        setData()
        set_report()
        set_service_partner()
        set_appointment()
        set_status()
        set_description()
      }

      axios
        .get(getCoreBaseURL() + "/openapi.json")
        .then(function (response) {
          // handle success
          console.log(response.status, "/openapi");
          setAllStatus(response.data["components"]["schemas"]["ServiceEventStatus"]["enum"]);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    } else {
      setData()
      set_report()
      set_service_partner()
      set_appointment()
      set_status()
      set_description()
    }

  }, [show]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selected_event) {

      axios
        .put("/assets/" + asset_id + "/service_events/" + selected_event?.id,
          {
            "appointment": appointment,
            "report": report,
            "description": description,
            "service_partner": service_partner,
            "status": status
          }
        )
        .then(function (response) {
          // handle success
          console.log(response.status, "/service_events PUT");

          notyf.open({
            type: "success",
            message: t("Item \"") + selected_event?.report + t("\" updated."),
            duration: "4500",
            ripple: false,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          })

          handleClose()
        })
        .catch(function (error) {
          // handle error
          setError({ showMessage: true, errorData: error })
          console.log(error);
        });

    } else {
      let body = {
        "appointment": appointment,
        "report": report,
        "description": description,
        "service_partner": service_partner,
        "asset_id": asset_id,
        "status": status
      }

      axios
        .post("/assets/" + asset_id + "/service_events/", body)
        .then(function (response) {
          // handle success
          console.log(response.status, "/service_events POST");

          notyf.open({
            type: "success",
            message: t("Item \"") + report + t("\" updated."),
            duration: "4500",
            ripple: false,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          })

          handleClose()
        })
        .catch(function (error) {
          // handle error
          setError({ showMessage: true, errorData: error })
          console.log(error);
        });

    }
  };

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header closeButton>{selected_event ? t("Edit") : t("Add")}</Modal.Header>
      <Modal.Body className="m-3">
        <Row>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Appointment")}</Form.Label>
                  <Form.Control
                    onChange={(e) => set_appointment(parseISO(e.target.value))}
                    defaultValue={data?.appointment && format(parseISO(data?.appointment), "yyyy-MM-dd", { locale: i18n.language === "de" ? de : null })}
                    type="date"
                    required
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Status")}</Form.Label>
                  <Form.Select onChange={(e) => set_status(e.target.value)} required>
                    <option value="">{t("-- Select --")}</option>
                    {allStatus?.map((item) => {
                      return (<option value={item} selected={item === data?.status}>{t(item)}</option>);
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>

            </Row>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Title")}</Form.Label>
                  <Form.Control
                    onChange={event => set_description(event.target.value)}
                    defaultValue={data?.description}
                    required
                    placeholder={t("Title")} />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Report")}</Form.Label>
                  <Form.Control
                    onChange={event => set_report(event.target.value)}
                    defaultValue={data?.report}
                    required
                    rows="13"
                    as="textarea"
                    placeholder="Report" />
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs="auto" className="ms-auto text-end mt-n1">
                <Button variant="secondary" onClick={handleClose}>
                  {t("Close")}
                </Button>
                <Button className="ms-1" variant="primary" type="submit">
                  {selected_event ? t("Aktualisieren") : t("Hinzufügen")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Row>

      </Modal.Body >
    </Modal >
  );
};

export default SingleMaintenanceEventModal;
