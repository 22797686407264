import {
  Badge,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Card,
  Col,
  OverlayTrigger,
  Popover,
  Row,
  Table,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {
  BarChart,
  Edit,
  Eye,
  HelpCircle,
  Info,
  Maximize2,
  Plus,
  RefreshCw,
  Trash,
  Wifi,
} from "react-feather";
import { useTranslation } from "react-i18next";
import { de } from 'date-fns/locale';
import { isMobile } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";
import PowerConsumptionChart from "../Charts/PowerConsumptionChart";
import axios from "axios";
import LoadingSpinner from "../../Helper/LoadingSpinner";
import UserDataManager from "../../Helper/UserDataManager";
import { format, formatDistanceToNow, formatDuration, intervalToDuration, parseISO } from "date-fns";
import SingleMaintenanceEventModal from "../../Maintenance/SingleMaintenanceEventModal";
import AssetHealthCheckSection from "./AssetHealthCheckSection";
import usePalette from "../../../hooks/usePalette";
import AssetDigitalTwinChartModal from "../AssetDigitalTwin/AssetDigitalTwinChartModal";
import SingleIoTDataPointModal from "../IoT_Data_Setup/SingleIoTDataPointModal";

const MaintenanceSection = ({ assetData, maintenanceContentLoaded, loadData }) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [serviceEvents, setServiceEvents] = useState(false);
  const [refresh, set_refresh] = useState(true);
  const palette = usePalette();
  const [violations, setViolations] = useState();
  const [filteredViolations, setFilteredViolations] = useState();
  let { id } = useParams();

  const [showChart, setShowChart] = useState();

  useEffect(() => {

    if (maintenanceContentLoaded) {
      axios
        .get("/assets/" + assetData?.id + "/service_events")
        .then(function (response) {
          // handle success
          console.log(response.status, "/service_events");
          setServiceEvents(response.data?.sort((a, b) => a?.appointment < b?.appointment))
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      axios
        .get("/assets/" + assetData?.id + "/violations", {
          params: {
            add_infos: true
          }
        })
        .then(function (response) {
          // handle success
          console.log(response.status, "/violations", response.data);
          setViolations(response.data)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }

  }, [refresh, maintenanceContentLoaded, assetData?.id]);

  const [showSingleMaintenanceEvent, setShowSingleMaintenanceEvent] = useState(false)
  const [selected_event, set_selected_event] = useState()
  const [showIoTDataPointConfig, setShowIoTDataPointConfig] = useState(null)

  function handleDelete(e, id) {
    e.preventDefault();

    axios
      .delete("/assets/" + assetData?.id + "/service_events/" + id)
      .then(function (response) {
        console.log(response.status, "Deleted event");
        // handle success
        set_refresh(!refresh)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  const renderStatus = (status) => {
    switch (status) {
      case "completed":
        return <Badge bg="" className="badge-soft-success me-2">{t("Completed")}</Badge>
      case "overdue":
        return <Badge bg="" className="badge-soft-warning me-2">{t("Overdue")}</Badge>
      case "planned":
        return <Badge bg="" className="badge-soft-primary me-2">{t("Planned")}</Badge>
      default:
        return <Badge bg="" className="badge-soft-secondary me-2">{t("n/a")}</Badge>
    }
  }

  const RenderSecondsDuration = ({ seconds }) => {

    return formatDuration(intervalToDuration({ start: 0, end: seconds * 1000 }), { format: ['days', 'hours', 'minutes', "seconds"], locale: i18n.language === "de" ? de : null });

  }

  const RenderComparison = ({ mode }) => {

    switch (mode) {
      case "higher":
        return t("higher than threshold")
      case "lower":
        return t("lower than threshold")
      case "equals":
        return t("not equal to desired value")
      default:
        break;
    }
  }



  const RenderSeverityLevel = ({ level }) => {

    switch (level) {
      case "critical":
        return (
          <Badge bg="" className="badge-soft-danger me-2">{t(level)}</Badge>
        )
      case "warning":
        return (
          <Badge bg="" className="badge-soft-warning me-2">{t(level)}</Badge>
        )
      case "info":
        return (
          <Badge bg="" className="badge-soft-primary me-2">{t(level)}</Badge>
        )
      default:
        return (
          <Badge bg="" className="badge-soft-secondary me-2">{t(level || "-")}</Badge>
        )
    }
  }


  const ShowRuleViolatedBadge = (violated) => {

    switch (violated) {
      case false:
        return (
          <Badge bg="" className="badge-soft-success me-2">{t("OK")}</Badge>
        )
      case true:
        return (
          <Badge bg="" className="badge-soft-warning me-2">{t("Violated")}</Badge>
        )
      default:
        return (
          <Badge bg="" className="badge-soft-secondary me-2">{t("n/a")}</Badge>
        )
    }
  }


  const RenderUnit = (unit) => {

    switch (unit) {
      case "state":
        return ""
      default:
        return unit
    }
  }

  const RenderLocalizedRuleComponent = (item) => {

    return (<div
      key={item?.id}
      // onClick={() => handleClickOnViolation(item?.iot_node_id)}
      style={{ cursor: "pointer" }}
      className="mb-2">
      {ShowRuleViolatedBadge(item?.violated)} <strong>{t(item?.display_name) || "-"}</strong>: {t("Currently")} <strong>{item?.last_value?.toLocaleString(i18n.language === "de" ? de : "en", {
        style: 'decimal',
        maximumFractionDigits: 1,
      })} {RenderUnit(item?.unit)} </strong> ({
        item?.lower_limit && item?.upper_limit // Upper and lower limit
          ? `${t("should be between")} ${item?.lower_limit?.toLocaleString(i18n.language === "de" ? de : "en", {
            style: 'decimal', maximumFractionDigits: 1,
          })} ${t("and")} ${item?.upper_limit?.toLocaleString(i18n.language === "de" ? de : "en", {
            style: 'decimal', maximumFractionDigits: 1,
          })} ${RenderUnit(item?.unit)}`

          : item?.lower_limit  // Only lower limit
            ? `${t("should be")} > ${item?.lower_limit?.toLocaleString(i18n.language === "de" ? de : "en", {
              style: 'decimal', maximumFractionDigits: 1,
            })} ${RenderUnit(item?.unit)}`

            : item?.upper_limit // Only upper limit
              ? `${t("should be")} < ${item?.upper_limit?.toLocaleString(i18n.language === "de" ? de : "en", {
                style: 'decimal', maximumFractionDigits: 1,
              })} ${RenderUnit(item?.unit)}`
              : ``}{item?.target_value ? `${(item?.upper_limit || item?.lower_limit) ? "; " : ""}${t("Target Value")} ${t("is")} ${item?.target_value?.toLocaleString(i18n.language === "de" ? de : "en", {
                style: 'decimal', maximumFractionDigits: 1,
              })})` : ")"}
    </div>)
  }


  const [selectedNodeId, setSelectedNodeId] = useState(null)

  function formatDataDiff(targetDate) {

    const currentDate = new Date();
    const timeDifference = currentDate - targetDate;

    if (timeDifference <= 1 * 60 * 1000) {
      return t("Just now")
    } else {
      return formatDistanceToNow(targetDate, { locale: i18n.language === "de" ? de : null, includeSeconds: true, addSuffix: true })
    }
  }

  const [filterLevel, setFilterLevel] = useState("all");

  // Use useEffect to trigger handleFiltration after state change
  useEffect(() => {
    // Only trigger on state change
    let updatedList = violations;

    if (filterLevel === "all") {
      updatedList = violations?.results;
    } else {
      updatedList = updatedList?.results?.filter(violation => violation?.level === filterLevel);
    }

    setFilteredViolations(updatedList);

  }, [filterLevel, violations]);

  const renderHealthState = (status) => {

    switch (status) {
      case "healthy":
        return (
          <Badge bg="" className="badge-soft-success me-2">{t("Healthy")}</Badge>
        )
      case "warning":
        return (
          <Badge bg="" className="badge-soft-warning me-2">{t("Warning")}</Badge>
        )
      case "unhealthy":
        return (
          <Badge bg="" className="badge-soft-danger me-2">{t("Warning")}</Badge>
        )
      case "offline":
        return (
          <Badge bg="" className="badge-soft-secondary me-2">{t("Offline")}</Badge>
        )
      case "no_asset_connection":
        return (
          <Badge bg="" className="badge-soft-warning me-2">{t("Not connected")}</Badge>
        )
      default:
        return (
          <Badge bg="" className="badge-soft-secondary me-2">{t("n/a")}</Badge>
        )
    }
  }

  const AssetState = () => {

    const renderState = (status) => {

      switch (status) {
        case "state_running":
          return (
            <Badge bg="" className="badge-soft-success me-2">{t("running")}</Badge>
          )
        case "state_setup":
          return (
            <Badge bg="" className="badge-soft-primary me-2">{t("setup")}</Badge>
          )
        case "state_error":
          return (
            <Badge bg="" className="badge-soft-danger me-2">{t("short_error")}</Badge>
          )
        case "no state_maintenance":
          return (
            <Badge bg="" className="badge-soft-warning me-2">{t("short_maintenance")}</Badge>
          )
        default:
          return (
            <Badge bg="" className="badge-soft-warning me-2">{t("n/a")}</Badge>
          )
      }
    }

    return (
      assetData?.health?.current_states?.length > 0 ?
        <div className="d-flex flex-wrap">
          {
            assetData?.health?.current_states?.map((reason) => {
              return (<h3 key={reason} className="mb-2" >{renderState(reason)}</h3>)
            })
          }
        </div>
        :
        <div><Badge bg="" className="badge-soft-secondary me-2">{t("-")}</Badge></div>
    )
  }

  const Violations = () => {

    // console.log("Health array ----->", assetData?.health?.health_rules)

    return (
      <Card>
        <Card.Header>
          <Row>
            <Col>
              <Card.Title>{t("Current state and violations")}</Card.Title>
            </Col>
            {/* <Col xs="auto" className="ms-auto text-end mt-3">
              <Button
                onClick={() => loadData()}
                variant={"outline-primary"}
                size="sm"
                className="shadow-sm me-1">
                <RefreshCw className="feather" />
              </Button>
            </Col> */}
          </Row>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={6} >
              {/* Healthy State */}
              <p className="mb-2">{t("Overall condition")}</p>
              <h3  >
                {renderHealthState(assetData?.health?.status)}
              </h3>
            </Col>
            {assetData?.health?.current_states?.length > 0 && <Col md={6} >
              <p className="mb-2">{t("Current Asset State")}</p>
              <AssetState />
            </Col>}
          </Row>
          <Row className="mt-3">
            <Col>

              <div className="d-flex-wrap">
                {
                  assetData?.health?.health_rules?.filter(item => item?.violated)?.length > 0
                    ? assetData?.health?.health_rules
                      ?.filter(item => item?.violated !== null)
                      ?.sort((a, b) => {
                        // First, sort by isActive (true first)
                        if (a.violated === b.violated) {
                          // If isActive is the same, sort by name
                          return a?.technical_name?.localeCompare(b?.technical_name);
                        }
                        return a.isActive ? -1 : 1;
                      })
                      ?.map((item) => {
                        return (
                          <OverlayTrigger
                            trigger="click"
                            rootClose
                            placement="top"
                            key={item?.id}
                            overlay={
                              <Popover key={item?.id}>
                                {/* <Popover.Header as="h3">{`${item?.display_name}`}</Popover.Header> */}
                                <Popover.Body>
                                  <h4>{RenderLocalizedRuleComponent(item)}</h4><br />
                                  <strong>{t("Last update")}: </strong> {item?.asset_log_time ? formatDataDiff(parseISO(item?.asset_log_time)) : '-'}

                                  <Button variant="outline-primary" size="sm" className="shadow-sm me-1 mt-2" onClick={() => setSelectedNodeId(item?.iot_node_id)}>
                                    {t("View chart")}
                                  </Button>
                                  <Button variant="outline-primary" size="sm" className="shadow-sm me-1 mt-2" onClick={() => setShowIoTDataPointConfig(item?.iot_node_id)}>
                                    {t("Update rule")}
                                  </Button>
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            {RenderLocalizedRuleComponent(item)}
                          </OverlayTrigger>
                        )
                      })
                    : t("No current violations detected.")
                }
              </div>
            </Col>
          </Row>

        </Card.Body>
      </Card>
    )
  }

  return (<div style={{ background: palette["gray-200"], padding: "20px" }}>

    <SingleMaintenanceEventModal
      show={showSingleMaintenanceEvent}
      selected_event={selected_event}
      asset_id={assetData?.id}
      handleClose={() => {
        set_selected_event(null)
        set_refresh(!refresh)
        setShowSingleMaintenanceEvent(false)
      }}
    />

    <AssetDigitalTwinChartModal
      show={(showChart || selectedNodeId) ? true : false}
      handleClose={() => {
        setShowChart(null);
        setSelectedNodeId(null);
      }}
      desired_iot_node_id={showChart?.iot_node_id || selectedNodeId}
      desired_start_time={showChart?.period_start ? new Date(new Date(showChart?.period_start).getTime() - (60 * 5 * 1000)) : null} // Subtract 1 hour
      desired_end_time={showChart?.period_end ? new Date(new Date(showChart?.period_end).getTime() + (60 * 5 * 1000)) : null} // Add 1 hour
      asset_data={assetData}
      asset_id={id}
    />
    <SingleIoTDataPointModal
      desired_node_id={selectedNodeId}
      showAdvanced
      show={showIoTDataPointConfig}
      asset_id={id}
      // slim
      handleClose={() => {
        // loadData();
        setShowIoTDataPointConfig(null)
      }}
    />

    <Row className="mt-2 mb-4">
      <Col >
        <div className="h3">{t("Maintenance Cockpit")}</div>
      </Col>
      <Col xs="auto" className="ms-auto text-end">

        {/* <Button onClick={() => navigate("/assets/" + assetData?.id + "?focus=digital-twin")} variant="outline-primary" size="sm" className="shadow-sm me-1">
          <Wifi className="feather" /> {isMobile ? "" : t("Live-View")}
        </Button> */}

      </Col>
    </Row>
    <Row>
      <Col md="6">
        <Violations />
      </Col>
    </Row>
    {/* <Row className="d-flex">
      <Col md={focusedSection === null ? "4" : focusedSection === "history" ? "8" : "2"} style={{ display: 'flex' }}>
        <Card className="flex-fill d-flex flex-column">
          <Card.Header>
            <Card.Title>
              {t("Operational Log")}
            </Card.Title>
          </Card.Header>
          <Card.Body className="d-flex flex-column flex-grow-1">
            {focusedSection === "history"
              ? <span className={"badge bg-warning"}>Coming soon</span>
              : <div className="mt-auto">
                <Button
                  onClick={() => setFocusedSection("history")}
                  size="sm"
                  variant="outline-primary"
                  className="shadow-sm me-1">
                  <Maximize2 className="feather" /> {t("Show")}
                </Button>
              </div>}
          </Card.Body>
        </Card>
      </Col>
      <Col md={focusedSection === null ? "4" : focusedSection === "current" ? "8" : "2"} style={{ display: 'flex' }}>
        <Card className="flex-fill d-flex flex-column">
          <Card.Header>
            <Card.Title>
              {t("Current state and violations")}
            </Card.Title>
          </Card.Header>
          <Card.Body className="d-flex flex-column flex-grow-1">
            {focusedSection === "current"
              ? <AssetHealthCheckSection assetData={assetData} loadData={() => loadData()} />
              : <div className="mt-auto">
                <Button
                  onClick={() => setFocusedSection("current")}
                  size="sm"
                  variant="outline-primary"
                  className="shadow-sm me-1">
                  <Maximize2 className="feather" /> {t("Show")}
                </Button>
              </div>}
          </Card.Body>
        </Card>
      </Col>
      <Col md={focusedSection === null ? "4" : focusedSection === "forecast" ? "8" : "2"} style={{ display: 'flex' }}>
        <Card className="flex-fill d-flex flex-column">
          <Card.Header>
            <Card.Title>
              {t("Predictive Maintenance")}
            </Card.Title>
          </Card.Header>
          <Card.Body className="d-flex flex-column flex-grow-1">
            {focusedSection === "forecast"
              ? <span className={"badge bg-warning"}>Coming soon</span>
              : <div className="mt-auto">
                <Button
                  onClick={() => setFocusedSection("forecast")}
                  size="sm"
                  variant="outline-primary"
                  className="shadow-sm me-1">
                  <Maximize2 className="feather" /> {t("Show")}
                </Button>
              </div>}
          </Card.Body>
        </Card>
      </Col>
    </Row> */}
    <Row>
      <Col>
        <Card>
          <Card.Header>
            <Col>
              <Card.Title>{t("Violation history")}</Card.Title>
            </Col>
            {/* <Col xs="auto" className="ms-auto text-end mt-n4">
              <Button
                onClick={() => {
                  set_selected_event(null)
                  setShowSingleMaintenanceEvent(true)
                }}
                size="sm"
                variant="outline-primary"
                className="shadow-sm me-1">
                <Plus className="feather" /> {t("New")}
              </Button>
            </Col> */}


          </Card.Header>
          <Card.Body>

            <div>
              <div className="mb-3">
                <div className="mb-1">{t("Severity")}</div>
                <div>
                  <ButtonToolbar>
                    <ButtonGroup>
                      <Button
                        variant={filterLevel === "info" ? "primary" : "outline-primary"}
                        size="sm"
                        onClick={() => filterLevel === "info" ? setFilterLevel("all") : setFilterLevel("info")}>
                        {t("Info")}
                      </Button>
                      <Button
                        variant={filterLevel === "warning" ? "primary" : "outline-primary"}
                        size="sm"
                        onClick={() => filterLevel === "warning" ? setFilterLevel("all") : setFilterLevel("warning")}>
                        {t("Warning")}
                      </Button>
                      <Button
                        variant={filterLevel === "critical" ? "primary" : "outline-primary"}
                        size="sm"
                        onClick={() => filterLevel === "critical" ? setFilterLevel("all") : setFilterLevel("critical")}>
                        {t("Critical")}
                      </Button>
                    </ButtonGroup>
                  </ButtonToolbar>
                </div>
              </div>

              {isLoading === true ?
                <LoadingSpinner withText /> :
                (filteredViolations?.length > 0)
                  ? // If data found


                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th scope="col">{t("Severity")}</th>
                        <th scope="col">{t("Message")}</th>
                        {/* <th scope="col">{t("Actual value")}</th> */}
                      </tr>
                    </thead>
                    <tbody>{filteredViolations?.map((violation) => {

                      const info_for_violation = violations?.infos?.find(
                        (info) => info?.iot_node_id === violation?.iot_node_id || info?.asset_metric_id === violation?.asset_metric_id
                      );

                      return (
                        <tr key={violation?.id}
                          style={{ cursor: "pointer" }}
                          onClick={e => violation?.iot_node_id && setShowChart(violation)}
                        >
                          <td><RenderSeverityLevel level={violation?.level} /></td>
                          <td>
                            <OverlayTrigger
                              placement='bottom'
                              overlay={
                                <Popover>
                                  <Popover.Header as='h3'>{t("Time span")}</Popover.Header>
                                  <Popover.Body>
                                    {format(parseISO(violation?.period_start), "d MMM HH:mm:ss", { locale: i18n.language === "de" ? de : null })} - {format(parseISO(violation?.period_end), "d MMM HH:mm:ss", { locale: i18n.language === "de" ? de : null })}
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <Info className="feather me-2" />
                            </OverlayTrigger>
                            <strong>{info_for_violation?.name || "-"}</strong> {t("was")} <strong>{violation?.actual_value?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 })} {info_for_violation?.unit}</strong>
                            {" "} (<RenderComparison mode={violation?.comparison_mode} /> <strong>{violation?.threshold?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0 })} {info_for_violation?.unit}</strong>) {t("for")} <RenderSecondsDuration seconds={violation?.seconds} /> {t("starting")} {format(parseISO(violation?.period_start), `d${i18n.language === "de" ? "." : ""} MMM, HH:mm:ss`, { locale: i18n.language === "de" ? de : null })} {i18n.language === "de" ? "Uhr" : null}</td>


                          {/* {violation?.iot_node_id &&
                            <td className="table-action">
                              <BarChart alt="chart" className="align-middle" size={18} style={{ cursor: "pointer" }} onClick={e => {
                                e.stopPropagation();
                                setShowChart(violation);
                              }
                              } />
                            </td>} */}

                        </tr >
                      );
                    })}</tbody>
                  </Table>
                  : // If no data found

                  <p className="card-subtitle text-muted">
                    {t("No data found.")}
                  </p>}
            </div>

          </Card.Body>
        </Card>
      </Col>
    </Row>

    <Row>
      <Col>
        <Card>
          <Card.Header>
            <Col>
              <Card.Title>{t("Maintenance Log")}</Card.Title>
            </Col>
            <Col xs="auto" className="ms-auto text-end mt-n4">
              <Button
                onClick={() => {
                  set_selected_event(null)
                  setShowSingleMaintenanceEvent(true)
                }}
                size="sm"
                variant="outline-primary"
                className="shadow-sm me-1">
                <Plus className="feather" /> {t("New")}
              </Button>
            </Col>


          </Card.Header>
          <Card.Body>
            {isLoading === true ?
              <LoadingSpinner withText /> :
              (serviceEvents.length > 0)
                ? // If data found

                <Table responsive hover>
                  <thead>
                    <tr>
                      <th scope="col">{t("Status")}</th>
                      <th scope="col">{t("Date")}</th>
                      <th scope="col">{t("Title")}</th>
                    </tr>
                  </thead>
                  <tbody>{serviceEvents.map((event) => {

                    return (
                      <tr key={event?.id} onClick={() => {
                        set_selected_event(event)
                        setShowSingleMaintenanceEvent(true)
                      }
                      } style={{ cursor: "pointer" }}>
                        <td>{renderStatus(event?.status)}</td>
                        <td>{event?.appointment !== null
                          ? parseISO(event.appointment) < (new Date() - 24 * 60 * 60 * 1000)
                            ? format(parseISO(event.appointment), "dd MMM yyyy", { locale: i18n.language === "de" ? de : null })
                            : formatDistanceToNow(parseISO(event.appointment), { locale: i18n.language === "de" ? de : null, addSuffix: true })
                          : "-"
                        }</td>
                        <td>{event?.description}</td>

                        {UserDataManager.getInstance().isAdmin() &&
                          <td className="table-action">
                            <Trash alt="Delete" className="align-middle" size={18} style={{ cursor: "pointer" }} onClick={e => {
                              e.stopPropagation();
                              window.confirm(t("Are you sure you wish to delete this entry?")) && handleDelete(e, event?.id)
                            }
                            } />
                          </td>}

                      </tr >
                    );
                  })}</tbody>
                </Table>

                : // If no data found

                <p className="card-subtitle text-muted">
                  {t("No data found.")}
                </p>}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </div>)
}

export default MaintenanceSection;
