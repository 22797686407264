import {
  Activity,
  HardDrive,
  Wifi,
  Bell,
  HelpCircle,
  Inbox,
  Crosshair,
  ShoppingBag,
  Database,
  Tool,
  Briefcase,
  DollarSign,
  Clipboard
} from "react-feather";

const cap_on_Admin_Section_Dashboard = [
  {
    href: "/dashboard",
    icon: Activity,
    title: "Dashboard",
  },
  {
    href: "/alerts",
    icon: Bell,
    title: "Alerts",
  },
]

const cap_on_Admin_Section_Assets = [
  {
    href: "/assets",
    icon: HardDrive,
    title: "Assets",
  },
  {
    href: "/gateways",
    icon: Wifi,
    title: "IoT-Gateways",
  },
  {
    href: "/watchtower",
    icon: Crosshair,
    title: "Watch Tower",
  }
]

const cap_on_Section_Support = [
  // {
  //   href: "/marketplace",
  //   icon: ShoppingBag,
  //   title: "cap-on Marketplace",
  // },
  {
    href: "/data-integration",
    icon: Database,
    title: "Client API Documenation",
  },
  // {
  //   href: "/faq",
  //   icon: HelpCircle,
  //   title: "FAQ",
  // },
  {
    href: "/support",
    icon: Inbox,
    // badge: 2,
    title: "Support",
  }
];

const cap_on_Admin_Section_CRM = [
  {
    href: "/clients",
    icon: Briefcase,
    title: "Clients",
  },
  {
    href: "/manufacturers",
    icon: Tool,
    title: "Manufacturers",
  },
  {
    href: "/contracts",
    icon: Clipboard,
    title: "Contracts",
  }
];

const navItems = [
  {
    title: "Home",
    pages: cap_on_Admin_Section_Dashboard,
  },
  {
    title: "Asset Management",
    pages: cap_on_Admin_Section_Assets,
  },
  {
    title: "Contract Management",
    pages: cap_on_Admin_Section_CRM,
  },
  {
    title: "Support",
    pages: cap_on_Section_Support,
  }
];

export default navItems;
