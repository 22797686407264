import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Dropdown, Form, Modal, OverlayTrigger, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import { parseISO } from "date-fns";
import UserDataManager from "../Helper/UserDataManager";
import { Crosshair, Plus } from "react-feather";
import { isMobile } from "react-device-detect";
import NotyfContext from "../../contexts/NotyfContext";

const AddGatewayModal = (props) => {
  const navigate = useNavigate();
  let { id } = useParams();
  const notyf = useContext(NotyfContext);
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  var gateway_serial = "";
  var passcode = "";
  var tailscale_node_id = "";
  var internal_ip = "";

  function handleChangeSerial(event) {
    gateway_serial = event.target.value;
  }
  function handleChangePasscode(event) {
    passcode = event.target.value;
  }
  function handleChangeTailscale_node_id(event) {
    tailscale_node_id = event.target.value;
  }
  function handleChangeInternal_ip(event) {
    internal_ip = event.target.value;
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (gateway_serial === "") {
      setError({ showMessage: true, errorData: null, customMessage: t("Please enter a gateway serial.") })
    } else {
      setError({ showMessage: false, errorData: null })

      // hier sollte man im dropdown einen Kunden auswählen können, dessen ID dann im Post-Request gesetzt wird
      axios
        .post("/iot_gateways/", {
          serial: gateway_serial,
          passcode: passcode,
          tailscale_node_id: tailscale_node_id,
          internal_ip: internal_ip
        })
        .then(function (response) {
          // handle success
          console.log(response.status, "Load");
          navigate("/gateways/" + response.data.id);

          notyf.open({
            type: "success",
            message: t("New item \"") + gateway_serial + t("\" has been added."),
            duration: "4500",
            ripple: false,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          })

        })
        .catch(function (error) {
          // handle error
          setError({ showMessage: true, errorData: error })
          console.log(error);
        });
    }
  }

  return (
    <>
      <Button
        onClick={() => toggle()}
        variant="primary"
        className="shadow-sm me-1" // me-1
      >
        <Plus className="feather" /> {t("New")}
      </Button>
      <Modal size="ms" show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t("Create Gateway")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
            <p><strong>Prozess:</strong>
              <br />1. Gateway hier anlegen
              <br />2. User mit Rolle "device" anlegen und Gateway im Dropdown auswählen
              <br />3. Asset anlegen und Gateway im Dropdown auswählen
              <br /><br />
            </p>
          </Row>

          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Gateway Serial")}</Form.Label>
                  <Form.Control
                    placeholder="CBIOT2050X__"
                    defaultValue={"CBIOT2050X"}
                    required
                    onChange={handleChangeSerial}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Passcode")}</Form.Label>
                  <Form.Control
                    placeholder={t("8-letter code")}
                    onChange={handleChangePasscode}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Tailscale Node-ID")}</Form.Label>
                  <Form.Control
                    placeholder="Tailscale ID"
                    onChange={handleChangeTailscale_node_id}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Internal IP")}</Form.Label>
                  <Form.Control
                    placeholder={t("Internal IP")}
                    onChange={handleChangeInternal_ip}
                  />
                </Form.Group>
              </Col>
            </Row>
            <br />
            <hr />
            <Row>
              <Col xs="auto" className="ms-auto text-end mt-n1">
                <Button variant="secondary" onClick={() => toggle(false)}>
                  {t("Cancel")}
                </Button>{" "}
                <Button variant={"primary"} type="submit">
                  {t("Create")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddGatewayModal;
