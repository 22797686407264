import { useContext, useEffect, useState } from "react";
import { Accordion, Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../../Helper/ErrorHandler";
import NotyfContext from "../../../contexts/NotyfContext";
import { getCoreBaseURL } from "../../../config";
import { Plus, Trash } from "react-feather";

const SingleIoTDataPointModal = ({ show, desired_protocol, desired_plc_node_id, node_data, desired_node_id, isNew, asset_id, handleClose, showAdvanced, slim }) => {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const notyf = useContext(NotyfContext);

  // iot_nodes
  const [iot_function, set_iot_function] = useState();
  const [read_interval, set_read_interval] = useState();
  const [subscription_interval, set_subscription_interval] = useState();
  const [connection_protocol, set_connection_protocol] = useState();
  const [capon_core_id, set_capon_core_id] = useState();
  const [FunctionsList, setFunctionsList] = useState([]);
  const [ai_report_relevance, set_ai_report_relevance] = useState();
  const [lower, setLower] = useState();
  const [upper, setUpper] = useState();
  const [target, setTarget] = useState();

  // Log Type Number
  const [plc_node_id, set_plc_node_id] = useState();
  const [type_number, set_type_number] = useState();
  const [aggregation_type, set_aggregation_type] = useState();
  const [display_name, set_display_name] = useState();
  const [technical_name, set_technical_name] = useState();

  const [unit, set_unit] = useState();
  const [state_names, set_state_names] = useState([]);

  const FunctionsOptions = () => {
    return FunctionsList?.map((unit) => {
      return (
        <option key={unit} value={unit}>{t(unit)}</option>
      );
    });
  };

  useEffect(() => {

    if (show) {
      console.log(isNew ? "Creating a new IoT item" : "Changing an existing IoT item", desired_plc_node_id, desired_node_id, node_data)

      if (desired_node_id) {
        axios
          .get("/assets/" + asset_id + "/iot_nodes/" + desired_node_id)
          .then(function (response) {
            // handle success
            console.log(response.status, "/iot_nodes/{id}");

            set_type_number(response.data?.type_number);
            set_iot_function(response.data?.function);
            set_read_interval(response.data?.read_interval / 1000);
            set_subscription_interval(response.data?.subscription_interval);
            set_ai_report_relevance(response.data?.ai_report_relevance);
            set_connection_protocol(response.data?.connection_protocol);
            set_capon_core_id(response.data?.capon_core_id);
            setLower(response.data?.lower_limit);
            setUpper(response.data?.upper_limit);
            setTarget(response.data?.target_value)

            set_state_names(response.data?.log_type?.state_names?.sort((a, b) => a?.value > b?.value));
            set_unit(response.data?.log_type?.unit);
            set_display_name(response.data?.log_type?.display_name);
            set_technical_name(response.data?.log_type?.technical_name);
            set_plc_node_id(response.data?.log_type?.plc_node_id);

          })
          .catch(function (error) {
            // handle error
            console.log(error);
          });

      } else if (desired_plc_node_id) {
        // This comes from Gateway-OPC-UA-Setup
        set_plc_node_id(desired_plc_node_id)

        axios
          .get(getCoreBaseURL() + "/openapi.json")
          .then(function (response) {
            // handle success
            console.log(response.status, "Loaded JSON");
            setFunctionsList(response.data["components"]["schemas"]["IoTNodeFunction"]["enum"]);
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          });

      } else if (node_data) {
        // This comes from IoT-Data-Setup

        // IoT node data
        set_type_number(node_data?.type_number);
        set_iot_function(node_data?.function);
        set_read_interval(node_data?.read_interval ? node_data?.read_interval / 1000 : null);
        set_subscription_interval(node_data?.subscription_interval);
        set_ai_report_relevance(node_data?.ai_report_relevance);
        set_connection_protocol(node_data?.connection_protocol);
        set_capon_core_id(node_data?.capon_core_id);
        setLower(node_data?.lower_limit);
        setTarget(node_data?.target_value)
        setUpper(node_data?.upper_limit);

        // Log type data
        set_unit(node_data?.log_type?.unit);
        set_display_name(node_data?.log_type?.display_name);
        set_technical_name(node_data?.log_type?.technical_name);
        set_state_names(node_data?.log_type?.state_names?.sort((a, b) => a?.value > b?.value));
        set_plc_node_id(node_data?.log_type?.plc_node_id)

        axios
          .get(getCoreBaseURL() + "/openapi.json")
          .then(function (response) {
            // handle success
            console.log(response.status, "Loaded JSON");
            setFunctionsList(response.data["components"]["schemas"]["IoTNodeFunction"]["enum"]);
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          });
      } else {
        set_plc_node_id()
        set_ai_report_relevance();
        set_type_number();
        set_iot_function();
        set_read_interval();
        set_subscription_interval();
        set_connection_protocol();
        set_capon_core_id();
        set_unit();
        set_display_name();
        set_technical_name();
        setLower();
        setUpper();
        setTarget();
        set_state_names([]);
      }

      if (desired_protocol) {
        set_connection_protocol(desired_protocol)
      }

    } else {
      set_plc_node_id()
      set_ai_report_relevance();
      set_type_number();
      set_iot_function();
      set_read_interval();
      set_subscription_interval();
      set_connection_protocol();
      set_technical_name()
      set_capon_core_id();
      set_unit();
      set_display_name();
      setLower();
      setUpper();
      setTarget();
      set_state_names([]);
    }

  }, [show]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isNew) {

      let body = {
        "asset_id": asset_id,
        "plc_node_id": plc_node_id,
        "type_number": type_number,
        "function": iot_function,
        "read_interval": read_interval === "" ? null : read_interval * 1000,
        "subscription_interval": subscription_interval === "" ? null : subscription_interval,
        "connection_protocol": connection_protocol,
        "capon_core_id": capon_core_id,
        "ai_report_relevance": ai_report_relevance,
        "display_name": display_name,
        "technical_name": technical_name,
        "unit": unit,
        "lower_limit": lower === "" ? null : lower,
        "upper_limit": upper === "" ? null : upper,
        "target_value": target === "" ? null : target,
        "aggregation_type": aggregation_type,
        "state_names": state_names
      }

      axios
        .post("/assets/" + asset_id + "/iot_nodes/", body)
        .then(function (response) {
          // handle success
          console.log(response.status, "/iot_nodes POST");

          notyf.open({
            type: "success",
            message: t("Item \"") + (response.data?.log_type?.display_name || response.data?.log_type?.plc_node_id) + t("\" updated."),
            duration: "4500",
            ripple: false,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          })

          handleClose()
        })
        .catch(function (error) {
          // handle error
          setError({ showMessage: true, errorData: error })
          console.log(error);
        });
    } else {


      let body = {
        "asset_id": asset_id,
        "type_number": type_number,
        "function": iot_function,
        "ai_report_relevance": ai_report_relevance,
        "read_interval": read_interval === "" ? null : read_interval * 1000,
        "subscription_interval": subscription_interval === "" ? null : subscription_interval,
        "connection_protocol": connection_protocol,
        "capon_core_id": capon_core_id,
        "display_name": display_name,
        "technical_name": technical_name,
        "unit": unit,
        "lower_limit": lower === "" ? null : lower,
        "upper_limit": upper === "" ? null : upper,
        "target_value": target === "" ? null : target,
        "plc_node_id": plc_node_id,
        "aggregation_type": aggregation_type,
        "state_names": state_names
      }

      // console.log("--->", body)

      axios
        .put("/assets/" + asset_id + "/iot_nodes/" + (node_data?.id || desired_plc_node_id || desired_node_id), body)
        .then(function (response) {
          // handle success
          console.log(response.status, "/iot_nodes PUT");

          notyf.open({
            type: "success",
            message: t("Item \"") + (response.data?.log_type?.display_name || response.data?.log_type?.plc_node_id) + t("\" updated."),
            duration: "4500",
            ripple: false,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          })

          handleClose()
        })
        .catch(function (error) {
          // handle error
          setError({ showMessage: true, errorData: error })
          console.log(error);
        });
    }
  };

  // const handleSubmitLogType = async (e) => {
  //   e.preventDefault();

  //   const body = {
  //     // "type_number": 0,
  //     "plc_node_id": plc_node_id,
  //     "aggregation_type": aggregation_type,
  //     "display_name": display_name,
  //     "technical_name": technical_name,
  //     "unit": unit,
  //     "state_names": state_names
  //   }
  //   // console.log("---->body to be PUT:", body)
  //   axios
  //     .put("/assets/asset_log_types/" + asset_log_type_id, body)
  //     .then(function (response) {
  //       // handle success
  //       console.log(response.status, "/asset_log_types PUT");

  //       notyf.open({
  //         type: "success",
  //         message: t("Item \"") + (display_name || plc_node_id || "unknown") + t("\" updated."),
  //         duration: "4500",
  //         ripple: false,
  //         dismissible: true,
  //         position: {
  //           x: "right",
  //           y: "bottom",
  //         },
  //       })

  //       setActiveKey(null);  // Set to null or an empty string to close all items

  //       // set_asset_log_type_id(response.data?.id)
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       setError({ showMessage: true, errorData: error })
  //       console.log(error);
  //     });


  // };

  // const [asset_log_type_id, set_asset_log_type_id] = useState();
  // const [assetLogType, setAssetLogType] = useState();

  // useEffect(() => {

  //   if (asset_log_type_id) {
  //     axios
  //       .get("/assets/asset_log_types/", {
  //         params: {
  //           "asset_log_type_id": asset_log_type_id
  //         }
  //       })
  //       .then(function (response) {
  //         // handle success
  //         console.log(response.status, "/log_type", response.data?.length === 1 ? response.data[0] : null);
  //         setAssetLogType(response.data?.length === 1 ? response.data[0] : null);
  //       })
  //       .catch(function (error) {
  //         // handle error
  //         console.log(error);
  //       });
  //   } else {
  //     setAssetLogType();
  //   }

  // }, [asset_log_type_id]);

  // const [time_rhythm, set_time_rhythm] = useState();
  const [activeKey, setActiveKey] = useState("0");

  const handleValueChange = (index, field, value) => {
    const updatedStateNames = [...state_names];
    updatedStateNames[index] = { ...updatedStateNames[index], [field]: value };
    console.log("Changed: ", updatedStateNames[index])
    set_state_names(updatedStateNames);
  };

  const [new_state_names, set_new_state_names] = useState([])
  const [updatingStateNames, setUpdatingStateNames] = useState(false)

  useEffect(() => {
    // console.log("--update please", new_state_names?.map(item => item?.value + "-" + item?.display_name))
    set_state_names(new_state_names)
    setUpdatingStateNames(false)

  }, [updatingStateNames])

  const handleDelete = (item) => {
    // Filter out the item to be deleted
    const updatedStateNames = state_names?.filter(i => item !== i)?.sort((a, b) => a?.value > b?.value);

    // Update the state with the sorted array
    set_new_state_names([...updatedStateNames]);

    setUpdatingStateNames(true)
  };

  const handleAdd = () => {
    // Get the last state object in the array

    // console.log("----->", state_names)

    // if (state_names === undefined) {
    //   set_state_names([])
    // }

    // console.log("----->", state_names)

    const newValue = (state_names?.length > 0) ? (state_names[state_names?.length - 1].value + 1) : 0;

    const newState = { value: newValue, display_name: null, color_coding: null };
    set_state_names([...state_names, newState]);
  };

  return (
    <Modal
      size="ms"
      show={show}
      onHide={handleClose}
      style={{ zIndex: 1060 }} // Higher z-index to ensure it appears above the first modal
    >
      <Modal.Header closeButton>{isNew ? t("Add data point") : t("Edit data point")}</Modal.Header>
      <Modal.Body className="m-3">
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        <Row>
          <Form onSubmit={handleSubmit}>
            {/* Asset ID and Connection Protocol */}
            {!slim && <Row>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Protocol")}</Form.Label>
                  <Form.Select
                    name="state"
                    required
                    onChange={event => set_connection_protocol(event.target.value)}>
                    <option value="">{t("-- Select --")}</option>
                    <option value={"opc_ua"} selected={"opc_ua" === connection_protocol}>{t("opc_ua")}</option>
                    <option value={"mqtt"} selected={"mqtt" === connection_protocol}>{t("mqtt")}</option>
                    <option value={"ads"} selected={"ads" === connection_protocol}>{t("ads")}</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>}

            {connection_protocol && <>
              {!slim && <><Row>
                <Col>

                  <Form.Group className="mb-3">
                    <Form.Label>{t("cap-on Core Type")}</Form.Label>
                    <Form.Select
                      name="state"
                      // defaultValue={capon_core_id}
                      required
                      onChange={event => set_capon_core_id(event.target.value)}>
                      <option value="">{t("-- Select --")}</option>
                      <option value={"asset_logs"} selected={capon_core_id === "asset_logs"}>{t("asset_logs")}</option>
                      <option value={"usage_cycles"} selected={capon_core_id === "usage_cycles"}>{t("usage_cycles")}</option>
                      <option value={"usage_bool"} selected={capon_core_id === "usage_bool"}>{t("usage_bool")}</option>
                      <option value={"opc_connection"} selected={capon_core_id === "opc_connection"}>{t("opc_connection")}</option>
                      {/* <option value={"asset_logs_energy"}>{t("asset_logs_energy")}</option> */}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

                <Row>
                  <Col md="6" xs="6">
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Read Interval")}</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          defaultValue={read_interval}
                          type="number"
                          onChange={e => set_read_interval(e.target.value)}
                          placeholder={t("Interval")}
                        />
                        <InputGroup.Text>{t("s")}</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md="6" xs="6">
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Subscription Interval")}</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Select
                          name="state"
                          required
                          onChange={event => set_subscription_interval(event.target.value)}>
                          <option value="">{t("No subscription")}</option>
                          <option value={200} selected={200 === subscription_interval}>0.2s</option>
                          <option value={500} selected={500 === subscription_interval}>0.5s</option>
                          <option value={1000} selected={1000 === subscription_interval}>1s</option>
                          <option value={5000} selected={5000 === subscription_interval}>5s</option>
                          <option value={10000} selected={10000 === subscription_interval}>10s</option>
                        </Form.Select>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row></>}

              {!slim && <div>

                {/* <br /> */}
                {/* {!isNew && <p>For security reasons, some settings cannot be changed here. Please remove this datapoint and add a new one instead.</p>} */}

                <Row  >

                  <Form.Group className="mb-3">
                    <Form.Label>{t("Technical Name")}</Form.Label>
                    <Form.Control
                      defaultValue={technical_name}
                      placeholder="Name"
                      onChange={e => set_technical_name(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>{t("Display Name")}</Form.Label>
                    <Form.Control
                      defaultValue={display_name}
                      placeholder="Name"
                      onChange={e => set_display_name(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>{t("PLC Node ID")}</Form.Label>
                    <Form.Control
                      defaultValue={plc_node_id || desired_plc_node_id}
                      placeholder="e.g. OPC UA-format: ns=X;s=Y"
                      onChange={e => set_plc_node_id(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>{t("Unit")}</Form.Label>
                    <Form.Select
                      name="state"
                      value={unit}
                      onChange={(event) => set_unit(event.target.value)}>
                      <option value="">{t("-- Select --")}</option>

                      <optgroup label={t("Relative")}>
                        <option value={"%"}>{t("%")}</option>
                      </optgroup>

                      <optgroup label={t("Pressure")}>
                        <option value={"bar"}>{t("bar")}</option>
                        <option value={"Pa"}>{t("Pascal (Pa)")}</option>
                        <option value={"psi"}>{t("Pounds per square inch (psi)")}</option>
                      </optgroup>

                      <optgroup label={t("Temperature")}>
                        <option value={"°C"}>{t("Celsius (°C)")}</option>
                        <option value={"°F"}>{t("Fahrenheit (°F)")}</option>
                        <option value={"K"}>{t("Kelvin (K)")}</option>
                      </optgroup>

                      <optgroup label={t("Energy/Power")}>
                        <option value={"A"}>{t("Current (Ampere)")}</option>
                        <option value={"V"}>{t("Voltage (Volt)")}</option>
                        <option value={"W"}>{t("Watt (W)")}</option>
                        <option value={"kW"}>{t("Kilowatt (kW)")}</option>
                        <option value={"Wh"}>{t("Watt-hour (Wh)")}</option>
                        <option value={"kWh"}>{t("Kilowatt-hour (kWh)")}</option>
                        <option value={"N·m"}>{t("Newton meter (N·m)")}</option>
                        <option value={"J"}>{t("Joule (J)")}</option>
                      </optgroup>

                      <optgroup label={t("Volume")}>
                        <option value={"l"}>{t("Liters (l)")}</option>
                        <option value={"ml"}>{t("Milliliters (ml)")}</option>
                        <option value={"gal"}>{t("Gallons (gal)")}</option>
                        <option value={"m³"}>{t("Cubic meter (m³)")}</option>
                        <option value={"cm³"}>{t("Cubic centimeter (cm³)")}</option>
                        <option value={"ft³"}>{t("Cubic feet (ft³)")}</option>
                        <option value={"m³/h"}>{t("Mass flow (m³/h)")}</option>
                        <option value={"kg/h"}>{t("Mass flow (kg/h)")}</option>
                      </optgroup>

                      <optgroup label={t("Distance/Length")}>
                        <option value={"mm"}>{t("Millimeter (mm)")}</option>
                        <option value={"cm"}>{t("Centimeter (cm)")}</option>
                        <option value={"m"}>{t("Meter (m)")}</option>
                        <option value={"km"}>{t("Kilometer (km)")}</option>
                        <option value={"mi"}>{t("Mile (mi)")}</option>
                        <option value={"in"}>{t("Inch (in)")}</option>
                        <option value={"ft"}>{t("Feet (ft)")}</option>
                      </optgroup>

                      <optgroup label={t("Time")}>
                        <option value={"s"}>{t("Seconds (s)")}</option>
                        <option value={"min"}>{t("Minutes (min)")}</option>
                        <option value={"h"}>{t("Hours (h)")}</option>
                        <option value={"d"}>{t("Days (d)")}</option>
                        <option value={"w"}>{t("Weeks (w)")}</option>
                        <option value={"mth"}>{t("Months (mth)")}</option>
                        <option value={"y"}>{t("Years (y)")}</option>
                      </optgroup>

                      <optgroup label={t("Velocity/Speed")}>
                        <option value={"m/s"}>{t("Meters per second (m/s)")}</option>
                        <option value={"km/h"}>{t("Kilometers per hour (km/h)")}</option>
                        <option value={"mi/h"}>{t("Miles per hour (mi/h)")}</option>
                        <option value={"r/min"}>{t("Revolutions per minute (r/min)")}</option>
                      </optgroup>

                      <optgroup label={t("Weight/Mass")}>
                        <option value={"g"}>{t("Grams (g)")}</option>
                        <option value={"kg"}>{t("Kilograms (kg)")}</option>
                        <option value={"lb"}>{t("Pounds (lb)")}</option>
                        <option value={"t"}>{t("Tons (t)")}</option>
                        <option value={"oz"}>{t("Ounces (oz)")}</option>
                      </optgroup>

                      <optgroup label={t("Other")}>
                        <option value={"state"}>{t("Custom state")}</option>
                      </optgroup>
                    </Form.Select>
                  </Form.Group>

                  {unit === "state" && <>
                    <Row className="mt-2">
                      <Col md="2" xs="2">
                      </Col>
                      <Col md="2" xs="2">
                        <Form.Label>Value</Form.Label>
                      </Col>
                      <Col md="5" xs="5">
                        <Form.Label>Description</Form.Label>
                      </Col>
                      <Col md="3" xs="3">
                        <Form.Label>Color</Form.Label>
                      </Col>
                    </Row>

                    {state_names?.sort((a, b) => a?.value > b?.value)?.map((item, index) => (
                      <Row key={index} className="mb-2">
                        <Col md="2" xs="2">
                          <Trash alt="Delete" className="mt-1 align-middle" size={18} style={{ cursor: "pointer" }} onClick={() => handleDelete(item)} />
                        </Col>
                        <Col md="2" xs="2">
                          <Form.Control
                            value={item?.value}
                            placeholder="Value"
                            type="number"
                            onChange={e => handleValueChange(index, 'value', parseInt(e.target.value))}
                          />
                        </Col>
                        <Col md="5" xs="5">
                          <Form.Control
                            value={item?.display_name}
                            placeholder="Description"
                            onChange={e => handleValueChange(index, 'display_name', e.target.value)}
                          />
                        </Col>
                        <Col md="3" xs="3">
                          <Form.Select
                            name="color"
                            onChange={e => handleValueChange(index, 'color_coding', e.target.value)}>
                            <option value="">{t("-")}</option>
                            <option value={"success"} selected={item?.color_coding === "success"}>{t("Green")}</option>
                            <option value={"warning"} selected={item?.color_coding === "warning"} >{t("Yellow")}</option>
                            <option value={"danger"} selected={item?.color_coding === "danger"}>{t("Red")}</option>
                            <option value={"primary"} selected={item?.color_coding === "primary"}>{t("Blue")}</option>
                            <option value={"secondary"} selected={item?.color_coding === "secondary"}>{t("Gray")}</option>
                          </Form.Select>
                        </Col>
                      </Row>
                    ))}
                    <Row>
                      <Col md="2" xs="2">
                      </Col>
                      <Col md="10" xs="10">
                        <Button onClick={handleAdd} size="sm" variant="outline-primary" className="mt-2 mb-5">
                          <Plus alt="Add" className="align-middle" size={18} style={{ cursor: "pointer" }} /> Add state
                        </Button>
                      </Col>
                    </Row>
                  </>}

                  <Form.Group className="mb-3">
                    <Form.Label>{t("Aggregation Type")}</Form.Label>
                    <Form.Select
                      name="state"
                      defaultValue={aggregation_type}
                      onChange={event => set_aggregation_type(event.target.value)}>
                      <option value="">{t("-- Select --")}</option>
                      <option value={"SUM"} >{t("Sum")}</option>
                      <option value={"AVG"} >{t("Average")}</option>
                      <option value={"COUNT"}>{t("Count")}</option>
                    </Form.Select>
                  </Form.Group>
                </Row>
              </div>}

              <Row>
                <Col>
                  <Accordion activeKey={showAdvanced ? "1" : activeKey} className="my-3">



                    <Accordion.Item eventKey="1" className="bg-white">
                      <Accordion.Header onClick={() => setActiveKey(activeKey === "1" ? null : "1")}>{t("Operating Rules & Advanced Settings")}</Accordion.Header>
                      <Accordion.Body className="py-2" style={{ padding: "40px" }}>
                        <br />
                        <Row className="d-flex align-items-end">
                          <Col md="4">
                            <Form.Group className="mb-3">
                              <Form.Label>{t("Lower Limit")}</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder={0}
                                defaultValue={lower}
                                onChange={e => setLower(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="4">
                            <Form.Group className="mb-3">
                              <Form.Label>{t("Target Value")}</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder={0}
                                defaultValue={target}
                                onChange={e => setTarget(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="4">
                            <Form.Group className="mb-3">
                              <Form.Label>{t("Upper Limit")}</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder={0}
                                defaultValue={upper}
                                onChange={e => setUpper(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        {!slim && <Row>
                          <Col>
                            <Form.Group className="mb-3">
                              <Form.Label>{t("Intent")}</Form.Label>
                              <Form.Select
                                onChange={(e) => set_iot_function(e.target.value)}
                                value={iot_function}
                                defaultValue={iot_function}>
                                <option value="">{t("-- Select --")}</option>
                                <FunctionsOptions />
                              </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Check
                                checked={ai_report_relevance}
                                defaultChecked={node_data?.ai_report_relevance}
                                onChange={() => set_ai_report_relevance(!ai_report_relevance)}
                                label={t("Relevant for AI-based anomaly report")}
                                type="checkbox" />
                            </Form.Group>

                            {/* {node_data?.type_number && <Form.Group className="mb-3">
                  <Form.Label>{t("Type Number")}</Form.Label>
                  <Form.Control
                    value={node_data?.type_number}
                    onChange={e => set_type_number(e.target.value)}
                    placeholder={t("Type Number")}
                  />
                </Form.Group>} */}



                            {/* <Form.Group className="mb-3">
                  <Form.Label>{t("Polling Method")}</Form.Label>
                  <Form.Select
                    name="state"
                    onChange={event => set_time_rhythm(event.target.value)}>
                    <option value="">{t("-- Select --")}</option>
                    <option value={"read"} selected={node_data?.read_interval}>{t("read")}</option>
                    <option value={"subscribe"} selected={node_data?.subscription_interval}>{t("subscribe")}</option>
                  </Form.Select>
                </Form.Group> */}
                          </Col>
                        </Row>}

                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              </Row>
            </>
            }


          </Form>
        </Row>
        <hr />
        <Row>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button variant="secondary" onClick={handleClose}>
              {t("Close")}
            </Button>
            <Button className="ms-1" variant="primary" onClick={(e) => handleSubmit(e)}>
              {isNew ? t("Hinzufügen") : t("Aktualisieren")}
            </Button>
          </Col>
        </Row>
      </Modal.Body >
    </Modal >
  );
};

export default SingleIoTDataPointModal;
