import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Accordion, Button, Col, Dropdown, Form, InputGroup, Modal, OverlayTrigger, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import { parseISO } from "date-fns";
import { Plus } from "react-feather";
import NotyfContext from "../../contexts/NotyfContext";
import AssetCategories from "../Helper/AssetCategories";
import UserDataManager from "../Helper/UserDataManager";
import { de } from "date-fns/locale";

const AddAssetModal = (props) => {
  const navigate = useNavigate();
  const notyf = useContext(NotyfContext);
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [openModal, setOpenModal] = useState(false);

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  const [title, setTitle] = useState();
  const [serial, setSerial] = useState();
  const [PPUPrice, setPPUPrice] = useState(0);
  const [description, setDescription] = useState();
  const [gatewayId, setGatewayId] = useState();
  const [co2_kg_per_wh, set_co2_kg_per_wh] = useState(0);
  const [duration, setDuration] = useState(60);
  const [monthlyPlannedUsage, setMonthlyPlannedUsage] = useState(0);
  // const [monthlyBaseFee, setMonthlyBaseFee] = useState(0);
  const [activationDate, setActivationDate] = useState();
  const [depreciationCategory, setDepreciationCategory] = useState();
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [maxOutputPerHour, setMaxOutputPerHour] = useState();
  const [dailyOperatingHours, setDailyOperatingHours] = useState();

  // Financials
  // const [financials_base_price, set_financials_base_price] = useState(0);
  const [financials_tier, set_financials_tier] = useState(0);
  const [financials_investment_price, set_financials_investment_price] = useState(0);
  const [financials_monthly_base_fee, set_financials_monthly_base_fee] = useState(0);
  // const [financials_market_value, set_financials_market_value] = useState(0);
  // const [financials_residual_book_value, set_financials_residual_book_value] = useState(0);
  // const [financials_capon_interest_rate, set_financials_capon_interest_rate] = useState(0);
  const [financials_capon_rate, set_financials_capon_rate] = useState(0);
  // const [financials_full_interest_rate, set_financials_full_interest_rate] = useState(0);
  // const [financials_refi_interest_rate, set_financials_refi_interest_rate] = useState(0);
  const [financials_refi_rate, set_financials_refi_rate] = useState(0);
  const [financials_starting_fee, set_financials_starting_fee] = useState("");
  const [financials_price_factors, set_financials_price_factors] = useState([
    {
      "starting_from": 0,
      "price": 0
    },
    {
      "starting_from": 0,
      "price": 0
    },
    {
      "starting_from": 0,
      "price": 0
    },
    {
      "starting_from": 0,
      "price": 0
    },
    {
      "starting_from": 0,
      "price": 0
    },
  ]);

  const [timeZones, setTimeZones] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

  useEffect(() => {
    // Generate a list of time zones using Intl API
    const zoneNames = new Set();
    const names = Intl.supportedValuesOf('timeZone');
    names.forEach(name => zoneNames.add(name));
    setTimeZones([...zoneNames]);
  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    setError({ showMessage: false, errorData: null })

    axios
      .post("/assets/", {
        "title": title,
        "serial": serial,
        "per_use_price": PPUPrice,
        // "monthly_base_fee": monthlyBaseFee,
        // "investment_price": assetPrice,
        "activation_date": activationDate,
        "description": description,
        "monthly_planned_usages": monthlyPlannedUsage,
        "duration_in_months": duration,
        "co2_kg_per_wh": co2_kg_per_wh,
        "daily_operating_hours": dailyOperatingHours,
        "max_output_per_hour": maxOutputPerHour,
        "iot_gateway_id": gatewayId,
        "category": depreciationCategory,
        "location": {
          "type": "Point",
          "coordinates": [
            latitude, longitude
          ]
        },
        "timezone": selectedTimeZone,
        "contract_id": props?.contract_id,
        "financials": (financials_tier === 1 || financials_tier === 2) ?
          {
            "tier": financials_tier,
            "price_factors": financials_price_factors,
            // "base_price": financials_base_price,
            "refi_rate": financials_refi_rate,
            "capon_rate": financials_capon_rate,
            "starting_fee": financials_starting_fee,
            // "full_interest_rate": financials_full_interest_rate,
            // "refi_interest_rate": financials_refi_interest_rate,
            // "capon_interest_rate": financials_capon_interest_rate,
            "investment_price": financials_investment_price,
            "monthly_base_fee": financials_monthly_base_fee,
            // "market_value": financials_market_value,
            // "residual_book_value": financials_residual_book_value
          } : null
      })
      .then(function (response) {
        // handle success
        navigate("/assets/" + response.data.id);
        console.log(response.status, "Load");

        notyf.open({
          type: "success",
          message: t("New item \"") + title + t("\" has been added."),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })

      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
      });
  }

  const [GatewayArray, setGatewayArray] = useState([]);

  useEffect(() => {
    axios
      .get("/iot_gateways/")
      .then(function (response) {
        // handle success
        console.log(response.status, "Load");
        setGatewayArray(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

  }, []);

  const loadGateways = () => {
    return GatewayArray.map((gateway) => {
      return (
        <option value={gateway.id}>{gateway.serial}</option>
      );
    });
  };

  var factors = [];
  function handleChangeP2Produced(event) {
    console.log(event.target.id, event.target.value);

    factors = [];
    factors = financials_price_factors;

    if (event.target.id === undefined || event.target.id === "") {
      factors[0]["starting_from"] = parseFloat(event.target.value);
    } else {
      factors[event.target.id]["starting_from"] = parseFloat(event.target.value);
    }

    set_financials_price_factors(factors)

    console.log(financials_price_factors);
  }

  function handleChangeP2Factor(event) {
    console.log(event.target.id, event.target.value);

    factors = [];
    factors = financials_price_factors;

    if (event.target.id === undefined || event.target.id === "") {
      factors[0]["price"] = parseFloat(event.target.value);
    } else {
      factors[event.target.id]["price"] = parseFloat(event.target.value);
    }

    set_financials_price_factors(factors)

    console.log(financials_price_factors);
  }

  const renderRows = () => {
    return financials_price_factors?.map((entry, index) => {
      return (
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3">
              <InputGroup className="mb-3">
                <Form.Control
                  onChange={handleChangeP2Produced}
                  id={index}
                  type="number"
                  defaultValue={entry.starting_from?.toLocaleString(i18n.language === "de" ? de : "en")}
                  placeholder="0 units" />
                <InputGroup.Text>Units</InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Col>

          {/* <Col md={1}></Col> */}
          <Col md={6}>
            <Form.Group className="mb-3">
              <InputGroup className="mb-3">
                <Form.Control
                  onChange={handleChangeP2Factor}
                  id={index}
                  type="number"
                  defaultValue={entry.price}
                  placeholder="0 €" />
                <InputGroup.Text>€</InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
      );
    });
  };

  return (
    <>
      <Button
        onClick={() => toggle()}
        variant="primary"
        className="shadow-sm me-1"
      >
        <Plus className="feather" /> {t("Create asset")}
      </Button>
      <Modal size="xl" show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t("Create asset")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Title")}</Form.Label>
                      <Form.Control required onChange={(e) => setTitle(e.target.value)} placeholder={t("Title")} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Model Number")}</Form.Label>
                      <Form.Control onChange={(e) => setSerial(e.target.value)} placeholder="ABC 123 DEF" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("cap-on Activation Date (start of billing cycle)")}</Form.Label>
                      <Form.Control
                        onChange={(e) => setActivationDate(parseISO(e.target.value))}
                        type="date"
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Description")}</Form.Label>
                  <Form.Control
                    onChange={(e) => setDescription(e.target.value)}
                    rows="9"
                    as="textarea"
                    placeholder={t("Description")} />
                </Form.Group>
              </Col>
            </Row>
            <br />
            <hr />
            <br />
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Maximum Output/h")}</Form.Label>
                  <Form.Control onChange={e => setMaxOutputPerHour(e.target.value)} placeholder="20/h" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Daily Operating Hours")}</Form.Label>
                  <Form.Control onChange={e => setDailyOperatingHours(e.target.value)} placeholder="22 (daily)" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("kg CO\u2082 per Wh")} ({t("Plan")})</Form.Label>
                  <Form.Select onChange={(e) => set_co2_kg_per_wh(e.target.value)}>
                    <option>{t("-- Select --")}</option>
                    <option value={0.000420}>{t("Strommix")}</option>
                    <option value={0.000025}>{t("Ökostrom")}</option>
                    <option value={0.001}>{t("Kohle")}</option>
                    <option value={0.0005}>{t("Gas")}</option>
                    <option value={0.00065}>{t("Öl")}</option>
                    <option value={0.00002}>{t("Kernkraft")} </option>
                    <option value={0.000024}>{t("Wasser")} </option>
                    <option value={0.000011}>{t("Wind")}</option>
                    <option value={0.000045}>{t("Solar")}</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("kg CO\u2082 per Wh")}</Form.Label>
                  <Form.Control value={co2_kg_per_wh ? co2_kg_per_wh : 0.42} onChange={(e) => set_co2_kg_per_wh(e.target.value)} placeholder="0.00042 kg CO\u2082" />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("kg CO\u2082 per kWh")}</Form.Label>
                  <Form.Control disabled value={(co2_kg_per_wh ? co2_kg_per_wh : 0.42) * 1000} placeholder="0.42 kg CO\u2082" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Category")}</Form.Label>
                  <Form.Select name="state" onChange={(e) => setDepreciationCategory(e.target.value)}>
                    <option>{t("-- Select --")}</option>
                    <AssetCategories />
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("cap-on IoT-Gateway")}</Form.Label>
                  <Form.Select name="state" onChange={(e) => setGatewayId(e.target.value)}>
                    <option>{t("-- Select --")}</option>
                    {loadGateways()}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <br />
            <hr />
            <br />
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Latitude")}</Form.Label>
                  <Form.Control defaultValue={latitude} onChange={(e) => setLatitude(e.target.value)} placeholder="20.3082" required />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Longitude")}</Form.Label>
                  <Form.Control defaultValue={longitude} onChange={(e) => setLongitude(e.target.value)} placeholder="51.2980" required />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Time zone")}</Form.Label>
                  <Form.Select onChange={event => setSelectedTimeZone(event.target.value)}>
                    <option>{t("-- Select --")}</option>
                    {timeZones.map(zone => (
                      <option key={zone} value={zone}>{zone}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <br />
            <br />
            <hr />
            <h5>{t("Planned Production")}</h5>
            <br />
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Duration in months")}</Form.Label>
                  <Form.Control onChange={(e) => setDuration(e.target.value)} placeholder={"60 " + t("months")} required />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Monthly Planned Production")}</Form.Label>
                  <Form.Control onChange={(e) => setMonthlyPlannedUsage(e.target.value)} placeholder={t("250 units/month")} />
                </Form.Group>
              </Col>
            </Row>
            <br />
            <br />
            <hr />
            <h5>{t("Financial Asset Settings")}</h5>
            <br />
            {
              UserDataManager.getInstance().isCapOn() &&
              <Accordion>
                <Accordion.Item eventKey="0" className="bg-white">
                  <Accordion.Header>{t("Financial Asset Settings")}</Accordion.Header>
                  <Accordion.Body className="bg-white">
                    <Row>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("financials_tier")}</Form.Label>
                          <Form.Select required onChange={(e) => set_financials_tier(e.target.value)}>
                            <option>{t("-- Select --")}</option>
                            {/* <option value={0}>{t("Tier 0")}</option> */}
                            <option value={1}>{t("Tier 1 - Pay-per-Use Leasing")}</option>
                            <option value={2}>{t("Tier 2 - Asset-as-a-Service Light")}</option>
                            <option value={3}>{t("Tier 3 - Asset-as-a-Service")}</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("financials_monthly_base_fee")}</Form.Label>
                          <Form.Control onChange={event => set_financials_monthly_base_fee(event.target.value)} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("financials_investment_price")}</Form.Label>
                          <Form.Control onChange={event => set_financials_investment_price(event.target.value)} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("financials_base_price")}</Form.Label>
                          <Form.Control
                            disabled
                          // onChange={event => set_financials_base_price(event.target.value)} 
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("Pay-per-Use Price (Tier 3 only)")}</Form.Label>
                          <Form.Control
                            onChange={(e) => setPPUPrice(e.target.value)}
                            // disabled={financials_tier === 3 ? true : false}
                            // required={financials_tier === 0}
                            placeholder="120.50 €" />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("financials_starting_fee")}</Form.Label>
                          <Form.Control onChange={event => set_financials_starting_fee(event.target.value)} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("financials_refi_rate")}</Form.Label>
                          <Form.Control onChange={event => set_financials_refi_rate(event.target.value)} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("financials_capon_rate")}</Form.Label>
                          <Form.Control onChange={event => set_financials_capon_rate(event.target.value)} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("financials_residual_book_value")}</Form.Label>
                          <Form.Control
                            disabled
                          // onChange={event => set_financials_residual_book_value(event.target.value)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("financials_full_interest_rate")}</Form.Label>
                          <Form.Control
                            disabled
                          // onChange={event => set_financials_full_interest_rate(event.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("financials_capon_interest_rate")}</Form.Label>
                          <Form.Control
                            disabled
                          // onChange={event => set_financials_capon_interest_rate(event.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("financials_refi_interest_rate")}</Form.Label>
                          <Form.Control
                            disabled
                          // onChange={event => set_financials_refi_interest_rate(event.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("financials_market_value")}</Form.Label>
                          <Form.Control
                            disabled
                          // onChange={event => set_financials_market_value(event.target.value)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Accordion>
                        <Accordion.Item eventKey="0" className="bg-white">
                          <Accordion.Header>{t("Pay-per-Use Price Factors")}</Accordion.Header>
                          <Accordion.Body>
                            <Form>
                              <Row>
                                <Col md={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>{t("Starting From Unit")}</Form.Label>
                                  </Form.Group>
                                </Col>
                                {/* <Col md={1}> </Col>*/}
                                <Col md={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>{t("Unit Price")}</Form.Label>
                                  </Form.Group>
                                </Col>
                              </Row>
                              {renderRows()}
                            </Form>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            }
            <br />
            <br />
            <hr />
            <br />
            <Row>
              <Col xs="auto" className="ms-auto text-end mt-n1">
                <Button variant="secondary" onClick={() => toggle(false)} >
                  {t("Cancel")}
                </Button>
                <Button variant={"primary"} type="submit">
                  {t("Create")}
                </Button>
              </Col>
            </Row>
          </Form >
        </Modal.Body >
      </Modal >
    </>
  );
};

export default AddAssetModal;
