import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Card,
  Col,
  Container,
  Row,
  Table,
  Badge,
  Breadcrumb,
  Spinner,
  Dropdown,
  OverlayTrigger,
  Popover,
  ProgressBar,
  Tab,
  Nav,
  Form,
  Modal,
} from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import {
  Wifi,
  MoreHorizontal,
  HelpCircle,
  Eye,
  Trash,
  Plus,
  Cloud,
  Activity,
  Info,
  Tool,
  File,
  Feather,
  DollarSign,
  Compass,
  ArrowUpRight,
  BellOff,
  Bell,
  Cast,
  Download,
  Clipboard,
  AlertTriangle,
  RefreshCw,
  Edit,
  UserCheck,
  ShoppingCart,
  Layers
} from "react-feather";
import { Button } from "react-bootstrap";
import usePalette from "../../hooks/usePalette";
import { format, formatDistanceToNow, formatDuration, intervalToDuration, parseISO, } from 'date-fns'
import { useTranslation } from "react-i18next";
import FileTable from "../FileManagement/FileTable";
import { Line } from "react-chartjs-2";
import { de } from 'date-fns/locale';
import ErrorHandler from "../Helper/ErrorHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import NotyfContext from "../../contexts/NotyfContext";
import UploadFileModal from "../Helper/UploadFileModal";
import AddOEERejectModal from "./AddOEERejectModal";
import { isMobile } from "react-device-detect";
import UserDataManager from "../Helper/UserDataManager";
import EditAssetModal from "./AssetModal_Edit";
import AssetMoveToContractModal from "./AssetMoveToContractModal";

import SingleWatchTowerModal from "../Watchtower/SingleWatchTowerModal";
import AddAssetUsageModal from "./AddAssetUsageModal";
import PowerConsumptionChart from "./Charts/PowerConsumptionChart";
import AssetGeneralSection from "./AssetDetailsSections/AssetGeneralSection";
import DigitalTwinSection from "./AssetDetailsSections/DigitalTwinSection";
import InvoiceTable from "../Invoices/InvoiceTable";
import AnomalyReportModal from "./AI Report/AnomalyReportModal";
import AssetUsageDetails from "./AssetUsageDetails";
import AssetServices from "./AssetServices";
import CreateAIReportModal from "./AI Report/CreateAIReportModal";
import UploadAvatarModal from "../Helper/UploadAvatarModal";
import RemoveAvatarButton from "../Helper/RemoveAvatarButton";
// import RFB from '@novnc/novnc/core/rfb';
import ESGSection from "./AssetDetailsSections/ESGSection";
import EditReportModal from "./AI Report/EditReportModal";
import MaintenanceSection from "./AssetDetailsSections/MaintenanceSection";
import { startOfYear } from "date-fns";
import CloudConnectivitySection from "./AssetDetailsSections/CloudConnectivitySection";
import ProductivitySection from "./AssetDetailsSections/ProductivitySection";
import MetricsSection from "./Metrics/MetricsSection";
import WatchTowerList from "../Watchtower/WatchTowerList";

const AssetDetails = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [assetData, setAssetData] = useState(null);
  // const [location, setLocation] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const notyf = useContext(NotyfContext);
  const [LocalWeather, setLocalWeather] = useState([]);
  const [showAnomalyReportModal, setShowAnomalyReportModal] = useState(false);
  const [selectedAnomalyReport, setSelectedAnomalyReport] = useState();
  const [PowerUsageToday, setPowerUsageToday] = useState([]);
  const [assetMonitoringData, setAssetMonitoringData] = useState([]);
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [filesData, setFilesData] = useState([]);
  const [coordinates, setCoordinates] = useState();
  const [showWatchTowerModal, setShowWatchTowerModal] = useState();
  const [selectedWatchTower, setSelectedWatchTower] = useState();
  const [analysisContentLoaded, setAnalysisContentLoaded] = useState(false)
  const [productionContentLoaded, setProductionContentLoaded] = useState(false)
  const [invoicingContentLoaded, setInvoicingContentLoaded] = useState(false)
  const [metricsContentLoaded, setMetricsContentLoaded] = useState(false)
  const [remoteHMIContentLoaded, setRemoteHMIContentLoaded] = useState(false)
  const [digitalTwinContentLoaded, setDigitalTwinContentLoaded] = useState(false);
  const [maintenanceContentLoaded, setMaintenanceContentLoaded] = useState(false);
  const [clodConnectivityContentLoaded, setClodConnectivityContentLoaded] = useState(false)
  const [ESGContentLoaded, setESGContentLoaded] = useState(false);
  const [showServicesModal, setShowServicesModal] = useState(false);
  const palette = usePalette();

  useEffect(() => {

    setIsLoading(true)
    setError({ showMessage: false, errorData: null })

    loadAssetDetails()

    // Important for initial load!
    switch (getTabFromUrl()) {
      case "digital-twin":
        setDigitalTwinContentLoaded(true)
        break;
      case "esg":
        setESGContentLoaded(true)
        break;
      case "invoicing":
        setInvoicingContentLoaded(true)
        break;
      case "KPIs":
        setMetricsContentLoaded(true)
        break;
      case "remote-hmi":
        setRemoteHMIContentLoaded(true)
        break;
      case "analysis":
        setAnalysisContentLoaded(true)
        break;
      case "production":
        setProductionContentLoaded(true)
        break;
      case "maintenance":
        setMaintenanceContentLoaded(true)
        break;
      case "connectivity":
        setClodConnectivityContentLoaded(true)
        break;
      default:
        setDigitalTwinContentLoaded(false)
        setESGContentLoaded(false)
        setInvoicingContentLoaded(false)
        setRemoteHMIContentLoaded(false)
        setAnalysisContentLoaded(false)
        setMetricsContentLoaded(false)
        setProductionContentLoaded(false)
        setMaintenanceContentLoaded(false)
        setClodConnectivityContentLoaded(false)
        break;
    }
  }, []);



  useEffect(() => {

    if (invoicingContentLoaded) {
      axios
        .get("/invoices/", {
          params: {
            "contract_id": assetData?.contract_id
          }
        })
        .then(function (response) {
          // handle success
          console.log(response.status, "/invoices");
          setInvoices(response.data.sort((a, b) => (a.interval_start > b.interval_start)))
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }

  }, [invoicingContentLoaded]);

  useEffect(() => {

    if (coordinates && digitalTwinContentLoaded) {
      const fetchWeather = async () => {
        try {
          const response = await axios.get(`https://api.weatherapi.com/v1/current.json?q=${coordinates?.latitude},${coordinates?.longitude}&key=d4439763810d40709ff104730231812&lang=${i18n.language}`);
          console.log(response.status, "/local_weather");
          setLocalWeather(response.data);
        } catch (error) {
          console.error('Failed to fetch data:', error);
        }
      };

      fetchWeather();
    }

  }, [coordinates, digitalTwinContentLoaded]);

  useEffect(() => {

    if (analysisContentLoaded) {

      getReports()
    }

  }, [analysisContentLoaded]);

  function getReports() {

    let startDate = startOfYear(new Date()).toISOString()
    axios
      .get("/webservice/assets/" + id + "/monitoring")
      .then(function (response) {
        // handle success
        console.log(response.status, "/monitoring", startDate);
        setAssetMonitoringData(response.data)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  // Extract tab key from URL query parameters
  const getTabFromUrl = () => {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get('focus') || 'general'; // Default to 'general' if no tab specified
  };

  // Handle tab selection
  const handleSelect = (key) => {
    // Update URL without reloading the page
    navigate(`?focus=${key}`);

    setDigitalTwinContentLoaded(false)
    setESGContentLoaded(false)
    setInvoicingContentLoaded(false)
    setRemoteHMIContentLoaded(false)
    setAnalysisContentLoaded(false)
    setProductionContentLoaded(false)
    setMaintenanceContentLoaded(false)
    setClodConnectivityContentLoaded(false)
    setMetricsContentLoaded(false)

    if (key === "digital-twin") {
      setDigitalTwinContentLoaded(true)
    } else if (key === "esg") {
      setESGContentLoaded(true)
    } else if (key === "invoicing") {
      setInvoicingContentLoaded(true)
    } else if (key === "remote-hmi") {
      setRemoteHMIContentLoaded(true)
    } else if (key === "KPIs") {
      setMetricsContentLoaded(true)
    } else if (key === "analysis") {
      setAnalysisContentLoaded(true)
    } else if (key === "production") {
      setProductionContentLoaded(true)
      // setProductionExtendedContentLoaded(true)
    } else if (key === "maintenance") {
      setMaintenanceContentLoaded(true)
    } else if (key === "connectivity") {
      setClodConnectivityContentLoaded(true)
    }
  };

  function loadAssetDetails() {
    axios
      .get("/webservice/assets/" + id + "/base")
      .then(function (response) {

        // handle success
        console.log(response.status, "/asset");

        setAssetData(response.data);
        setCoordinates({
          "latitude": response.data?.location?.coordinates[0],
          "longitude": response.data?.location?.coordinates[1]
        })
        setPowerUsageToday(response.data.power_usage_current_day);
        setFilesData(response.data.files);

        setIsLoading(false)
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        setIsLoading(false)
        console.log(error);
      });
  }

  function handleDelete(e) {
    e.preventDefault();
    axios
      .delete("/assets/" + id)
      .then(function (response) {
        console.log(response.status, "Deleted asset");
        // handle success
        navigate(-1);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setError({ showMessage: true, errorData: error })
      });
  }

  function handleDeleteWatchtower(e, id) {
    e.preventDefault();

    axios
      .delete("/watchtower/" + id)
      .then(function (response) {
        console.log(response.status, "Deleted watchtower");
        // handle success

        notyf.open({
          type: "success",
          message: t("Removed watchtower"),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })

        loadAssetDetails()
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  // function handleDeleteWatchtower(e, watchtower_id) {
  //   e.preventDefault();

  //   axios
  //     .delete("/watchtower/" + watchtower_id)
  //     .then(function (response) {
  //       console.log(response.status, "Deleted watchtower");
  //       // handle success
  //       refreshPage();
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       console.log(error);
  //     });
  // }

  function handleDeleteReport(e, report_id) {
    e.preventDefault();

    axios
      .delete(`/assets/${id}/anomaly_reports/${report_id}`)
      .then(function (response) {
        console.log(response.status, "Deleted report");
        // handle success
        getReports()
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  const HealthBadge = (healthy) => {

    switch (healthy) {

      case true:
        return (<h1 className="mb-1">
          <Badge bg="" className="badge-soft-success me-2">{t("Healthy")}</Badge>
        </h1>)

      case false:
        return (<h1 className="mb-1">
          <Badge bg="" className="badge-soft-secondary me-2">{t("Inactive")}</Badge>
        </h1>)

      default:
        return (<h1 className="mb-1">
          <Badge bg="" className="badge-soft-secondary me-2">{t("Checking")}</Badge>
        </h1>)
    }
  }

  const ReportStatusBadge = (status) => {

    // "manual" "in_progress" "completed" "error"

    switch (status) {

      case "completed":
        return <Badge bg="" className="badge-soft-success me-2">{t(status)}</Badge>

      case "in_progress":
        return <Badge bg="" className="badge-soft-secondary me-2">{t(status)}</Badge>

      case "manual":
        return <Badge bg="" className="badge-soft-success me-2">{t(status)}</Badge>

      case "error":
        return <Badge bg="" className="badge-soft-danger me-2">{t(status)}</Badge>

      default:
        return <Badge bg="" className="badge-soft-secondary me-2">{t(status)}</Badge>
    }
  }



  // function getOperatingTimesPercent(stateString) {

  //   let secondsForState = state_periods?.find(element => element.state_name === stateString)?.current_month_seconds
  //   return secondsForState === undefined ? "-" : (secondsForState / state_periods.reduce((a, v) => a = a + v.current_month_seconds, 0))?.toLocaleString(i18n.language === "de" ? de : "en", {
  //     style: 'percent',
  //     maximumFractionDigits: 1,
  //   })
  // }

  // const ShowLoadingSpinner = () => {
  //   return (
  //     <div className="text-center">
  //       <br />
  //       <Spinner animation="border" variant="secondary" />
  //       <br />
  //       <br />
  //       <br />
  //     </div>
  //   )
  // }

  const ShowLoadingSpinner = () => {
    return (
      <div>
        <Spinner animation="border" variant="secondary" size="sm" /> {t("Loading data")}
      </div>
    )
  }

  // function getConnectionStatus(item) {
  //   switch (item.connection_status) {
  //     case "connected":
  //       return <Badge bg="" className="badge-soft-success me-2">{t("connected")}</Badge>
  //     case "no_connection":
  //       return <Badge bg="" className="badge-soft-secondary me-2">{t("no_connection")}</Badge>
  //     case "disconnected":
  //       return <Badge bg="" className="badge-soft-danger me-2">{t("disconnected")}</Badge>
  //     default:
  //       return <span>-</span>
  //   }
  // }

  // function getRecentProductionStatus(item) {
  //   switch (item?.iot_gateway?.recent_production) {
  //     case true:
  //       return <Badge bg="" className="badge-soft-success me-2">{t("Used recently")}</Badge>
  //     case false:
  //       return <Badge bg="" className="badge-soft-warning me-2">{t("Not used recently")}</Badge>
  //     default:
  //       return <span>-</span>
  //   }
  // }

  function updateAlertUser(watchtowerItem) {

    var isBeingNotified = watchtowerItem?.users_to_alert?.find(user => user.user_id === UserDataManager.getInstance().getUserData().id);

    if (isBeingNotified) {
      // Add user from watchtower
      axios
        .delete("/watchtower/" + watchtowerItem?.id + "/self")
        .then(function (response) {
          // handle success
          console.log(response.status, "Edited successfully");

          notyf.open({
            type: "success",
            message: t("You will not be notified any longer."),
            duration: "4500",
            ripple: false,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          })
          loadAssetDetails()

        })
        .catch(function (error) {
          // handle error
          setError({ showMessage: true, errorData: error })
          console.log(error);
        });
    } else {

      // Remove user from watchtower
      axios
        .put("/watchtower/" + watchtowerItem?.id + "/self")
        .then(function (response) {
          // handle success
          console.log(response.status, "Deleted successfully");

          notyf.open({
            type: "success",
            message: t("You will be notified."),
            duration: "4500",
            ripple: false,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          })
          loadAssetDetails()

        })
        .catch(function (error) {
          // handle error
          setError({ showMessage: true, errorData: error })
          console.log(error);
        });
    }

  }
  const UserNotificationSetting = ({ item }) => {

    var isBeingNotified = item?.users_to_alert?.find(user => user.user_id === UserDataManager.getInstance().getUserData().id);

    return (
      isBeingNotified
        ? <BellOff alt="Delete" className="align-middle" size={18} style={{ cursor: "pointer" }} onClick={e => {
          e.stopPropagation();
          updateAlertUser(item)
        }} />
        : <Bell alt="Delete" className="align-middle" size={18} style={{ cursor: "pointer" }} onClick={e => {
          e.stopPropagation();
          updateAlertUser(item)
        }} />
    )
  }

  const renderAnomalyReportsTable = () => {
    return assetMonitoringData?.anomaly_reports?.sort((a, b) => a?.created_at < b?.created_at)?.map((item) => {
      return (
        <tr key={item.id}
          onClick={() => {
            setSelectedAnomalyReport(item?.id)
            setShowAnomalyReportModal(true)
          }} style={{ cursor: "pointer" }}
        >
          <td>{ReportStatusBadge(item?.status)}</td>
          <td>{formatDistanceToNow(parseISO(item?.created_at), { locale: i18n.language === "de" ? de : null, addSuffix: true })}</td>
          <td>{format(parseISO(item.start_date), "dd MMM yyyy", { locale: i18n.language === "de" ? de : null })} - {format(parseISO(item.end_date), "dd MMM yyyy", { locale: i18n.language === "de" ? de : null })}</td>
          {/* 
          <td >{t(item?.comparison_mode) || "-"}</td>
          <td>{item?.last_trigger_at === null ? "-" : formatDistanceToNow(parseISO(item?.last_trigger_at), { locale: i18n.language === "de" ? de : null, addSuffix: true })}</td>
          <td >{t(item?.last_trigger_count || "-")}</td>

          <td >
            {ShowUserAvatars(item)}
          </td> */}
          {UserDataManager.getInstance().isAdmin() && <td className="table-action">
            <Edit alt="Edit" className="align-middle" size={18} style={{ cursor: "pointer" }} onClick={e => {
              e.stopPropagation(); // This will prevent the click from bubbling up to the <tr> element
              setSelectedAnomalyReport(item?.id)
              setShowEditAIModal(true)
            }
            } />

            <Trash alt="Delete" className="align-middle ms-2" size={18} style={{ cursor: "pointer" }} onClick={e => {
              e.stopPropagation(); // This will prevent the click from bubbling up to the <tr> element
              window.confirm(t("Are you sure you wish to delete this report?")) && handleDeleteReport(e, item?.id)
            }
            } />
          </td>}
        </tr>
      );
    });
  };

  const ShowUserAvatars = (item) => {

    return item?.users_to_alert?.map((user) => {

      if (user.avatar_url !== null) {
        return (
          <img
            src={user == null ? null : user.avatar_url}
            alt="avatar"
            key={user?.avatar_url}
            className="me-3 text-center rounded-circle"
            width={32} height={32}
          />
        );
      }
      return (<></>)
    });
  }


  const InvoicesSection = () => {
    return (<>
      <div className="h3 me-4 mt-3 mb-4">{t("AaaS-Billing")}</div>
      <InvoiceTable data={invoices} title={t("Invoices")} isLoading={isLoading} showFullTable />
    </>)
  }


  const AssetFinancialSection = () => {
    return (<>
      <div className="h3 me-4 mt-3 mb-4">{t("Financials")}</div>
    </>)
  }

  const [showCreateAIModal, setShowCreateAIModal] = useState(false);
  const [showEditAIModal, setShowEditAIModal] = useState();

  const AnomalyReportsSection = () => {
    return (
      <div style={{ background: palette["gray-200"], padding: "20px" }}>
        <Row className="mt-3 mb-4">
          <Col>
            <div className="h3">{t("AI-based Monitoring")}</div>
          </Col>
          {/* <Col xs="auto" className="ms-auto text-end mt-n1">
        <Button
          onClick={() => navigate("/assets/" + assetData?.id + "/insights")}
          variant="outline-primary"
          size="sm"
          className="shadow-sm me-1">
          <Eye className="feather" /> {!isMobile && t("Insights")}
        </Button>
      </Col> */}
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Col xs="auto" className="d-none d-sm-block">
                  <Card.Title>
                    {t("Anomaly Reports")}
                  </Card.Title>
                </Col>
                <Col xs="auto" className="ms-auto text-end mt-n3">
                  {UserDataManager.getInstance().isAdmin() && <Button
                    onClick={() => setShowCreateAIModal(true)}
                    variant="outline-primary"
                    size="sm"
                    className="shadow-sm me-1">
                    <Plus className="feather" /> {t("Generate AI-Report")}
                  </Button>}

                  <Button
                    onClick={() => getReports()}
                    variant="outline-primary"
                    size="sm"
                    className="shadow-sm">
                    <RefreshCw className="feather" />
                  </Button>
                </Col>
              </Card.Header>

              {assetMonitoringData?.anomaly_reports?.length > 0
                ? <Table responsive hover>
                  <thead>
                    <tr>
                      <th scope="col">{t("Status")}</th>
                      <th scope="col">{t("Created")}</th>
                      <th scope="col">{t("Period")}</th>
                      <th scope="col">{t("Quick Actions")}</th>
                    </tr>
                  </thead>
                  <tbody>{renderAnomalyReportsTable()}</tbody>
                </Table>
                : UserDataManager.getInstance().isAdmin()
                  ? <Card.Body>
                    <p className="card-subtitle text-muted">
                      {t("No data found.")}
                    </p>
                  </Card.Body>
                  : <Card.Body>
                    <p className="card-subtitle text-muted">
                      {t("This feature needs to be unlocked. Please get in touch with the cap-on team.")}
                    </p>
                  </Card.Body>}
            </Card>
          </Col>
        </Row>

        <WatchTowerList slim watchtowers={assetMonitoringData?.watchtower_entries} selected_asset_id={assetData?.id} />

      </div>
    )
  }

  const AssetEnergyConsumption = () => {
    return (
      <Col md="12" xs="6" className="d-flex">
        <Card className="flex-fill">
          <Card.Body>
            <Row>
              <Col className="mb-2">
                <h5 className="card-title">{t("Power Consumption")} • {t("Today")}</h5>
              </Col>
              <Col xs="auto" className="ms-auto text-end">
                <OverlayTrigger
                  placement='bottom'
                  overlay={

                    PowerUsageToday !== 0 ?
                      <Popover>
                        <Popover.Header as='h3'>CO<sub>2</sub> {t("Emissions")}</Popover.Header>
                        <Popover.Body>
                          Die folgenden Stromverbrauchsdaten beziehen sich auf die <strong>heutige</strong> Produktion.
                          <br /> <br />

                          {t("Power Consumption")}: <strong>{(PowerUsageToday / 1000)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0 }) + " kWh"}</strong><br />
                          {t("Power Consumption")}/{t("unit")}: <strong>{(PowerUsageToday / assetData?.usage_section?.usages_today / 1000)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 }) + " kWh/" + t("unit")}</strong>

                          <br /><br />

                          CO<sub>2</sub> {t("Emissions")}: <strong className="h5">{(PowerUsageToday * assetData?.co2_kg_per_wh)?.toLocaleString(i18n.language === "de" ? de : "en", {
                            style: 'decimal',
                            maximumFractionDigits: 2,
                          })} kg CO<sub>2</sub>e</strong><br />
                          CO<sub>2</sub> {t("Emissions")}/{t("unit")}: <strong className="h5">{(PowerUsageToday * assetData?.co2_kg_per_wh / assetData?.usage_section?.usages_today)?.toLocaleString(i18n.language === "de" ? de : "en", {
                            style: 'decimal',
                            maximumFractionDigits: 2,
                          })} kg CO<sub>2</sub>e</strong>

                          <br /><br />

                          {/* Bei der Herstellung des <strong>heutigen</strong> Stroms wurden <strong className="h5">{(PowerUsageToday * assetData?.co2_kg_per_wh)?.toLocaleString(i18n.language === "de" ? de : "en", {
                                style: 'decimal',
                                maximumFractionDigits: 2,
                              })} kg CO<sub>2</sub>e</strong> freigesetzt.<br /><br /> */}
                          • 1 kWh entspricht {assetData?.co2_kg_per_wh * 1000} kg CO<sub>2</sub>e<br />
                          {/* {assetData?.co2_compensation === false ? t("Not compensated") : t("Compensated")}<br /> */}
                          • 1 kWh Strom kostet für Industriekunden ca. 0.25 EUR
                        </Popover.Body>
                      </Popover>
                      :
                      <Popover>
                        <Popover.Header as='h3'>CO<sub>2</sub> {t("Emissions")}</Popover.Header>
                        <Popover.Body>
                          CO<sub>2</sub> {t("Emissions")}/{t("unit")}: <strong>{assetData?.co2_per_use} g</strong> <br />
                          {t("Today")}: <strong>{(assetData?.co2_per_use * assetData?.usage_section?.usages_today).toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0 }) + " g"}</strong><br /><br />
                          {assetData?.co2_compensation === false ? t("Not compensated") : t("Compensated")}<br />
                        </Popover.Body>
                      </Popover>

                  }
                >
                  <HelpCircle className="feather" />
                </OverlayTrigger>
              </Col>
            </Row>
            {
              PowerUsageToday !== 0 ?
                <span className="h1 d-inline-block mt-1 mb-4">{(PowerUsageToday / 1000)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0 })} kWh</span>
                :
                <span className="h1 d-inline-block mt-1 mb-4">{((assetData?.co2_per_use * assetData?.usage_section?.usages_today) / 1000).toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 }) + " kg"}</span>
            }
            <div className="mb-0">
              {/* <Badge bg="" className="badge-soft-success me-2">{badgeValue}</Badge> */}
              <span className="text-muted"><strong>{(PowerUsageToday * assetData?.co2_kg_per_wh)?.toLocaleString(i18n.language === "de" ? de : "en", {
                style: 'decimal',
                maximumFractionDigits: 2,
              })}</strong> kg CO<sub>2</sub>e Emissionen | ca. <strong>{(PowerUsageToday / 1000 * 0.25)?.toLocaleString(i18n.language === "de" ? de : "en", {
                style: 'currency',
                currency: "EUR",
                maximumFractionDigits: 2,
              })}</strong> Stromkosten</span>
            </div>
          </Card.Body>
        </Card>
      </Col>
    )
  }


  return (
    <React.Fragment>

      {showWatchTowerModal && <SingleWatchTowerModal
        desired_watchtower_id={selectedWatchTower?.id}
        selected_asset_id={assetData?.id}
        handleClose={() => {
          setShowWatchTowerModal(null)
          setSelectedWatchTower(null)
          loadAssetDetails()
        }} />}

      <CreateAIReportModal asset_id={id} show={showCreateAIModal} handleClose={() => setShowCreateAIModal(false)} />
      <EditReportModal report_id={selectedAnomalyReport} asset_id={id} show={showEditAIModal} handleClose={() => {
        setShowEditAIModal(false)
        setSelectedAnomalyReport(null)
      }} />

      <AnomalyReportModal report_id={selectedAnomalyReport} show={showAnomalyReportModal} asset_id={id} handleClose={() => {
        setShowAnomalyReportModal(null)
        setSelectedAnomalyReport(null)
      }} />
      <AssetServices show={showServicesModal} handleClose={() => setShowServicesModal(false)} />

      <Helmet title={assetData?.title} />
      <Container fluid className="p-0">
        <Breadcrumb>
          {/* <div className="vertical-center-all"> */}
          <Button variant="outline-primary" size="sm" className="me-2" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faArrowLeft} /> {t("Back")}
          </Button>
          <Breadcrumb.Item href="/assets">{t("Assets")}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t("Asset 360° View")}</Breadcrumb.Item>
          {/* </div> */}
        </Breadcrumb>
        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            {isLoading ? ShowLoadingSpinner() : <h3>{assetData?.title}</h3>}
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            {UserDataManager.getInstance().isTenantAdmin() && localStorage.getItem("tenant_id") === "tWMPWtl" && <Button
              onClick={() => setShowServicesModal(true)}
              // onClick={() => reloadDigitalTwin()}
              variant="outline-primary"
              size="sm"
              className="shadow-sm me-1">
              <ShoppingCart className="feather me-1" /> {!isMobile && t("MAAG Services")}
            </Button>}

            {/* {UserDataManager.getInstance().isAdmin() &&
              <Button
                onClick={() => setShowServicesModal(true)}
                // onClick={() => reloadDigitalTwin()}
                variant="outline-primary"
                size="sm"
                className="shadow-sm me-1">
                <ShoppingCart className="feather me-1" /> {!isMobile && t("Services")}
              </Button>} */}

            <Dropdown align={{ lg: 'end' }} className="d-inline">
              <Dropdown.Toggle variant="light" className="bg-white shadow-sm">
                <MoreHorizontal className="feather align-middle mt-n1" /> {t("More")}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <EditAssetModal />
                <AssetMoveToContractModal />
                <Dropdown.Item onClick={e => window.confirm(t("Are you sure you wish to delete this asset?")) && handleDelete(e)}>{t("Remove asset")}</Dropdown.Item>
                <Dropdown.Divider />
                {UserDataManager.getInstance().isAdmin() && <Dropdown.Item onClick={() => navigate("/assets/" + id + "/setup")}>{t("IoT data setup")}</Dropdown.Item>}
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => navigate("/contracts/" + assetData?.contract_id)}>{t("View Contract")}</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => navigate("/assets/" + id + "/usage/logs")}>{t("Usage Logs")}</Dropdown.Item>
                <Dropdown.Item onClick={() => navigate("/assets/" + id + "/edit/planned_production")}>{t("Edit planned production")}</Dropdown.Item>
                <AddAssetUsageModal />
                <Dropdown.Item onClick={() => navigate("/assets/" + id + "/pcf")}>{t("Calculate PCF")}</Dropdown.Item>
                {/* <Dropdown.Item onClick={() => navigate("/assets/" + id + "/addOEE")}>{t("Add OEE data")}</Dropdown.Item> */}
                <AddOEERejectModal />
                <Dropdown.Divider />
                <UploadAvatarModal type="asset" />
                {assetData?.avatar && <RemoveAvatarButton type="asset" />}
                <Dropdown.Divider />
                <UploadFileModal buttonType="" type="asset" />
                {/* <Dropdown.Item >{t("Add file")}</Dropdown.Item> */}
                <Dropdown.Divider />
                <Dropdown.Item onClick={refreshPage}>{t("Refresh")}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>

        {!isLoading && <Row>
          <Col lg="12">
            <div className={"tab Default"}>
              <Tab.Container id="left-tabs-example" defaultActiveKey={getTabFromUrl()} onSelect={handleSelect}>
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="general">
                      <Info className="feather me-2" />
                      {isMobile ? "" : t("General")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="production">
                      <Activity className="feather me-2" />
                      {isMobile ? "" : t("Production")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="digital-twin">
                      <Wifi className="feather me-2" />
                      {isMobile ? "" : t("Live-View")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="KPIs">
                      <Layers className="feather me-2" />
                      {isMobile ? "" : t("Data Insights")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="analysis">
                      <Tool className="feather me-2" />
                      {isMobile ? "" : t("AI-based Monitoring")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="esg">
                      <Feather className="feather me-2" />
                      {isMobile ? "" : t("ESG")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="maintenance">
                      <UserCheck className="feather me-2" />
                      {isMobile ? "" : t("Maintenance Cockpit")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="connectivity">
                      <Cloud className="feather me-2" />
                      {isMobile ? "" : t("Cloud-Connectivity")}
                    </Nav.Link>
                  </Nav.Item>

                  {assetData?.usage_section && <Nav.Item>
                    <Nav.Link eventKey="invoicing">
                      <DollarSign className="feather me-2" />
                      {isMobile ? "" : t("AaaS-Billing")}
                    </Nav.Link>
                  </Nav.Item>}

                  {assetData?.financial_section && <Nav.Item>
                    <Nav.Link eventKey="financial">
                      <DollarSign className="feather me-2" />
                      {isMobile ? "" : t("Financials")}
                    </Nav.Link>
                  </Nav.Item>}

                  <Nav.Item>
                    <Nav.Link eventKey="files">
                      <File className="feather me-2" />
                      {isMobile ? "" : t("Documents")}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content style={{padding:"4px", margin:"0px" }}>

                  <Tab.Pane eventKey="general">
                    <AssetGeneralSection assetData={assetData} loadData={() => loadAssetDetails()} />
                  </Tab.Pane>

                  <Tab.Pane eventKey="production" >
                    {productionContentLoaded && <ProductivitySection assetData={assetData} productionContentLoaded={productionContentLoaded} />}
                  </Tab.Pane>

                  <Tab.Pane eventKey="KPIs">
                    {metricsContentLoaded && <MetricsSection assetData={assetData} loaded={metricsContentLoaded} />}
                  </Tab.Pane>

                  <Tab.Pane eventKey="analysis">
                    {analysisContentLoaded && <AnomalyReportsSection />}
                  </Tab.Pane>

                  <Tab.Pane eventKey="invoicing">
                    {invoicingContentLoaded && <InvoicesSection />}
                  </Tab.Pane>

                  <Tab.Pane eventKey="digital-twin">
                    {digitalTwinContentLoaded && <DigitalTwinSection assetData={assetData} localWeather={LocalWeather} />}
                  </Tab.Pane>

                  <Tab.Pane eventKey="financials">
                    {assetData?.financial_section && <AssetFinancialSection />}
                  </Tab.Pane>

                  <Tab.Pane eventKey="esg">
                    {ESGContentLoaded && <ESGSection assetData={assetData} ESGContentLoaded={ESGContentLoaded} />}
                  </Tab.Pane>

                  <Tab.Pane eventKey="maintenance">
                    {maintenanceContentLoaded && <MaintenanceSection assetData={assetData} maintenanceContentLoaded={maintenanceContentLoaded} />}
                  </Tab.Pane>

                  <Tab.Pane eventKey="connectivity">
                    {clodConnectivityContentLoaded && <CloudConnectivitySection assetData={assetData} clodConnectivityContentLoaded={clodConnectivityContentLoaded} />}

                    {/* <GatewaySection /> */}
                  </Tab.Pane>

                  <Tab.Pane eventKey="files">
                    <div style={{ background: palette["gray-200"], padding: "20px" }}>
                      <FileTable data={filesData} title="Documents" parent_id={assetData?.id} parent_type="/assets/" />
                    </div>
                  </Tab.Pane>

                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>}
      </Container>
    </React.Fragment >
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default AssetDetails;
