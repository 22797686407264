import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Card,
  Col,
  OverlayTrigger,
  Popover,
  Row,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Eye, Info } from "react-feather";
import { useTranslation } from "react-i18next";
import { de } from 'date-fns/locale';
import { format, parseISO } from "date-fns";
import axios from "axios";
import LoadingSpinner from "../../Helper/LoadingSpinner";
import usePalette from "../../../hooks/usePalette";
import { Sparklines, SparklinesLine } from 'react-sparklines';
import SingleWatchTowerModal from "../../Watchtower/SingleWatchTowerModal";
import { useParams } from "react-router-dom";
import MetricDetailsChartModal from "../Metrics/MetricDetailsChartModal";

const MetricsSection = ({ assetData, loaded }) => {
  const { t, i18n } = useTranslation();
  const [metricsData, setMetricsData] = useState([]);
  const [filteredMetricsList, setFilteredMetricsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMetricData, setSelectedMetricData] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [PeriodType, setPeriodType] = useState("day");
  const palette = usePalette();
  const [selectAddWatchtowerModal, setSelectAddWatchtowerModal] = useState(null);
  let { id } = useParams();

  useEffect(() => {
    if (loaded) {
      setIsLoading(true);
      axios
        .get("/assets/" + assetData?.id + "/latest_metrics", {
          params: {
            period_type: PeriodType ?? "month"
          }
        })
        .then((response) => {
          setMetricsData(response.data);
          setFilteredMetricsList(response.data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [loaded, assetData?.id, PeriodType]);

  const RenderValueBadge = ({ item }) => {
    switch (item?.unit) {
      case "#":
        return `${item?.value?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 })}`;
      case "%":
        return `${item?.value?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', maximumFractionDigits: 1 })}`;
      default:
        return `${item?.value?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 })} ${item?.unit}`;
    }
  };

  const RenderValue = ({ item, upTrend }) => {
    const recentHistoryValues = item?.recent_history?.map(history => history.value) || [];

    if (recentHistoryValues.length === 0) {
      return <span>No data</span>;
    }

    let sparklineColor;
    if (upTrend === "positive") {
      sparklineColor = recentHistoryValues[recentHistoryValues.length - 1] > recentHistoryValues[0] ? palette.success : palette.danger;
    } else if (upTrend === "negative") {
      sparklineColor = recentHistoryValues[recentHistoryValues.length - 1] < recentHistoryValues[0] ? palette.success : palette.danger;
    } else {
      sparklineColor = "blue"; // for "-"
    }

    return (
      <div className="mt-3 mb-3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <span style={{ fontSize: '1.5rem' }}>
          <RenderValueBadge item={item} />
        </span>

        <div style={{ width: '100px', height: '30px' }}>
          <Sparklines data={recentHistoryValues} width={100} height={30}>
            <SparklinesLine color={sparklineColor} />
          </Sparklines>
        </div>

      </div>
    );
  };

  const categorizer = [
    {
      title: "Production",
      metric_names: [
        { name: "usage", "up_trend": "positive" },
        { name: "running_time_percent", "up_trend": "positive" },
        { name: "error_time_percent", "up_trend": "negative" },
        { name: "setup_time_percent", "up_trend": "positive" },
        { name: "share_of_planned_usage", "up_trend": "positive" },
        { name: "oee", "up_trend": "positive" },
        { name: "weekend_usage", "up_trend": "negative" }
      ]
    },
    {
      title: "Financial",
      metric_names: [
        { name: "share_of_planned_revenue", "up_trend": "positive" },
        { name: "revenue", "up_trend": "positive" },
        { name: "share_of_planned_usage", "up_trend": "positive" },
        { name: "weekend_usage", "up_trend": "negative" }
      ]
    },
    {
      title: "ESG",
      metric_names: [
        { name: "oee", "up_trend": "positive" },
        { name: "power_consumption", "up_trend": "negative" },
        { name: "power_per_use", "up_trend": "negative" },
      ]
    },
    {
      title: "Technical",
      metric_names: [
        { name: "unit_counter_share", "up_trend": "positive" },
      ]
    },
  ];

  // Render metrics based on categories defined in the categorizer
  const renderMetricsByCategory = () => {
    // const categorizedMetricNames = categorizer.flatMap(category => category.metric_names.map(m => m.name));

    return categorizer.map((category) => {
      const categoryMetrics = filteredMetricsList.filter(metric =>
        category.metric_names.some(catMetric => catMetric.name === metric.metric_name)
      );

      if (categoryMetrics.length === 0) {
        return null;
      }

      return (
        <React.Fragment key={category.title}>
          <Row className="mb-3">
            <Col>
              <h4>{t(category.title)}</h4>
            </Col>
          </Row>
          <Row>
            {categoryMetrics.sort((a, b) => a?.metric_title > b?.metric_title).map((metric) => {
              const catMetric = category.metric_names.find(catMetric => catMetric.name === metric.metric_name);
              return <RenderTile key={metric.asset_metric_id} metric={metric} upTrend={catMetric.up_trend} />;
            })}
          </Row>
        </React.Fragment>
      );
    });
  };

  // Render metrics that are not categorized
  const renderOtherMetrics = () => {
    const categorizedMetricNames = categorizer.flatMap(category => category.metric_names.map(m => m.name));

    const otherMetrics = filteredMetricsList.filter(metric =>
      !categorizedMetricNames.includes(metric.metric_name)
    );

    if (otherMetrics.length === 0) {
      return null;
    }

    return (
      <React.Fragment>
        <Row className="mt-4 mb-3">
          <Col>
            <h4>{t("Other Metrics")}</h4>
          </Col>
        </Row>
        <Row>
          {otherMetrics.map((metric) => (
            <RenderTile key={metric.asset_metric_id} metric={metric} upTrend="neutral" />
          ))}
        </Row>
      </React.Fragment>
    );
  };

  const RenderTile = ({ metric, upTrend }) => {
    return (
      <Col md="3" className="d-flex mb-4" key={metric.asset_metric_id} style={{ height: '100%', cursor: 'pointer' }}
        onClick={() => {
          setSelectedMetricData(metric);
          setShowDetails(true);
        }}>
        <Card className="flex-fill">
          <Card.Body className="d-flex flex-column justify-content-between">
            <div>
              <Row>
                <Col>
                  <span className="h4">{t(metric?.metric_name)}</span>
                </Col>
                <Col xs="auto" className="ms-auto text-end mt-n1">
                  <span>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Popover>
                          <Popover.Body>
                            <strong>{"Info"}:</strong> {metric?.explanation}
                            <br /><br />
                            {metric?.target_value &&
                              `${t("Target Value")}: ${metric?.target_value} ${metric?.unit}`}
                            {metric?.lower_limit &&
                              `${t("Lower Limit")}: ${metric?.lower_limit} ${metric?.unit}`}
                            {metric?.upper_limit &&
                              `${t("Upper Limit")}: ${metric?.upper_limit} ${metric?.unit}`}
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <Info className="feather ms-1" size="sm" />
                    </OverlayTrigger>
                  </span>
                </Col>
              </Row>
            </div>

            <div className="mt-auto">
              <Row>
                <span className="h1 d-inline-block mt-3 mb-4">
                  <RenderValue item={metric} upTrend={upTrend} />
                </span>
              </Row>

              <div className="mb-0">
                <span className="text-muted">
                  {
                    PeriodType === "day"
                      ? format(parseISO(metric.period_start), "d MMM yyyy", {
                        locale: i18n.language === "de" ? de : null,
                      })
                      : PeriodType === "month"
                        ? format(parseISO(metric.period_start), "MMM yyyy", {
                          locale: i18n.language === "de" ? de : null,
                        })
                        : `${format(parseISO(metric.period_start), "d MMM yyyy", {
                          locale: i18n.language === "de" ? de : null,
                        })} - ${format(parseISO(metric.period_end), "d MMM yyyy", {
                          locale: i18n.language === "de" ? de : null,
                        })}`
                  }
                </span>
              </div>
            </div>
          </Card.Body>
          <Card.Footer>

            <Row>
              <Col>
                <span className="text-muted">
                  <Eye className="feather me-2" />
                  {t("Watch Towers")}: {metric.watchtowers.length}
                </span>
                {/* <span>
                  <Button
                    size="sm"
                    variant="link"
                    className="mb-1"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectAddWatchtowerModal(metric)
                    }}>
                    {t("Observe KPI")}
                  </Button>
                </span> */}
              </Col>
              <Col xs="auto" className="ms-auto text-end">
                <Button
                  variant={"outline-primary"}
                  size="sm"
                // onClick={() => setPeriodType("")}
                >
                  {t("More")}
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </Col>
    );
  };

  return (
    <div style={{ background: palette["gray-200"], padding: "20px" }}>
      <MetricDetailsChartModal
        asset_id={assetData?.id}
        metric_data={selectedMetricData}
        show={showDetails}
        handleClose={() => {
          setShowDetails(false);
          setSelectedMetricData(null);
        }}
      />

      {selectAddWatchtowerModal && <SingleWatchTowerModal
        desired_asset_metric={selectAddWatchtowerModal}
        selected_asset_id={id}
        handleClose={() => {
          setSelectAddWatchtowerModal(null)
          setPeriodType("day")
        }} />}

      <Row className="mt-3 mb-4">
        <Col>
          <div className="h3">{t("Data Insights")}</div>
        </Col>
        <Col xs="auto" className="ms-auto text-end">
          <ButtonToolbar>
            <ButtonGroup>
              <Button
                variant={PeriodType === "day" ? "primary" : "outline-primary"}
                size="sm"
                onClick={() => setPeriodType("day")}>
                {t("Yesterday")}
              </Button>
              <Button
                variant={PeriodType === "month" ? "primary" : "outline-primary"}
                size="sm"
                onClick={() => setPeriodType("month")}>
                {t("Last month")}
              </Button>
              <Button
                variant={PeriodType === "all_time" ? "primary" : "outline-primary"}
                size="sm"
                onClick={() => setPeriodType("all_time")}>
                {t("All time")}
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>

      {isLoading ? (
        <LoadingSpinner withText />
      ) : (
        metricsData.length > 0 ? (
          <>
            <div>{renderMetricsByCategory()}</div>
            <div>{renderOtherMetrics()}</div>
          </>
        ) : (
          <p className="card-subtitle text-muted">{t("No data found.")}</p>
        )
      )}
    </div>
  );
};

export default MetricsSection;