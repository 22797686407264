import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Col,
  Row,
  Table,
  Spinner,
  Badge,
  Carousel,
  Accordion,
  OverlayTrigger,
  Popover,
  Tab,
  Nav,
  Dropdown,
} from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import {
  RefreshCw,
  Activity,
  Minimize2,
  Maximize2,
  Eye,
  HelpCircle,
  Monitor,
  Info,
  Edit2,
  Pause,
  Play
} from "react-feather";
import { Button } from "react-bootstrap";
import { formatDistanceToNow, parseISO, } from 'date-fns'
import { useTranslation } from "react-i18next";
import { de } from 'date-fns/locale';
// import AssetDigitalTwinChartModal from "./AssetDigitalTwinChartModal_OLD";
import AssetDigitalTwinChartModal from "./AssetDigitalTwinChartModal";
import StructureIHU from "./DigitalTwinStructures/DigitalTwinStructureIHU.json"
import StructureMAAGECO from "./DigitalTwinStructures/DigitalTwinStructureECO1000.json"
import StructureMAAGERF from "./DigitalTwinStructures/DigitalTwinStructureERF1000.json"

// import StructureMAAG_Spain from "./DigitalTwinStructures/DigitalTwinStructureECO1000_Spain.json"
import StructureMAAGPump from "./DigitalTwinStructures/DigitalTwinStructurePump.json"
import StructureMAAGAnalyses from "./DigitalTwinStructures/DigitalTwinStructureECO1000_Analyses.json"
// import StructureBinPicking from "./DigitalTwinStructures/DigitalTwinStructureBinPicking.json"
// import StructureBrikettier from "./DigitalTwinStructures/DigitalTwinStructureBrikettier.json"
// import StructureFraesmaschine from "./DigitalTwinStructures/DigitalTwinStructureFraesmaschine.json"
import { isMobile } from "react-device-detect";
import AssetDigitalTwinLiveChartModal from "./AssetDigitalTwinLiveChartModal";
import { useFullScreen } from "react-browser-hooks/lib/hooks/fullscreen";
import useSidebar from "../../../hooks/useSidebar";
import AssetDigitalTwinChartModalMultiple from "./AssetDigitalTwinChartModalMultiple";
import ECO1000_Bitmap_1 from "./DigitalTwinStructures/ECO1000_Bitmap_1.png";
import ECO1000_Bitmap_2 from "./DigitalTwinStructures/ECO1000_Bitmap_2.png";
import ECO1000_Bitmap_3 from "./DigitalTwinStructures/ECO1000_Bitmap_3.png";
import usePalette from "../../../hooks/usePalette";
import RemoteHMISectionModal from "../AssetDetailsSections/RemoteHMISectionModal";
import UserDataManager from "../../Helper/UserDataManager";
import SingleTwinStructureModal from "../IoT_Data_Setup/SingleTwinStructureModal";
import useTheme from "../../../hooks/useTheme";
import SingleWatchTowerModal from "../../Watchtower/SingleWatchTowerModal";
import SingleIoTDataPointModal from "../IoT_Data_Setup/SingleIoTDataPointModal";

const DigitalTwinCard = ({ webserviceData, simulatedJsonData, slim }) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [AssetData, setAssetData] = useState([]);
  // const [healthData, setHealthData] = useState([]);
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [PLC_Node_Ids_latest, setPLC_Node_Ids_latest] = useState([]);
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const { toggle: toggleFullscreen, fullScreen } = useFullScreen()
  const { setIsOpen } = useSidebar();
  const [isLoadingTwinData, setIsLoadingTwingData] = useState(false);
  // const [selectedNodeIdForWatchTower, setSelectedNodeIdForWatchTower] = useState(false);
  // const [showServicesModal, setShowServicesModal] = useState(false);
  const palette = usePalette();
  const [usageCycle, setUsageCycle] = useState([]);
  // const notyf = useContext(NotyfContext);
  // const [activeHMISession, setActiveHMISession] = useState();
  const [showHMIModal, setShowHMIModal] = useState(false);
  const [liveMode, setLiveMode] = useState(false);
  const [showDigialTwinEditor, setShowDigialTwinEditor] = useState(false);
  const [configData, setTwinStructureData] = useState();
  const { theme, setTheme } = useTheme();

  const CustomRow = ({ children, title }) => {
    return (
      <Row>
        <div className={slim ? "h6" : "h5"}>{t(title)}</div>
        {children}
      </Row>
    )
  };

  const CustomCol = ({ children, size, title }) => {
    return (
      <Col md={size}>
        <div className={slim ? "h5" : "h4"}>{t(title)}</div>
        {children}
      </Col>);
  }

  const CustomDivider = () => {
    return (<hr />);
  }

  function handleLiveMode(forceStop) {
    if (forceStop) {
      setLiveMode(false)
    } else {
      setLiveMode(!liveMode)
    }
  }

  function handleClickFullScreen() {
    toggleFullscreen()
    setIsOpen(fullScreen ? true : false)
  }

  // function fetchAssetHealth() {
  //   axios
  //     .get("/webservice/assets/" + id + "/health_overview", {
  //       params: {
  //         "asset_id": id,
  //       }
  //     })
  //     .then(function (response) {
  //       // handle success
  //       console.log(response.status, "/health_overview");
  //       setHealthData(response.data);
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       setError({ showMessage: true, errorData: error })
  //       console.log(error);
  //     });
  // }

  function fetchLatestAssetLogs() {

    // setIsLoadingTwingData(true)
    // setPLC_Node_Ids_latest([]);

    axios
      .get(`/assets/${id}/asset_logs/latest`, {
        params: {
          "asset_id": id,
        }
      })
      .then(function (response) {
        // handle success
        console.log(response.status, `/asset_logs/latest. Amount: ${response.data?.length}`, response.data);
        setPLC_Node_Ids_latest(response.data);  // Adjust according to your API's response structure
        // setIsLoadingTwingData(false)
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error });
        console.error('Failed to fetch data:', error);
        // setIsLoadingTwingData(false)
      });
  };

  function fetchConfigData() {
    axios
      .get("/assets/" + id + "/iot_configs")
      .then(function (response) {

        // handle success
        console.log(response.status, "/assets/iot_config");

        try {
          setTwinStructureData(response.data)
        } catch (error) {
          console.error("Error parsing twin_structure", error);
          setTwinStructureData()
        }

      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  function fetchAssetProductionDetails() {

    axios
      .get(`/webservice/assets/${id}/production/`)
      .then(function (response) {
        // handle success
        console.log(response.status, "/asset-production");
        setAssetData(prevAssetData => ({
          ...prevAssetData,
          ...response.data  // Assuming response.data is an object with keys you want to merge
        }));

      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error });
        console.error('Failed to fetch data:', error);
        // setIsLoadingTwingData(false)
      });
  };

  function fetchAssetEcoDetails() {

    axios
      .get(`/webservice/assets/${id}/eco/`)
      .then(function (response) {
        // handle success
        console.log(response.status, "/asset-eco");
        setAssetData(prevAssetData => ({
          ...prevAssetData,
          ...response.data  // Assuming response.data is an object with keys you want to merge
        }));

      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error });
        console.error('Failed to fetch data:', error);
        // setIsLoadingTwingData(false)
      });
  };

  function fetchLatestUsageCycle() {

    // setUsageCycle([]);

    axios
      .get(`/assets/${id}/usage_cycles/`, {
        params: {
          "limit": 1,
        }
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "/usage_cycle");
        setUsageCycle(response.data);  // Adjust according to your API's response structure
        // setIsLoadingTwingData(false)
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error });
        console.error('Failed to fetch data:', error);
        // setIsLoadingTwingData(false)
      });
  };

  const DataPointCard = ({ ...props }) => {
    const [data, setData] = useState(null);

    const [showWatchtowerModal, setShowWatchtowerModal] = useState(false);
    const [showIoTNodeModal, setShowIoTNodeModal] = useState(false);
    // const [selectedIoTNode, setSelectedIoTNode] = useState();

    useEffect(() => {
      if (props?.plc_node_id || props?.plc_node_id !== "") {
        // console.log("--->", PLC_Node_Ids_latest)
        const desiredObject = PLC_Node_Ids_latest.find(obj => obj.plc_node_id === props?.plc_node_id);

        if (desiredObject && desiredObject !== data) {
          setData(desiredObject)
        }
      }
    }, [props?.plc_node_id, data]);

    function renderBadgeText(value, unit) {

      switch (unit) {
        case 'bool':
          return value
        // case '%':
        //   return value?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', maximumFractionDigits: 2 })
        default:
          return value?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0 }) || "-"
      }
    }

    function renderH1Text(value, unit) {

      var temp_value = value

      if (i18n.language === "us") {
        if (unit === "°C") {
          temp_value = celsiusToFahrenheit(value)
        } else if (unit === "bar") {
          temp_value = barsToPsi(value)
        }
      }

      switch (unit) {
        // case '%':
        //   return (temp_value / 100)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', maximumFractionDigits: 2 })
        default:
          return temp_value?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 1 }) || "-"
      }
    }

    function renderUnit(unit) {

      switch (unit) {
        case "°C":
          return i18n.language === "us" ? "°F" : "°C"
        case "bool":
          return ""
        case "state":
          return ""
        case "bar":
          return i18n.language === "us" ? "PSI" : "bar"
        case "#":
          return ""
        default:
          return t(unit) || ""
      }
    }

    function celsiusToFahrenheit(celsius) {
      return ((celsius * 9 / 5) + 32)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 1 })
    }

    function barsToPsi(bars) {
      return (bars * 14.5038)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 1 })
    }

    const TrafficLightInfo = ({ data }) => {

      function getTrafficLightColour() {

        if (data?.upper_limit && data?.lower_limit) {
          if (data?.value < data?.upper_limit && data?.value > data?.lower_limit) {
            return palette.success
          } else {
            return palette.danger
          }
        } else if (data?.lower_limit) {
          if (data?.value > data?.lower_limit) {
            return palette.success
          } else {
            return palette.danger
          }

        } else if (data?.upper_limit) {
          if (data?.value < data?.upper_limit) {
            return palette.success
          } else {
            return palette.danger
          }
        }
        return null
      }

      return (
        <OverlayTrigger
          placement='auto'
          overlay={
            <Popover>
              <Popover.Header as='h3'>{t(data?.display_name)}</Popover.Header>
              <Popover.Body>
                {t("Value")}: {data?.value || "-"} {data?.unit}<br />
                {data?.target_value && `${t("Target Value")}: ${data?.target_value} ${data?.unit}`}
                {data?.lower_limit && `${t("Lower Limit")}: ${data?.lower_limit} ${data?.unit}`}
                {data?.upper_limit && `${t("Upper Limit")}: ${data?.upper_limit} ${data?.unit}`}
              </Popover.Body>
            </Popover>
          }
        >
          <div style={{ display: 'inline-flex', alignItems: 'center' }}>
            <div
              style={{
                width: '15px',
                height: '15px',
                backgroundColor: getTrafficLightColour(), // Change this to your desired color
                borderRadius: '50%',
                opacity: 0.8, // Set the opacity to make the whole dot transparent
                // paddingTop: '10px',
                // marginTop: '-20px',
                marginRight: '10px' // Space between the circle and TrafficLightInfo component
              }}
            ></div>
          </div>
        </OverlayTrigger>
      )
    }

    return (
      data?.value || (data?.value === 0 && data?.unit !== "state") || props?.alwaysShow ? <Col>

        {/* <SingleWatchTowerModal
          show={showWatchtowerModal}
          desired_plc_node_id={data?.plc_node_id}
          selected_asset_id={id}
          handleClose={() => setShowWatchtowerModal(false)} /> */}

        {/* <SingleIoTDataPointModal
          show={showIoTNodeModal}
          desired_node_id={data?.iot_node_id}
          asset_id={id}
          handleClose={() => setShowIoTNodeModal(false)} /> */}

        <SingleIoTDataPointModal
          desired_node_id={data?.iot_node_id}
          // showAdvanced
          show={showIoTNodeModal}
          asset_id={id}
          // slim
          handleClose={() => setShowIoTNodeModal(false)}
        />

        <OverlayTrigger
          placement='auto'
          overlay={
            <Popover>
              <Popover.Header as='h3'>{t(data?.display_name)}</Popover.Header>
              <Popover.Body>
                {data?.unit === "°C" &&
                  <div className={slim ? "h3" : "h1"}>
                    {i18n.language === "us" ? `${data?.value} °C` : `≈ ${celsiusToFahrenheit(data?.value)} °F`}
                  </div>}

                {data?.unit === "bar" &&
                  <div className={slim ? "h3" : "h1"}>
                    {i18n.language === "us" ? `${data?.value} bar` : `≈ ${barsToPsi(data?.value)} PSI`}
                  </div>}
                <strong>{t("Last update")}: </strong> {data === null ? '-' : formatDataDiff(parseISO(data?.time))}
              </Popover.Body>
            </Popover>
          }
        >
          <Card className="flex-fill w-100" style={{ backgroundColor: "transparent" }}>
            <Card.Body
              className="py-3"
              style={{
                backgroundColor: (theme === "dark") ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.8)", //</Card>:  "var(--bs-digi)", // Semi-transparent white background
              }}>
              <Row >
                <Col onClick={() => data?.plc_node_id && handleBadgeClick(data)} style={{ cursor: data?.plc_node_id && "pointer" }}>

                  <span className="d-inline-block">
                    {
                      (props?.badge || data?.unit === "state")

                        // With badge or individual state names
                        // ? isLongerThan10Minutes(parseISO(data?.time))

                        // Data older than 10 minutes
                        ?
                        //  <h1>
                        //   <Badge bg="" className={`${renderValueBadge("secondary")}`}>
                        //     {renderBadgeText(data?.state_name || data?.value, data?.unit)} {data?.unit ? renderUnit(data?.unit) : ""}
                        //   </Badge>
                        // </h1>

                        // : // Badge design
                        <div className={slim ? "h5" : "h3"}>
                          <Badge bg="" className={`${renderValueBadge(data?.color_coding || "secondary")}`}>
                            {renderBadgeText(t(data?.state_name) || data?.value, data?.unit)} <span className={`${slim ? "p" : "h5"} text-muted`}>{data?.unit ? renderUnit(data?.unit) : ""}</span>
                          </Badge>
                        </div>

                        // No Badge
                        : <div className={`${slim ? "h5" : "h3"} ${isLongerThan10Minutes(parseISO(data?.time)) ? "text-muted" : ""}`}>
                          {renderH1Text(data?.value, data?.unit)} <span className={`${slim ? "p" : "h6"} text-muted`}>{data?.unit ? renderUnit(data?.unit) : ""}</span>
                        </div>
                    }
                  </span>
                </Col>

                {UserDataManager.getInstance().isAdmin() && <Col xs="auto" className="ms-auto text-end mt-n1">
                  <div style={{ display: 'inline-flex', alignItems: 'center' }}>

                    <TrafficLightInfo data={data} />
                    <Dropdown align={{ lg: 'end' }} className="d-inline">
                      <Dropdown.Toggle variant="light" className="bg-white shadow-sm" size="sm">
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {/* <Dropdown.Item onClick={() => data?.plc_node_id && handleBadgeClick(data)}>{t("Show Chart")}</Dropdown.Item> */}
                        {/* <Dropdown.Item onClick={(e) => {
                          e.stopPropagation();
                          setShowWatchtowerModal(true)
                        }}>{t("Add Watchtower")}</Dropdown.Item> */}
                        <Dropdown.Item
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowIoTNodeModal(true)
                          }}>{t("Edit data point")}</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>}
              </Row>
              <div className="mb-0" onClick={() => data?.plc_node_id && handleBadgeClick(data)} style={{ backgroundColor: "transparent", cursor: data?.plc_node_id && "pointer" }}>
                {data?.display_name && <span className={slim ? "p" : "card-title"}>{data?.unit === "state" ? "" : t(data?.display_name)}</span>}
                <div className="text-muted">
                </div>
              </div>
            </Card.Body>
          </Card>
        </OverlayTrigger>
      </Col> :
        <></>
    );
  };

  const DataPointCardLastUsageCycle = ({ data, ...props }) => {
    // console.log("DataPointCardLastUsageCycle received data:", data);

    if (!data || data.length === 0) {
      return <div>No data available</div>; // or handle empty state
    }

    function renderBadgeText(value, format) {

      switch (format) {
        case 'definitionFromJson':
          switch (value) {
            case 0:
              return t("-")
            case 1:
              return t("On")
            case 2:
              return t("Flashing")
            default:
              return t("")
          }
        case 'percent':
          return value?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', maximumFractionDigits: 2 })
        case 'decimal':
          return value?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0 })
        default:
          return `${value || "-"}` // Default case
      }
    }

    return (
      data && data?.[0]?.counter_value ?
        <Col>
          <Card className="flex-fill w-100" style={{ backgroundColor: "transparent", cursor: data?.plc_node_id && "pointer" }}>
            <Card.Body
              className="py-3"
              style={{
                backgroundColor: (theme === "dark") ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.8)", //</Card>:  "var(--bs-digi)", // Semi-transparent white background
              }}
            >
              <Row>
                <Col className="mt-0">
                  <span className="d-inline-block">
                    {data && data?.[0]?.part_type
                      ? <div>
                        <div className={`${slim ? "h5" : "h3"}`}>
                          {t("Type")} {data && renderBadgeText(data?.[0]?.part_type || "-", "decimal")}
                        </div>
                        <span className={`${slim ? "p" : "card-title"}`}>{t("Part Type")}</span>
                      </div>
                      : <></>
                    }
                  </span>
                </Col>
              </Row>
              <Row>
                <Col className="mt-4">
                  <span className="d-inline-block">
                    {data && data?.[0].production_type
                      ? <div>
                        <div className={`${slim ? "h5" : "h3"}`}>
                          {t("Type")} {data && renderBadgeText(data?.[0].production_type || "-", "decimal")}
                        </div>
                        <span className={`${slim ? "p" : "card-title"}`}>{t("Production Type")}</span>
                      </div>
                      : <></>
                    }
                  </span>
                </Col>
              </Row>
              <Row>
                <Col className="mt-4">
                  <span className="d-inline-block">
                    {data && data?.[0]?.counter_value
                      ? <div>
                        <div className={`${slim ? "h5" : "h3"}`}>
                          {data && renderBadgeText(data?.[0]?.counter_value, "decimal")} {t("units")}
                        </div>
                        <span className={`${slim ? "p" : "card-title"}`}>{t("Total asset counter")}</span>
                      </div>
                      : <></>
                    }
                  </span>
                </Col>
              </Row>
              <Row>
                <Col className="mt-4">
                  <span className="d-inline-block">
                    {data && data?.[0]?.cycle_time
                      ? <div>
                        <div className={`${slim ? "h5" : "h3"}`}>
                          {data && renderBadgeText(data?.[0]?.cycle_time / 1000, "decimal")}{t("s")} ({data && renderBadgeText(data?.[0]?.process_time / 1000, "decimal")}{t("s")})
                        </div>
                        <span className={`${slim ? "p" : "card-title"}`}>{t("Cycle Time")} ({t("Process Time")})</span>
                      </div>
                      : <></>
                    }
                  </span>
                </Col>
              </Row>
              <Row>
                <Col className="mt-4">
                  <span className="d-inline-block">
                    {data && data?.[0]?.process_time
                      ? <div>
                        <div className={`${slim ? "h5" : "h3"}`}>
                          {data && renderBadgeText(data?.[0]?.process_time / data?.[0]?.cycle_time, "percent")}
                        </div>
                        <span className={`${slim ? "p" : "card-title"}`}>{t("Value added percentage")}</span>
                      </div>
                      : <></>
                    }
                  </span>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col className="mt-0">
                  <span className="d-inline-block">
                    {data && data?.[0]?.auto_vs_steps_done_perc
                      ? <div>
                        <div className={`${slim ? "h5" : "h3"}`}>
                          {data && renderBadgeText(data?.[0]?.auto_vs_steps_done_perc / 100, "percent")}
                        </div>
                        <span className={`${slim ? "p" : "card-title"}`}>{t("Automatic step %")}</span>
                      </div>
                      : <></>
                    }
                  </span>
                </Col>
              </Row>
              <div className="mb-0">
                <div className="text-muted">{data === null ? '' : formatDistanceToNow(parseISO(data?.[0]?.plc_time), { locale: i18n.language === "de" ? de : null, includeSeconds: true })}</div>
              </div>
            </Card.Body>
          </Card>
        </Col >
        : <></>
    );
  };

  const DataPointCardWebService = ({ ...props }) => {
    const [data, setData] = useState(null);

    useEffect(() => {

      if (props?.webserviceKey || props?.webserviceKey !== "") {

        // Nur ein temporärer Workaround, damit ih Emissionen darstellen kann
        if (props?.webserviceKey === "co2_kg_per_wh") {
          setData(AssetData?.power_usage_current_day * AssetData?.[props?.webserviceKey])
        } else if (props?.webserviceKey === "last_oee") {
          setData(AssetData?.oee_section?.last_oee?.oee)
        } else if (props?.webserviceKey === "usages_today") {
          setData(AssetData?.usage_section?.usages_today)
        } else {
          setData(AssetData?.[props?.webserviceKey])
        }
      }
    }, [props?.webserviceKey]);

    function renderBadgeText(value, format) {

      switch (format) {
        case 'on_off':
          return (value === 1 || value === true ? t("On") : value === 0 || value === false ? t("Off") : "-")
        case 'on_off_blinking':
          switch (value) {
            case 0:
              return t("-")
            case 1:
              return t("On")
            case 2:
              return t("Flashing")
            default:
              return t("")
          }
        case 'percent':
          return !value ? "-" : value?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', maximumFractionDigits: 2 })
        case 'decimal':
          return !value ? "-" : value?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0 })
        default:
          return `${value || "-"}` // Default case
      }
    }

    function renderH1Text(value, format) {

      switch (format) {
        case 'on_off':
          return (value === 1 || value === true ? t("On") : value === 0 || value === false ? t("Off") : "-")
        case 'on_off_blinking':
          switch (value) {
            case 0:
              return t("-")
            case 1:
              return t("On")
            case 2:
              return t("Flashing")
            default:
              return t("")
          }
        case 'percent':
          return value === 0 ? (0).toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', maximumFractionDigits: 2 }) : !value ? "-" : value?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', maximumFractionDigits: 2 })
        case 'decimal':
          return !value ? "-" : (parseFloat(value))?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0 })
        default:
          return `${value || "-"}` // Default case
      }
    }

    return (
      <Col>
        <Card className="flex-fill w-100" style={{ backgroundColor: "transparent", cursor: data?.plc_node_id && "pointer" }}>
          <Card.Body
            className="py-3"
            style={{
              backgroundColor: (theme === "dark") ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.8)", //</Card>:  "var(--bs-digi)", // Semi-transparent white background
            }}
          >
            <Row>
              <Col className="mt-0">
                <span className="d-inline-block">
                  {
                    props?.badge && data ?
                      <div className={`${slim ? "h5" : "h3"}`}>
                        <Badge bg="" className={renderValueBadge(data, props?.color)}>
                          {renderBadgeText(data, props?.format)}
                          {" "} {data ? t(props?.unit) : ''}
                        </Badge>
                      </div>
                      :
                      <div className={`${slim ? "h5" : "h3"} ${isLongerThan10Minutes(parseISO(data?.time)) ? "text-muted" : ""}`}>
                        {renderH1Text(data, props?.format)}
                        {" "} {data ? t(props?.unit) : ''}
                      </div>
                  }
                </span>
              </Col>
            </Row>
            <div className="mb-0">
              <span className={slim ? "p" : "card-title"}>{t(props?.title)}</span>
              {/* <div className="text-muted">{data === null ? '' : formatDistanceToNow(parseISO(data?.time), { locale: i18n.language === "de" ? de : null, addSuffix: true })}</div> */}
            </div>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  // const DataPointCardAnalysis = ({ ...props }) => {

  //   return (
  //     <Col>
  //       <Card className="flex-fill w-100">
  //         <Card.Body className=" py-3" onClick={() => handleAnalysisBadgeClick({ ...props })} style={{ cursor: "pointer" }}>
  //           <div className="mb-0">
  //             <span className={slim ? "h3" : "card-title"}>{t(props?.title)}</span>
  //           </div>
  //         </Card.Body>
  //       </Card>
  //     </Col>
  //   );
  // };

  const DataPointImageCard = ({ ...props }) => {
    const [data, setData] = useState(null);

    useEffect(() => {

      if (props?.images?.length > 0) {
        setData(props?.images)
      }
    }, [props?.images]);

    const imageMap = {
      'ECO1000_Bitmap_1': ECO1000_Bitmap_1,
      'ECO1000_Bitmap_2': ECO1000_Bitmap_2,
      'ECO1000_Bitmap_3': ECO1000_Bitmap_3,
      // Continue for all mappings
    };

    return (
      <Col>
        <Card className="flex-fill w-100">
          <Card.Body className=" py-3">
            <Row>
              <Col>
                <div className="text-center mt-3">
                  <Carousel indicators={true} controls={true}>
                    {
                      data?.map(file => {
                        return (
                          <Carousel.Item key={file}>
                            <img key={file} alt={file} className="center" width="auto" height="300" src={imageMap[file]} />
                          </Carousel.Item>
                        )
                      })
                    }
                  </Carousel>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  function renderUnit(unit) {

    switch (unit) {
      case "°C":
        return i18n.language === "us" ? "°F" : "°C"
      case "bar":
        return i18n.language === "us" ? "PSI" : "bar"
      default:
        return unit || ""
    }
  }

  function formatDataDiff(targetDate) {

    const currentDate = new Date();
    const timeDifference = currentDate - targetDate;

    if (timeDifference <= 1 * 60 * 1000) {
      return t("Just now")
    } else {
      return formatDistanceToNow(targetDate, { locale: i18n.language === "de" ? de : null, includeSeconds: true, addSuffix: true })
    }
  }

  const DataPointCardRaw = ({ data }) => {

    function renderUnit(unit) {

      switch (unit) {
        case "°C":
          return i18n.language === "us" ? "°F" : "°C"
        case "bar":
          return i18n.language === "us" ? "PSI" : "bar"
        default:
          return unit || ""
      }
    }

    function formatDataDiff(targetDate) {

      const currentDate = new Date();
      const timeDifference = currentDate - targetDate;

      if (timeDifference <= 1 * 60 * 1000) {
        return t("Just now")
      } else {
        return formatDistanceToNow(targetDate, { locale: i18n.language === "de" ? de : null, includeSeconds: true, addSuffix: true })
      }
    }

    return (
      <Col md="3" >
        <OverlayTrigger
          placement='bottom'
          overlay={
            <Popover>
              <Popover.Header as='h3'>{t(data?.display_name)}</Popover.Header>
              <Popover.Body>
                <strong>PLC Node ID:</strong> {data?.plc_node_id}<br /><br />
                <strong>{t("Unit")}: </strong> {data?.unit ? `(${renderUnit(data?.unit)})` : "n/a"} <br /><br />
                <strong>{t("Last update")}: </strong> {data === null ? '-' : formatDataDiff(parseISO(data?.time))}
              </Popover.Body>
            </Popover>
          }
        >
          <Card
            className="flex-fill w-100"
            style={{ backgroundColor: "transparent", cursor: data?.plc_node_id && "pointer" }}
            onClick={() => data?.plc_node_id && handleBadgeClick(data)}
          >
            <Card.Body
              className="py-3"
              style={{
                backgroundColor: (theme === "dark") ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.8)", //</Card>:  "var(--bs-digi)", // Semi-transparent white background
              }}
            >
              <Row>
                <Col>
                  <span className="d-inline-block">
                    <h3 className={`${isLongerThan10Minutes(parseISO(data?.time)) ? "text-muted" : ""}`}>
                      {(data?.value)?.toLocaleString()}
                      {" "}
                      {data?.unit ? `(${renderUnit(data?.unit)})` : ""}
                    </h3>
                  </span>
                </Col>
              </Row>
              <div className="mb-0">
                {data?.display_name
                  ? <span className="card-title">{t(data?.display_name)}</span>
                  : <span className="card-title">{t(data?.plc_node_id)}</span>}
                <div className="text-muted">
                </div>
              </div>
            </Card.Body>
          </Card>
        </OverlayTrigger>
      </Col>
    );
  };

  const DataPointCardCustom = ({ ...props }) => {
    const [data, setData] = useState(null);

    useEffect(() => {
      if (props?.plc_node_ids.length > 1) {

        const desiredObject1 = PLC_Node_Ids_latest.find(obj => obj.plc_node_id === props?.plc_node_ids[0])?.value;
        const desiredObject2 = PLC_Node_Ids_latest.find(obj => obj.plc_node_id === props?.plc_node_ids[1])?.value;

        switch (props?.mode) {
          case "subtract":
            setData(desiredObject1 - desiredObject2);
            break;
          default:
            setData();
            break;
        }
      }
    }, [props?.mode, props?.plc_node_ids]);

    function renderH1Text(value, format, definition) {

      switch (format) {
        case 'definitionFromJson':
          switch (value) {
            case 0:
              return t(definition?.[0])
            case 1:
              return t(definition?.[1])
            case 2:
              return t(definition?.[2])
            default:
              return t("-")
          }
        case 'percent':
          return (value / 100)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', maximumFractionDigits: 2 })
        case 'decimal':
          return value?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 }) || "-"
        default:
          return `${value || "-"}` // Default case
      }
    }

    function celsiusToFahrenheit(celsius) {
      var Fahrenheit = ((celsius * 9 / 5) + 32)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 1 })
      return `≈ ${Fahrenheit} °F`;
    }

    function barsToPsi(bars) {
      var Psi = (bars * 14.5038)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 1 })
      return `≈ ${Psi} PSI`;
    }

    return (
      data || props?.alwaysShow ? <Col>
        <OverlayTrigger
          placement='bottom'
          overlay={
            <Popover>
              <Popover.Header as='h3'>{t(props?.title)}</Popover.Header>
              <Popover.Body>
                {
                  props?.unit === "°C" &&
                  <h1>
                    {celsiusToFahrenheit(data)}
                  </h1>
                }
                {
                  props?.unit === "bar" &&
                  <h1>
                    {barsToPsi(data)}
                  </h1>
                }
                {/* <strong>{t("Last update")}: </strong> {data === null ? '-' : formatDataDiff(parseISO(data?.time))} */}
              </Popover.Body>
            </Popover>
          }
        >
          <Card className="flex-fill w-100">
            <Card.Body className=" py-3">
              <Row>
                <Col>
                  <span className="d-inline-block">
                    <h1>
                      {renderH1Text(data, props?.format)}
                      {" "}
                      {data ? props?.unit : ''}
                    </h1>
                  </span>
                </Col>
              </Row>
              <div className="mb-0">
                {props?.title && <span className={slim ? "h3" : "card-title"}>{t(props?.title)}</span>}
                <div className="text-muted">
                </div>
              </div>
            </Card.Body>
          </Card>
        </OverlayTrigger>
      </Col> :
        <></>
    );
  };
  function isLongerThan10Minutes(targetDate) {

    const currentDate = new Date();
    const timeDifference = currentDate - targetDate;

    // Check if the time difference is greater than or equal to 10 minutes in milliseconds.
    const isDate10MinutesOrLater = timeDifference >= 10 * 60 * 1000;

    return isDate10MinutesOrLater;
  }

  const [showModal, setShowModal] = useState(false);
  const [ModalData, setModalData] = useState([]);

  const [showAnalysisModal, setShowAnalysisModal] = useState(false);
  const [AnalysisModalData, setAnalysisModalData] = useState([]);

  const [showProductionModal, setShowProductionModal] = useState(false);
  const [ProductionModalData, setProductionModalData] = useState([]); // example default text

  const handleBadgeClick = (data) => {

    if (!data || data.plc_node_id === "") {
      alert("No historical data for this sensor.")
      return null
    }

    // console.log("Clicked on: ", data)

    setShowModal(true);
    setModalData(data)
  };

  const handleAnalysisBadgeClick = (data) => {
    if (!data || data.plc_node_id === "") {
      alert("No historical data for this sensor.")
      return null
    }

    setShowAnalysisModal(true);
    setAnalysisModalData(data)
  }

  const renderValueBadge = (color) => {

    if (color) {
      return (`badge-soft-${color} me-2`);
    } else {
      return ("badge-soft-secondary me-2");
    }
  }

  const renderTabs = (tabs) => (
    <Tab.Container id="left-tabs-example" defaultActiveKey={tabs[0].eventKey} >
      <Nav variant="tabs" style={{ background: palette["gray-100"], padding: "10px", paddingBottom: "0px" }}>
        {tabs.map((tab) => (
          <Nav.Item key={tab.eventKey}>
            <Nav.Link eventKey={tab.eventKey}>
              {/* <Info className="feather me-2" /> */}
              <img
                src={tab?.wallpaper}
                height="40px"
                className="me-2"
                alt={tab.wallpaper ? "img" : ""} // Only set alt if the image exists
              />
              <strong>{isMobile ? "" : t(tab.title)}</strong>
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      <Tab.Content style={{ background: palette["gray-100"], padding: "10px", paddingTop: "0px" }}>
        {tabs.map((tab) => (
          <Tab.Pane
            eventKey={tab.eventKey}
            key={tab.eventKey}
            style={{
              position: "relative", // Make sure the Tab.Pane is relative so the background stays within it
              minHeight: "400px",
              padding: "20px",
              backgroundColor: "rgba(255, 255, 255, 0.2)", // white with 80% opacity
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                padding: "30px", // Add 30px padding on all sides
                backgroundImage: `url(${tab.wallpaper})`,
                backgroundSize: "contain", // Maintain the aspect ratio and make the image fit within the container
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat", // Prevent the image from repeating
                opacity: 0.15, // Set the image opacity here
                zIndex: 0,     // Make sure it is behind the content
              }}
            />
            <RenderFromJSON node={{ type: "Col", children: tab.children }} />
          </Tab.Pane>
        ))}
      </Tab.Content>

    </Tab.Container>
  );

  function getJsonData() {

    // if (id === "a6snwR3") {
    //   return StructureIHU
    // }

    // if (id === "aqkpId0") {
    //   return StructureMAAGPump
    // }

    // if (id === "al4VVdk") {
    //   return StructureMAAGECO
    // }

    // if (id === "aOVeu55") {
    //   return StructureMAAGERF
    // }

    if (simulatedJsonData) {
      return simulatedJsonData
    }

    if (configData?.twin_structure) {
      return configData?.twin_structure
    } else {
      return "fallback"
    }

    // switch (id) {
    //   case "aGkyHyy": // Bin Picking
    //     return (StructureBinPicking)
    //   case "al4VVdk": // MAAG Asset
    //     return (StructureMAAG)
    //   case "aOVeu55": // MAAG Asset
    //     return (StructureMAAG)
    //   case "aXG6jjk": // MAAG Asset
    //     return (StructureMAAG_Spain)
    //   case "acThh1a": // MAAG Asset
    //     return (StructureMAAG)
    //   case "am7Sixa": // MAAG Asset
    //     return (StructureMAAG)
    //   case "aMglD4V": // MAAG Pactive
    //     return (StructureMAAG)
    //   case "aEK7snL": // MAAG ADS
    //     return (StructureMAAG)
    //   case "aVh8T99": // MAAG ADS
    //     return (StructureMAAG)
    //   case "aAeB2eG": // MAAG ADS
    //     return (StructureMAAG)
    //   case "a6snwR3": // IHU
    //     return (StructureIHU)
    //   case "a67Sf3N": // IHU PROD
    //     return (StructureIHU)
    //   case "aItiBRZ": // IHU PROD DEMO
    //     return (StructureIHU)
    //   case "a0yDKdP": // Fraesmaschine
    //     return (StructureFraesmaschine)
    //   case "aqkpId0": // StructureMAAGPump
    //     return (StructureMAAGPump)
    //   case "a3RgClO": // Brikettier WEIMA
    //     return (StructureBrikettier)
    //   default:
    //     return ("fallback")
    // }
  }

  // function getAnalysesJsonData() {

  //   switch (id) {
  //     case "al4VVdk": // MAAG Asset
  //       return (StructureMAAGAnalyses)
  //     case "aOVeu55": // MAAG Asset
  //       return (StructureMAAGAnalyses)
  //     case "aXG6jjk": // MAAG Asset
  //       return (StructureMAAGAnalyses)
  //     case "acThh1a": // MAAG Asset
  //       return (StructureMAAGAnalyses)
  //     case "am7Sixa": // MAAG Asset
  //       return (StructureMAAGAnalyses)
  //     case "aMglD4V": // MAAG Pactive
  //       return (StructureMAAGAnalyses)
  //     case "aEK7snL": // MAAG ADS
  //       return (StructureMAAGAnalyses)
  //     case "aVh8T99": // MAAG ADS
  //       return (StructureMAAGAnalyses)
  //     case "aAeB2eG": // MAAG ADS
  //       return (StructureMAAGAnalyses)
  //     default:
  //       return (null)
  //   }
  // }

  const RenderFromJSON = ({ node }) => {
    if (!node) {
      console.warn("RenderFromJSON received a null or undefined node");
      return null;
    }

    let Component;
    let props = { ...node };

    switch (node.type) {
      case "TabContainer":
        return renderTabs(node.tabs);
      case 'Row':
        Component = CustomRow;
        break;
      case 'Col':
        Component = CustomCol;
        break;
      case 'Divider':
        Component = CustomDivider;
        break;
      case "DataPointCard":
        Component = DataPointCard;
        break;
      case "DataPointCardWebService":
        Component = DataPointCardWebService;
        break;
      case "DataPointCardLastUsageCycle":
        props.data = usageCycle; // Example of adding a new prop
        Component = DataPointCardLastUsageCycle;
        break;
      // case "DataPointCardAnalysis":
      //   Component = DataPointCardAnalysis;
      //   break;
      case "DataPointImageCard":
        Component = DataPointImageCard;
        break;
      case "DataPointCardCustom":
        Component = DataPointCardCustom;
        break;
      default:
        console.warn("RenderFromJSON: Unrecognized type", node.type);
        return null;
    }

    // console.log("Rendering component of type:", node.type, "with props:", props);

    return (
      <Component {...props} >
        {node.children &&
          node.children.map((child, index) => (
            <RenderFromJSON key={index} node={child} />
          ))}
      </Component>
    );
  };



  const Live_Data_INTERVAL = 2000;

  useEffect(() => {
    setIsLoading(true);
    fetchConfigData();
    setAssetData(webserviceData);
    fetchLatestUsageCycle();
    fetchLatestAssetLogs();
    fetchAssetProductionDetails();
    fetchAssetEcoDetails();
  }, []);

  useEffect(() => {
    let timer;

    if (showProductionModal || showHMIModal || showDigialTwinEditor || showHMIModal || showModal) {
      handleLiveMode(true)
    }

    if (liveMode) {
      timer = setInterval(() => {
        console.log("Running timer");
        fetchLatestAssetLogs();
        fetchLatestUsageCycle();
      }, Live_Data_INTERVAL);
    }

    return () => {
      if (timer) {
        console.log("Stopped timer");
        clearInterval(timer);
      }
    };
  }, [showProductionModal, showHMIModal, liveMode, showDigialTwinEditor, showHMIModal, showModal]);


  const ShowLoadingSpinner = () => {

    return (
      <div className="text-center">
        <br />
        <Spinner animation="border" variant="secondary" />
        <br /><br /> {" "}
      </div>
    )
  }


  return (
    <>

      <SingleTwinStructureModal asset_id={id} show={showDigialTwinEditor} configData={configData} handleClose={() => {
        setShowDigialTwinEditor(false)
        window.location.reload(false);
      }} />
      <RemoteHMISectionModal show={showHMIModal} handleClose={() => setShowHMIModal(false)} />
      <AssetDigitalTwinChartModalMultiple show={showAnalysisModal} handleClose={() => setShowAnalysisModal(false)} data={AnalysisModalData} asset_id={id} />
      <AssetDigitalTwinChartModal show={showModal} handleClose={() => setShowModal(false)} desired_iot_node_id={ModalData?.iot_node_id} asset_data={AssetData} asset_id={id} />
      <AssetDigitalTwinLiveChartModal show={showProductionModal} handleClose={() => setShowProductionModal(false)} data={ProductionModalData} asset_id={id} />

      <Card className="flex-fill w-100">
        {slim ? <></> :
          <Card.Header>
            <Col xs="auto">
              <div className="d-flex">
                <Card.Title tag="h5" className="mb-0 me-2">
                  {t("Digital Twin")}
                </Card.Title>
                <OverlayTrigger
                  placement='bottom'
                  overlay={<Popover>
                    <Popover.Header as='h3'>{t("Digital Twin")}</Popover.Header>
                    <Popover.Body>
                      {t("A \"digital twin\" is a digital representation of a physical object, system or process. This digital model reflects the real-time data, properties, states and behavior of its physical counterpart. By linking it with sensor data, digital twins can be used to monitor, simulate, optimize or predict the behaviour of machines, buildings or entire cities, for example. Digital twins play a particularly important role in Industry 4.0, the Internet of Things (IoT) and building technology.")}
                    </Popover.Body>
                  </Popover>}
                >
                  <HelpCircle className="feather" />
                </OverlayTrigger>
              </div>
            </Col>
            <Col xs="auto" className="ms-auto text-end mt-n3">

              {AssetData?.hmi_enabled && <Button
                onClick={() => setShowHMIModal(true)}
                variant={"outline-primary"}
                size="sm"
                className="shadow-sm me-1">
                <Monitor className="feather" /> {!isMobile && t("Remote-HMI Access")}
              </Button>}

              {UserDataManager.getInstance().isAdmin() && <Button
                onClick={() => navigate("/assets/" + id + "/setup")}
                variant={"outline-primary"}
                size="sm"
                className="shadow-sm me-1">
                <Edit2 className="feather" /> {!isMobile && t("IoT-Daten")}
              </Button>}

              {UserDataManager.getInstance().isAdmin() && <Button
                onClick={() => setShowDigialTwinEditor(true)}
                variant={"outline-primary"}
                size="sm"
                className="shadow-sm me-1">
                <Edit2 className="feather" /> {!isMobile && t("Editor")}
              </Button>}

              {AssetData?.usage_enabled && <>
                <Button
                  onClick={() => setShowProductionModal(true)}
                  variant="outline-primary"
                  size="sm"
                  className="shadow-sm me-1">
                  <Activity className="feather" /> {!isMobile && t("Live-Production")}
                </Button>
              </>}

              {/* {(UserDataManager.getInstance().isTenantAdmin() || UserDataManager.getInstance().isCapOn()) && AssetData?.id === "aOVeu55" && <Button
              onClick={() => navigate("/assets/" + AssetData?.id + "/?focus=analysis")}
              // onClick={() => reloadDigitalTwin()}
              variant="outline-primary"
              size="sm"
              className="shadow-sm me-1">
              <Award className="feather" /> {!isMobile && t("Anomaly Detection")}
            </Button>} */}

              {/* <Button
              onClick={() => navigate("/assets/" + id + "/usage")}
              variant="outline-primary"
              size="sm"
              className="shadow-sm me-1">
              <BarChart2 className="feather" /> {!isMobile && t("More usage details")}
            </Button> */}

              {AssetData?.usage_enabled && <Button
                onClick={() => navigate("/assets/" + id + "/insights")}
                variant="outline-primary"
                size="sm"
                className="shadow-sm me-1">
                <Eye className="feather" /> {!isMobile && t("Insights")}
              </Button>}

              {/* {UserDataManager.getInstance().isAdmin() && <Button
              onClick={() => setShowEditModal(true)}
              variant="outline-primary"
              size="sm"
              className="shadow-sm me-1">
              <Edit2 className="feather" /> Edit digital twin
            </Button>} */}

              {!isMobile && <Button
                onClick={() => handleLiveMode()}
                variant={liveMode ? "outline-danger" : "outline-primary"}
                size="sm"
                className="shadow-sm me-1">
                {
                  liveMode
                    ? <><Pause className="feather" />  {t('Stop Live Mode')} </>
                    : <><Play className="feather" />  {t('Start Live Mode')} </>
                }
              </Button>}

              {!isMobile && <Button
                onClick={() => handleClickFullScreen()}
                variant={fullScreen ? "outline-primary" : "outline-primary"}
                size="sm"
                className="shadow-sm me-1">
                {
                  fullScreen ?
                    <><Minimize2 className="feather" />  {t('Stop fullscreen')} </> :
                    <Maximize2 className="feather" />
                }
              </Button>}

              <Button
                onClick={() => {
                  fetchLatestAssetLogs()
                  fetchLatestUsageCycle()
                }}
                variant="outline-primary"
                size="sm"
                className="shadow-sm">
                <RefreshCw className="feather" />
              </Button>
            </Col>
          </Card.Header>}
        <Card.Body >
          {/* <div className="py-2" style={{ background: palette["gray-100"], padding: "20px" }}>
            <h6 className="mt-3 mb-4">{slim ? "Editor" : t("Live Condition Monitoring")}</h6> */}

          {isLoadingTwinData
            ? ShowLoadingSpinner()
            : getJsonData() === "fallback"
              ? <Row className="mt-2" style={{ background: palette["gray-100"], padding: "20px" }}>
                {PLC_Node_Ids_latest?.map(item => <DataPointCardRaw key={item?.plc_node_id} data={item} />)}
              </Row>
              : <RenderFromJSON node={getJsonData()} />}
          {/* </div> */}
          {/* {getAnalysesJsonData() && <>
            <br /><br />
            <div className="py-2" style={{ background: palette["gray-100"], padding: "20px" }}>
              <h6 className="mt-3 mb-2">{t("Analyses")}</h6>
              <RenderFromJSON node={getAnalysesJsonData()} />
            </div>
          </>} */}

          {slim ? <></> : <><br /><br />
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="1" className="bg-white">
                <Accordion.Header>{t("Raw Data Monitor")}</Accordion.Header>
                <Accordion.Body className="py-2" style={{ padding: "40px" }}>
                  <br />
                  <Row>
                    <Col xs="auto">
                      <div className="d-flex">
                        <Card.Title tag="h5" className="mb-0">
                          {t("Raw data (latest of a kind)")}
                        </Card.Title>
                      </div>
                    </Col>
                    <Col xs="auto" className="ms-auto text-end">
                      <Button
                        variant="outline-primary"
                        className="shadow-sm me-1"
                        size="sm"
                        onClick={() => navigate("/assets/" + id + "/setup")}>
                        {t("IoT data setup")}
                      </Button>
                      <Button
                        onClick={() => fetchLatestAssetLogs()}
                        // onClick={() => reloadDigitalTwin()}
                        variant="outline-primary"
                        size="sm"
                        className="shadow-sm">
                        <RefreshCw className="feather" />
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Table size="sm" >
                      <thead>
                        <tr>
                          <th scope="col">{t("Latest")}</th>
                          <th scope="col">{t("Display Name")}</th>
                          <th scope="col">{t("Value")}</th>
                          <th scope="col">{t("Unit")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {PLC_Node_Ids_latest?.sort((a, b) => a?.time < b?.time)?.map((item) => {
                          return <tr
                            key={item?.plc_node_id}
                          // onClick={() => {
                          //   setSelectedNodeIdForWatchTower(item?.plc_node_id)
                          //   setShowWatchTowerModal(true)
                          // }}
                          // style={{ cursor: "pointer" }}
                          >
                            <td><strong>{item === null ? '-' : formatDataDiff(parseISO(item?.time))}</strong></td>
                            <td>
                              <span>
                                <OverlayTrigger
                                  placement='bottom'
                                  overlay={
                                    <Popover>
                                      <Popover.Body>
                                        Technical-ID:<br />
                                        <strong>{item?.plc_node_id}</strong>
                                      </Popover.Body>
                                    </Popover>
                                  }
                                >
                                  <Info className="feather me-2" />
                                </OverlayTrigger>
                              </span>
                              {t(item?.display_name) || item?.plc_node_id || "-"}
                            </td>
                            <td>{item?.value || "-"}</td>
                            <td>{item?.unit ? `(${renderUnit(item?.unit)})` : "n/a"}</td>
                          </tr>
                        })}
                      </tbody>
                    </Table>
                    {/* {PLC_Node_Ids_latest?.map(item => <DataPointCardRaw data={item} />)} */}
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion></>}
        </Card.Body>
      </Card>
    </>
  );
};

export default DigitalTwinCard;
