import {
  Col,
  Modal,
  Card,
  Tab,
  Nav,
} from "react-bootstrap";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

const ProductDescriptionTechPremium = (props) => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  return (
    <>
      {/* <MoreHorizontal alt="More" className="align-middle" size={18} style={{ cursor: "pointer" }} onClick={() => toggle()} /> */}
      <Col className="ms-auto text-center mb-2">
        <Card.Link style={{ cursor: "pointer" }} onClick={() => toggle()}>{t("Produktdetails")}</Card.Link>
      </Col>      <Modal size="xl" show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t("cap-on Tech Premium")}</Modal.Header>
        <Modal.Body className="m-3" style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
          <h3>cap-on Tech Premium</h3>
          <br />
          <div className={"tab tab-vertical"}>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="first">
                    Beschreibung
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">
                    Vorteile
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="third">
                    Preis
                  </Nav.Link>
                </Nav.Item> */}
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <ReactMarkdown>{`
              
### Funktionsbeschreibung
- - - 
Der dedizierte **cap-on IoT-Hub** ist die zentrale All-in-One Web Applikation für Produktionsleiter, Werkskräfte sowie Managementpersonal des Kunden. Hier werden alle relevanten cap-on Daten visuell aufbereitet während User sich sämtlicher Funktionen vom Echtzeit-Monitoring der Assets, der Erstellung von Rechnungen und vielem mehr bedienen können.

#### Dashboards: Analysen und KPIs
Relevante Daten werden automatisch in hilfreiche Analysen und KPIs übersetzt und visuell aufbereitet, um Nutzern Einblicke in den Asset Status und zukünftige Zahlungen zu geben. Jegliche individuelle KPIs können darüber hinaus selbst konfiguriert werden.

#### Asset 360° Ansicht
Die cap-on Asset 360°-Ansicht beinhaltet eine aktuelle Zustandsüberwachung (Condition Monitoring) sowie Echtzeit-Produktion und Störungsmeldungen direkt in der cap-on Plattform.
Dies beinhaltet darüber hinaus eine digitale Maschinenakte eines Assets, die dem Asset-Nutzer hilft, dem Kundenbetreuer oder dem Wartungstechniker bereits aus der Ferne erste Diagnose bzw. Hilfe leisten zu können. Die digitale Maschinenakte zeigt auf einen Blick an, ob das Asset einsatzbereit ist, in Benutzung ist oder eine Störung vorliegt.

#### cap-on Insights
- OEE-Kalkulation
- Taktzeitanalyse
- Produktions- und Finanzkennzahlen (KPI)
- Nutzungs- und Trendanalysen: 
  - Einblicke in das Nutzungsverhalten im Vergleich zur geplanten Produktion
  - Forecast von künftigen Zahlungen und ROI basierend auf erhobenen Nutzungsdaten
  - Asset-Bonitätsprüfung und Maximierung des Restwerts durch Verschleiß- und Restwertberechnung
  - Steigerung der Assetlanglebigkeit durch Condition Monitoring und Predictive Maintenance

#### cap-on Enhance
cap-on Enhance ist ein KI-gestütztes System zur Prozessoptimierung in der Industrie 4.0-Ära. Durch automatisierte Datenanalyse erkennt cap-on Enhance Verbesserungspotenziale in der Produktion und gibt maßgeschneiderte Handlungsempfehlungen zur Steigerung des Produktionsoutputs und Senkung von Produktionskosten. Mit fortschrittlichen Algorithmen und Machine Learning können Engpässe erkannt und spezifische Empfehlungen zur Prozessoptimierung gegeben werden. Unternehmen erhalten außerdem Zugang zu professionellen Servicedienstleistern für eine effektive Umsetzung der Empfehlungen und eine Steigerung der Produktionseffizienz.
Angestrebte Vorteile des Kunden:
- Minimierung der Störzeiten durch Condition Monitoring und Predictive Maintenance
- Verbesserte Anlageneffizienz: Durch die Überwachung von Betriebsdaten können Unternehmen Störungen schneller erkennen und beseitigen, was die Ausfallzeiten reduziert und die Lebensdauer der Anlagen verlängert.
- Erhöhte Produktivität: Durch die Überwachung von Produktionsprozessen können Unternehmen identifizieren, wo Optimierungspotential besteht, was zu einer höheren Ausbringung der Anlagen führt.
- Reduktion von Instandhaltungskosten: Durch die Überwachung von Anlagendaten können Unternehmen Wartungsarbeiten planen und gezielt durchführen, was die Anzahl ungeplanter Ausfallzeiten reduziert.
- Erhöhte Qualität der Produkte: Durch die Überwachung der Produktionsprozesse können Unternehmen die Qualität ihrer Produkte verbessern, indem sie Fehler frühzeitig erkennen und beseitigen.
- Erhöhte Sicherheit: Durch die Überwachung von Anlagendaten können Unternehmen potenzielle Sicherheitsrisiken erkennen und entsprechende Maßnahmen ergreifen, um Unfälle zu vermeiden.

#### cap-on Watchtower
Das Einsetzen eines IoT-Gateways ermöglicht eine automatisierte Überwachung der Assets im Shop-Floor. Dies beinhaltet die Erfassung von Daten wie Betriebszeiten, Produktionsvolumen, Energieverbrauch und Fehlermeldungen. Diese Daten werden in Echtzeit ausgewertet und proaktive Alarmmeldungen generiert, falls bestimmte Schwellenwerte überschritten werden oder andere Anomalien auftreten.
cap-on Watchtower ist die ideale Lösung für eine effektive IoT-basierte Produktionsoptimierung. Das KI-gestützte Monitoring erkennt negative Produktionsmuster und -anomalien, während das automatische Alarmsystem bei Über-/Unterschreitung von KPI-Schwellenwerten eine sofortige multi-channel Benachrichtigung auslöst. Unternehmen können so schnell auf Probleme reagieren und Ausfälle vermeiden, um die Produktionskosten zu senken und Störzeiten zu minimieren. Mit Cap-on Watchtower können Sie Ihre Produktionsprozesse effektiv überwachen und optimieren, um wettbewerbsfähig zu bleiben.
Die Alarmmeldungen können per Email, Microsoft Teams, Slack oder einem anderen Kommunikationskanal versendet werden, um die Verantwortlichen schnellstmöglich zu informieren, falls ein Asset nicht den erwarteten Betriebszustand aufweist. Dies ermöglicht es, schnell auf Probleme zu reagieren und Ausfälle zu vermeiden, was letztendlich zu einer höheren Produktivität und Effizienz führt. Durch die Nutzung von proaktivem Alarmsystem, kann eine frühzeitige Erkennung von Problemen und eine schnelle Reaktion sichergestellt werden, was wiederum die Verfügbarkeit der Anlagen erhöht und die Betriebskosten senkt.
- KI-gestütztes Monitoring erkennt negative Produktionsmuster und -anomalien
- Automatisches Alarmsystem inkl. multi-channel Benachrichtigung bei Über-/Unterschreitung angegebener KPI-Schwellenwerte
- Dadurch können Unternehmen schneller auf Probleme reagieren und Ausfälle vermeiden

#### Support Portal
Das Support-Portal ist Teil der cap-on Plattform, der Nutzern die Möglichkeit gibt, schnell und einfach Hilfe und Unterstützung des cap-on Support-Teams zu erhalten.

#### Digitale Vertrags- und Rechnungsübersicht
Mit der Rechnungsübersicht können Sie Ihre Ausgaben leicht nachvollziehen, einzelne Rechnungen anzeigen. Beurteilen Sie schnell, ob Ihre monatlichen Ausgaben mit früheren Zeiträumen, Prognosen oder dem Budget übereinstimmen, und führen Sie rechtzeitig Untersuchungen und Abhilfemaßnahmen durch.

#### FAQ - Wissensdatenbank
Die FAQ-Seite auf der cap-on Plattform unterstützt Unternehmen beim Change Management Prozess, indem sie häufig gestellte Fragen zum Thema "Asset-as-a-Service" beantwortet. Einige Beispiele für Fragen, die auf der FAQ-Seite beantwortet werden können, sind: Was ist Asset-as-a-Service? Welche Vorteile bietet es für Unternehmen? Wie kann man damit beginnen, dieses Geschäftsmodell zu implementieren?
Eine gut gestaltete FAQ-Seite kann auch dazu beitragen, dass Mitarbeiter und Kunden schneller die richtigen Informationen finden und Unsicherheiten oder Missverständnisse reduziert werden.

#### Datenexport/-Integration
cap-on bietet eine Consumer API, die es ermöglicht, erhobene Nutzungsdaten sowie Kunden-/Vertrags- und Rechnungsdaten in Bestandssysteme (wie SAP, ERP, Automatisierungssysteme, etc.) zu übertragen. Dies ermöglicht eine individuelle Verarbeitung und Analyse der Daten und unterstützt die Entscheidungsfindung.
Die Consumer API wird in gängigen maschinenlesbaren Formaten (wie JSON, REST API, Excel) bereitgestellt, um eine einfache Integration in bestehende Kundensysteme zu ermöglichen. Eine ausführliche Dokumentation unterstützt bei der Inbetriebnahme der technischen Schnittstelle.
Unser Implementierungsteam steht dem Kunden zur Seite, um eine erfolgreiche Datenanbindung sicherzustellen. Dies schließt auch die Unterstützung bei der Konfiguration und Anpassung der API an die Anforderungen des Kunden ein.

#### Regelmäßige Software-Updates und neue Funktionen
`}
                  </ReactMarkdown>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <ReactMarkdown>{`

### Ihre Vorteile
- - - 

- Transparente Maschinennutzungsdaten
  - Echtzeitnutzung des Assets
  - Betriebsstunden
  - Störungen, etc.
- Intelligente Analysen und Insights
  - Trendanalysen und Prognosen
  - Automatische Taktzeit-/Prozessanalyse inkl. monatlichem Report
  - KPIs (z.B. OEE)
- Potentialanalyse
  - OEE Steigerung (Verfügbarkeit, Produktivität, Qualität) [zusätzlich bei Bedarf buchbar]
  - Taktzeitverbesserung [zusätzlich bei Bedarf buchbar]
  - Später: Weniger Produktionsausfälle/Störfälle durch automatisiertes, KI-basiertes Predictive Maintenance
- Zuverlässige Datenkommunikation durch Hochverfügbarkeitstechnologie 

`}
                  </ReactMarkdown>
                </Tab.Pane>
                {/* <Tab.Pane eventKey="third">
                  <h4 className="tab-title">One more</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                    commodo ligula eget dolor tellus eget condimentum rhoncus. Aenean
                    massa. Cum sociis natoque penatibus et magnis neque dis parturient
                    montes, nascetur ridiculus mus.
                  </p>
                  <p>
                    Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.
                    Nulla consequat massa quis enim. Donec pede justo, fringilla vel,
                    aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut,
                    imperdiet a, venenatis vitae, justo.
                  </p>
                </Tab.Pane> */}
              </Tab.Content>
            </Tab.Container>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => toggle(false)}>
            {t("OK")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProductDescriptionTechPremium;
