import { useNavigate, useParams } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  Form,
  InputGroup,
  Breadcrumb,
  Container,
} from "react-bootstrap";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import climateGridLogo from "../../assets/company/climate_grid_gross.png";
import versoLogo from "../../assets/company/verso.svg";
import { Helmet } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import CompensationModal from "./CompensationModal";

const PCFCalculator = (props) => {
  let { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showPCFResult, setShowPCFResult] = useState(false);

  return (
    <React.Fragment>
      <Helmet title="Product Carbon Footprint" />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Breadcrumb>
            <Button variant="outline-primary" size="sm" className="me-2" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faArrowLeft} /> {t("Back")}
            </Button>
            <Breadcrumb.Item href="/assets">{t("Assets")}</Breadcrumb.Item>
            <Breadcrumb.Item href="#" onClick={() => navigate("/assets/" + id)}>{t("Asset 360° View")}</Breadcrumb.Item>
            <Breadcrumb.Item active>{t("Product Carbon Footprint")}</Breadcrumb.Item>
          </Breadcrumb>

          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>{t("Product Carbon Footprint | Rechner")}</h3>
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row> */}
        <Row>
          <Col md="12" className="d-flex">
            <Card className="flex-fill w-100">
              <Card.Body>

                <Row>
                  <Col md="9">
                    <p>Hier berechnet cap-on den Product Carbon Footprint (Cradle-to-Gate) einer Einheit. Der Produkt-Carbon-Fußabdruck (Product Carbon Footprint, PCF) ist eine Maßzahl für den Kohlenstoffausstoß, der mit der Herstellung, dem Gebrauch und der Entsorgung eines Produkts verbunden ist. Es gibt einen Überblick über die Treibhausgasemissionen, die aufgrund der gesamten Lebenszyklus eines Produkts verursacht werden. Der PCF kann verwendet werden, um die Umweltauswirkungen von Produkten zu bewerten und zu vergleichen, um Entscheidungen über nachhaltigere Alternativen zu treffen.</p>
                  </Col>
                  <Col md="3" xs="auto" className="ms-auto text-end mt-n1">

                    In Kooperation mit:
                    <br />
                    <img
                      src={climateGridLogo}
                      alt="avatar"
                      className="me-3"
                      width="auto"
                      height={50}
                    />
                    <img
                      src={versoLogo}
                      alt="avatar"
                      className="me-3"
                      width="auto"
                      height={25}
                    />
                  </Col>
                </Row>

                <br />
                <br />
                <Row>
                  <Col md={6}>
                    <h4 className="mb-4">Einmalige Emissionen (Asset-Herstellung)</h4>
                    <Form.Group className="mb-3">
                      <Form.Label>Fußabdruck in CO<sub>2</sub>e vor SOP (in Herstellung/Aufbereitung)</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="number"
                          // defaultValue={}
                          placeholder="120"
                        />
                        <InputGroup.Text>kg CO<sub>2</sub>e</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Werkzeug [1…n] mit BOM</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="number"
                          // defaultValue={}
                          placeholder="120"
                        />
                        <InputGroup.Text>kg CO<sub>2</sub>e</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Verschleißteile [1…n] mit BOM</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="number"
                          // defaultValue={}
                          placeholder="120"
                        />
                        <InputGroup.Text>kg CO<sub>2</sub>e</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <h4 className="mb-4">Monatliche Emissionen (im Betrieb)</h4>
                    <Form.Group className="mb-3">
                      <Form.Label>Stromverbrauch</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="number"
                          // defaultValue={}
                          disabled
                          placeholder="3211220"
                        />
                        <InputGroup.Text>Wh (Wattstunden)</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Wasserverbrauch</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="number"
                          // defaultValue={}
                          placeholder="120"
                        />
                        <InputGroup.Text>Liter</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Kühlmittelverbrauch</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="number"
                          // defaultValue={}
                          placeholder="120"
                        />
                        <InputGroup.Text>Liter</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Schmiermittel</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="number"
                          // defaultValue={}
                          placeholder="120"
                        />
                        <InputGroup.Text>Liter</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Ölverbrauch</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="number"
                          // defaultValue={}
                          placeholder="120"
                        />
                        <InputGroup.Text>Liter</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Anzahl der Werkzeugwechsel (pro Monat)</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="number"
                          // defaultValue={}
                          placeholder="3"
                        />
                        <InputGroup.Text>Anzahl</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Anzahl der Verschleißteile (pro Monat)</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="number"
                          // defaultValue={}
                          placeholder="24"
                        />
                        <InputGroup.Text>Anzahl</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <br />
                <br />
                <hr />
                <br />
                <Row>
                  <Col md={12}>
                    <h4 className="mb-4">Zwischensumme</h4>
                    <Form.Group className="mb-3">
                      <Form.Label>Zwischensumme: Herstellungsemissionen pro Monat (anteilig)</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="number"
                          defaultValue="438"
                          disabled
                          placeholder="120"
                        />
                        <InputGroup.Text>kg CO<sub>2</sub>e</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Zwischensumme: Nutzungsemissionen pro Monat</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="number"
                          defaultValue="113"
                          disabled
                          placeholder="120"
                        />
                        <InputGroup.Text>kg CO<sub>2</sub>e</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <br />
                <br />
                <Row>
                  <Col md={12}>
                    <h4 className="mb-4">Infos aus dem cap-on System</h4>
                    <Form.Group className="mb-3">
                      <Form.Label>Laufzeit</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="number"
                          defaultValue={"48"}
                          disabled
                          placeholder="120"
                        />
                        <InputGroup.Text>Monate</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Produzierte Einheiten pro Monat (ø)</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="number"
                          defaultValue={"2731"}
                          disabled
                          placeholder="120"
                        />
                        <InputGroup.Text>Monate</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <br />
                <br />
                <Row>
                  <h4 className="mb-4">PCF-Kalkulation</h4>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>PCF des Rohmaterials/Blech/etc.</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="number"
                          defaultValue="48"
                          placeholder="120"
                        />
                        <InputGroup.Text>kg CO<sub>2</sub>e</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Fußabdruck pro Hub/Satz</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="number"
                          disabled
                          defaultValue="48"
                          placeholder="120"
                        />
                        <InputGroup.Text>kg CO<sub>2</sub>e</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>

                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <Button variant="outline-primary" className="shadow-sm me-1" onClick={() => navigate(-1)}>
                      {t("Reset")}
                    </Button>
                    <Button variant="primary" className="shadow-sm me-1" onClick={() => setShowPCFResult(true)}>
                      {t("Calculate PCF")}
                    </Button>
                  </Col>
                </Row>
                <br />
                <br />
                <Row>
                  <Col>
                    {
                      showPCFResult && <><Form.Group className="mb-3">
                        <Form.Label>Gesamt-PCF des produzierten Teils bei Abtransport (Gate) | Output Carbon Footprint</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            type="number"
                            disabled
                            defaultValue="482"
                            placeholder="120"
                          />
                          <InputGroup.Text>kg CO<sub>2</sub>e</InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                        <br />
                        <Row>
                          <Col>
                            <CompensationModal buttonSize="regular" offsettable_co2e={482 / 1000} />
                          </Col>
                        </Row>
                      </>
                    }
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row >
      </Container>
    </React.Fragment>
  );
};

export default PCFCalculator;
