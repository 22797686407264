import React, { useContext, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import NotyfContext from "../../../contexts/NotyfContext";
import ErrorHandler from "../../Helper/ErrorHandler";
import { Plus } from "react-feather";
import { getIAMBaseURL } from "../../../config";

const AddTenantModal = (props) => {
  const notyf = useContext(NotyfContext);
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [openModal, setOpenModal] = useState(false);

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  const [name, setName] = useState("");
  const [env, setEnv] = useState("");
  const [url, setUrl] = useState("");

  function handleSubmit(e) {
    e.preventDefault();

    setError({ showMessage: false, errorData: null })

    axios
      .post(getIAMBaseURL() + "/tenants/", {
        "name": name,
        "url": url,
        "env": env
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "Load");
        toggle(false);

        notyf.open({
          type: "success",
          message: t("New item \"") + name + t("\" has been added."),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })

        refreshPage();
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <>
      <Button
        onClick={() => toggle()}
        variant="primary"
        className="shadow-sm me-1"
      >
        <Plus className="feather" /> {t("New")}
      </Button>
      <Modal size="xl" show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t("Create tenant")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Name")}</Form.Label>
                  <Form.Control
                    onChange={(e) => setName(e.target.value)}
                    required
                    placeholder={t("Name")} />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("URL")}</Form.Label>
                  <Form.Control
                    onChange={(e) => setUrl(e.target.value)}
                    placeholder={t("URL")} />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Environment")}</Form.Label>
                  <Form.Select onChange={(e) => setEnv(e.target.value)}>
                    <option>{t("-- Select --")}</option>
                    <option value={"PROD"}>{t("Production")}</option>
                    <option value={"STAG"}>{t("Staging")}</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <br />
            <hr />
            <Row>
              <Col xs="auto" className="ms-auto text-end mt-n1">
                <Button variant="secondary" onClick={() => toggle(false)}>
                  {t("Cancel")}
                </Button>{" "}
                <Button variant={"primary"} type="submit">
                  {t("Create")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddTenantModal;
