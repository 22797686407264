import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Card,
  Col,
  Container,
  Row,
  Breadcrumb,
  Dropdown,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {
  MoreHorizontal,
} from "react-feather";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { de } from 'date-fns/locale';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import ErrorHandler from "../Helper/ErrorHandler";
import { format, parseISO } from "date-fns";

const AssetRequestDetails = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [location, setLocation] = useState([]);
  // const [AssetLogs, setAssetLogs] = useState([]);
  const [AssetLogISTArray, setAssetLogISTArray] = useState([]);
  const [AssetLogDatesArray, setAssetLogDatesArray] = useState([]);
  const [AssetLogSOLLArray, setAssetLogSOLLArray] = useState([]);
  const [TodayUsageSum, setTodayUsageSum] = useState();
  const [TodayLastWeekUsageSum, setTodayLastWeekUsageSum] = useState();
  // const [MonthUsageSum, setMonthUsageSum] = useState();
  // const [LastMonthUsageSum, setLastMonthUsageSum] = useState();
  // const [AssetState, setAssetState] = useState();
  const [gatewayData, setGatewayData] = useState([]);
  const [filesData, setFilesData] = useState([]);
  const { t, i18n } = useTranslation();
  // const [next_service, setNextService] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [last_OEE, setLast_OEE] = useState([]);
  const [OEE_Array, setOEE_Array] = useState([{
    "interval_start": "2022-05-23T15:43:24.230Z",
    "availability": 0,
    "performance": 0,
    "quality": 0,
    "oee": 0
  }]);
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [DetailedUsageAmountArray, setDetailedUsageAmountArray] = useState([]);
  const [DetailedUsageIntervalDateArray, setDetailedUsageIntervalDateArray] = useState([]);

  useEffect(() => {
    axios
      .get("/assets/" + id)
      .then(function (response) {
        console.log(response.status, "Loaded asset by id");
        // handle success
        setData(response.data);
        // setLocation(response.data.location.coordinates)
        setLocation(response.data.location?.coordinates)
        setFilesData(response.data["files"]);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        // if (error.response.status == "422") {
        //   window.confirm("Error 422 \n\n" + t("Please enter the fields correctly. Just re-enter the content and try again."));
        // } else {
        //   window.confirm(error)
        // }
      });

    axios
      .get("/iot_gateways/by_asset_id", {
        params: {
          "asset_id": id,
        }
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "Load iot_gateways");
        setGatewayData(response.data);
      })
      .catch(function (error) {
        // handleError(error);
        console.log(error);
      });

    axios
      .get("/webservice/assets/" + id + "/planned_actual_usages")
      .then(function (response) {
        // handle success
        console.log(response.status, "Loaded planned_actual_usages");

        var dates = [];
        var SOLL = [];
        var IST = [];

        response.data.map(element => {
          const dateFormatted = format(parseISO(element["month_start"]), 'MMM yyyy', { locale: i18n.language === "de" ? de : null });
          dates.push(dateFormatted);
          SOLL.push(element["planned_usage"]);
          IST.push(element["actual_usage"]);
        });

        setAssetLogDatesArray(dates)
        setAssetLogSOLLArray(SOLL)
        setAssetLogISTArray(IST)

        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        setIsLoading(false);
        console.log(error);
      });

    setAssetLogISTArray([]);
    setAssetLogDatesArray([]);
    var ISTUsageArray = [];
    var ISTUsageDatesArray = [];

    axios
      .get("/assets/" + id + "/usage_logs/clustered", {
        params: {
          "asset_id": id,
          "period_start": new Date(new Date().getFullYear(), 0, 1).toISOString(),
          "clustering_unit": "D",
          "fill_gaps": true,
          "real_time": false
        }
      })
      .then(function (response) {
        // handle success

        response.data.sort((a, b) => (a.interval_start > b.interval_start))

        // // To collect all date from today
        var todaySubstring = format(new Date(), 'dd MMM');
        var todaysUsages = [];

        var todayLastWeekSubstring = format(new Date() - 1000 * 24 * 60 * 60 * 7, 'dd MMM');
        var todayLastWeekUsages = [];

        // // To collect all date from this month
        var monthSubstring = format(new Date(), 'MMM yyyy');
        var monthlyUsages = [];

        // // To collect all date from last month
        var lastMonthSubstring = format(new Date() - 1000 * 24 * 60 * 60 * 30, 'MMM yyyy');
        var lastMonthlyUsages = [];

        response.data.map(element => {
          const dateFormattedForGraph = format(parseISO(element["interval_start"]), 'dd MMM yyyy');
          ISTUsageDatesArray.push(dateFormattedForGraph);
          ISTUsageArray.push(element["amount"]);
          // console.log(dateFormattedForGraph, "->", element["usage"]);

          if (dateFormattedForGraph.includes(todaySubstring)) {
            todaysUsages.push(parseInt(element["amount"]));
          }
          if (dateFormattedForGraph.includes(todayLastWeekSubstring)) {
            todayLastWeekUsages.push(parseInt(element["amount"]));
          }
          if (dateFormattedForGraph.includes(monthSubstring)) {
            monthlyUsages.push(parseInt(element["amount"]));
          }
          if (dateFormattedForGraph.includes(lastMonthSubstring)) {
            lastMonthlyUsages.push(parseInt(element["amount"]));
          }
        });

        setDetailedUsageIntervalDateArray(response.data.map((entry) => { return format(parseISO(entry.interval_start), "dd MM", { locale: i18n.language === "de" ? de : null }) }));
        setDetailedUsageAmountArray(response.data.map((entry) => { return entry.amount }));

        setTodayUsageSum(todaysUsages.reduce((a, v) => a = a + v, 0))
        setTodayLastWeekUsageSum(todayLastWeekUsages.reduce((a, v) => a = a + v, 0))
        // setMonthUsageSum(monthlyUsages.reduce((a, v) => a = a + v, 0))
        // setLastMonthUsageSum(lastMonthlyUsages.reduce((a, v) => a = a + v, 0))
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    axios
      .get("/assets/" + id + "/oee", {
        params: {
          "asset_id": id,
          "date_start": "2022-03-01",
        }
      })
      .then(function (response) {
        console.log(response.status, "Load OEE. Items: ", response.data.length);
        setOEE_Array(response.data);
        setLast_OEE(response.data.slice(-1)[0]);
        // handle success
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    }, []);

  // const showLoadingSpinner = () => {
  //   return (
  //     <div>
  //       <Spinner animation="border" variant="secondary" size="sm" /> {t("Loading data")}
  //     </div>
  //   )
  // }

  function handleDelete(e) {
    e.preventDefault();
    axios
      .delete("/assets/" + id)
      .then(function (response) {
        console.log(response.status, "Deleted asset");
        // handle success
        navigate(-1);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        // window.confirm(error)
        setError({ showMessage: true, errorData: error })
      });
  }

  // const ChartPlannedVsActual = () => {
  //   const palette = usePalette();

  //   const data = {
  //     labels: AssetLogDatesArray,
  //     datasets: [
  //       {
  //         label: t("Actual Production"),
  //         fill: true,
  //         backgroundColor: "transparent",
  //         borderColor: palette.primary,
  //         data: AssetLogISTArray,
  //       },
  //       {
  //         label: t("Planned Production"),
  //         fill: true,
  //         backgroundColor: "transparent",
  //         borderColor: palette["primary-dark"],
  //         borderDash: [4, 4],
  //         data: AssetLogSOLLArray,
  //       },
  //     ],
  //   };

  //   const options = {
  //     maintainAspectRatio: false,
  //     legend: {
  //       display: false,
  //     },
  //     tooltips: {
  //       intersect: false,
  //     },
  //     hover: {
  //       intersect: true,
  //     },
  //     plugins: {
  //       filler: {
  //         propagate: false,
  //       },
  //     },
  //     scales: {
  //       xAxes: [
  //         {
  //           reverse: true,
  //           gridLines: {
  //             color: "rgba(0,0,0,0.05)",
  //           },
  //         },
  //       ],
  //       yAxes: [
  //         {
  //           ticks: {
  //             stepSize: 500,
  //           },
  //           display: true,
  //           borderDash: [5, 5],
  //           gridLines: {
  //             color: "rgba(0,0,0,0)",
  //             fontColor: "#fff",
  //           },
  //           // max: max(AssetLogISTArray, AssetLogSOLLArray) * 1.5
  //         },
  //       ],
  //     },
  //   };

  //   return (
  //     <Card className="flex-fill w-100">
  //       <Card.Header>
  //         <Card.Title tag="h5">{t("Asset Usage (Monthly)")}</Card.Title>
  //         {/* <h6 className="card-subtitle text-muted">
  //         A line chart is a way of plotting data points on a line.
  //       </h6> */}
  //       </Card.Header>
  //       <Card.Body>
  //         {
  //           isLoading === true ?

  //             showLoadingSpinner() :

  //             AssetLogISTArray.length === 0 && AssetLogSOLLArray.length === 0

  //               ?

  //               <h6 className="card-subtitle text-muted">
  //                 {t("No production data available.")}
  //               </h6>

  //               :

  //               <div className="chart">
  //                 <Line data={data} options={options} />
  //               </div>
  //         }

  //         {/* <Line data={data} options={options} /> */}
  //       </Card.Body>
  //     </Card>
  //   );
  // };

  // const AssetDetailedUsageChart = () => {
  //   const palette = usePalette();

  //   const data = {
  //     labels: DetailedUsageIntervalDateArray,
  //     datasets: [
  //       {
  //         label: t("Actual Production"),
  //         fill: true,
  //         backgroundColor: "transparent",
  //         borderColor: palette.primary,
  //         data: DetailedUsageAmountArray,
  //       },
  //     ],
  //   };

  //   const options = {
  //     maintainAspectRatio: false,
  //     legend: {
  //       display: false,
  //     },
  //     tooltips: {
  //       intersect: false,
  //     },
  //     hover: {
  //       intersect: true,
  //     },
  //     plugins: {
  //       filler: {
  //         propagate: false,
  //       },
  //     },
  //     scales: {
  //       xAxes: [
  //         {
  //           reverse: true,
  //           gridLines: {
  //             color: "rgba(0,0,0,0.05)",
  //           },
  //         },
  //       ],
  //       yAxes: [
  //         {
  //           ticks: {
  //             stepSize: 500,
  //           },
  //           display: true,
  //           borderDash: [5, 5],
  //           gridLines: {
  //             color: "rgba(0,0,0,0)",
  //             fontColor: "#fff",
  //           },
  //           // max: max(AssetLogISTArray, AssetLogSOLLArray) * 1.5
  //         },
  //       ],
  //     },
  //   };

  //   return (
  //     <Card className="flex-fill w-100">
  //       <Card.Header>
  //         <Card.Title tag="h5">{t("Asset Usage (Daily)")}</Card.Title>
  //         {/* <h6 className="card-subtitle text-muted">
  //         A line chart is a way of plotting data points on a line.
  //       </h6> */}
  //       </Card.Header>
  //       <Card.Body>
  //         {
  //           isLoading === true ?

  //             showLoadingSpinner() :

  //             DetailedUsageAmountArray.length == 0

  //               ?

  //               <h6 className="card-subtitle text-muted">
  //                 {t("No production data available.")}
  //               </h6>

  //               :

  //               <div className="chart">
  //                 <Line data={data} options={options} />
  //               </div>
  //         }

  //         {/* <Line data={data} options={options} /> */}
  //       </Card.Body>
  //     </Card>
  //   );
  // };

  // const AssetOEEChart = () => {
  //   const palette = usePalette();

  //   const data = [
  //     {
  //       name: t("OEE (%)"),
  //       type: "area",
  //       data: OEE_Array.map((e) => { return e.oee * 100 }),
  //     },
  //     {
  //       name: t("Availability (%)"),
  //       type: "area",
  //       data: OEE_Array.map((e) => { return e.availability * 100 }),
  //     },
  //     {
  //       name: t("Quality (%)"),
  //       type: "area",
  //       data: OEE_Array.map((e) => { return e.quality * 100 }),
  //     },
  //     {
  //       name: t("Performance (%)"),
  //       type: "area",
  //       data: OEE_Array.map((e) => { return e.performance * 100 }),
  //     },
  //   ];

  //   const options = {
  //     chart: {
  //       stacked: false,
  //     },
  //     stroke: {
  //       width: [2, 2],
  //       curve: "smooth",
  //     },
  //     plotOptions: {
  //       bar: {
  //         columnWidth: "100%",
  //       },
  //     },
  //     fill: {
  //       opacity: [0.85, 0.25],
  //       gradient: {
  //         inverseColors: false,
  //         shade: "light",
  //         type: "vertical",
  //         opacityFrom: 0.85,
  //         opacityTo: 0.55,
  //         stops: [0, 100, 100, 100],
  //       },
  //     },
  //     labels: OEE_Array.map((e) => { return format(parseISO(e.interval_start), "dd MMM yyyy", { locale: i18n.language === "de" ? de : null }) }),
  //     markers: {
  //       size: 0,
  //     },
  //     xaxis: {
  //       // type: "time",
  //       // max: 100,
  //       labels: {
  //         show: true,
  //         align: 'right',
  //         minWidth: 0,
  //         maxWidth: 160,
  //         style: {
  //           colors: palette.black,
  //         },
  //       },
  //     },
  //     yaxis: {
  //       min: 0,
  //       max: 100,
  //       labels: {
  //         show: true,
  //         align: 'right',
  //         minWidth: 0,
  //         maxWidth: 160,
  //         style: {
  //           colors: palette.black,
  //         },
  //         formatter: (value) => { return value },
  //       },
  //     },
  //     legend: {
  //       position: 'top',
  //       labels: {
  //         colors: palette.black,
  //         useSeriesColors: false
  //       },
  //     },
  //     tooltip: {
  //       shared: true,
  //       intersect: false,
  //       y: {
  //         formatter: function (y) {
  //           return y?.toLocaleString({ style: "percent", maximumFractionDigits: 2 });
  //         },
  //       },
  //     },
  //     colors: [
  //       palette.primary,
  //       palette.success,
  //       palette.warning,
  //       palette.info,
  //       palette.danger,
  //     ],
  //   };

  //   return (

  //     <Card className="flex-fill w-100">
  //       <Card.Header>
  //         <Card.Title tag="h5">{t("Overall Equipment Effectiveness (OEE) | History")}</Card.Title>
  //       </Card.Header>
  //       <Card.Body>
  //         {
  //           isLoading === true ?

  //             showLoadingSpinner() :

  //             OEE_Array.length === 0

  //               ?

  //               <h6 className="card-subtitle text-muted">
  //                 {t("No OEE data available.")}
  //               </h6>

  //               :

  //               <div className="chart">
  //                 <Chart options={options} series={data} type="line" height="340" />
  //               </div>
  //         }

  //         {/* <Line data={data} options={options} /> */}
  //       </Card.Body>
  //     </Card>
  //   );
  // };

  // const AssetOEEChart = () => {
  //   const palette = usePalette();

  //   const data = {
  //     labels: OEE_Array.map((e) => { return format(parseISO(e.interval_start), "dd MMM yyyy", { locale: i18n.language === "de" ? de : null }) }),
  //     datasets: [
  //       {
  //         label: t("OEE (%)"),
  //         fill: true,
  //         backgroundColor: "transparent",
  //         borderColor: palette.primary,
  //         data: OEE_Array.map((e) => { return e.oee * 100 }),
  //       },
  //       {
  //         label: t("Availability (%)"),
  //         fill: true,
  //         backgroundColor: "transparent",
  //         borderColor: palette.warning,
  //         data: OEE_Array.map((e) => { return e.availability * 100 }),
  //       },
  //       {
  //         label: t("Quality (%)"),
  //         fill: true,
  //         backgroundColor: "transparent",
  //         borderColor: palette.success,
  //         data: OEE_Array.map((e) => { return e.quality * 100 }),
  //       },
  //       {
  //         label: t("Performance (%)"),
  //         fill: true,
  //         backgroundColor: "transparent",
  //         borderColor: palette.info,
  //         data: OEE_Array.map((e) => { return e.performance * 100 }),
  //       },
  //     ],
  //   };

  //   const options = {
  //     maintainAspectRatio: false,
  //     legend: {
  //       display: false,
  //     },
  //     tooltips: {
  //       intersect: false,
  //     },
  //     hover: {
  //       intersect: true,
  //     },
  //     plugins: {
  //       filler: {
  //         propagate: false,
  //       },
  //     },
  //     scales: {
  //       xAxes: [
  //         {
  //           reverse: true,
  //           gridLines: {
  //             color: "rgba(0,0,0,0.05)",
  //           },
  //         },
  //       ],
  //       yAxes: [
  //         {
  //           ticks: {
  //             stepSize: 500,
  //           },
  //           display: true,
  //           borderDash: [5, 5],
  //           gridLines: {
  //             color: "rgba(0,0,0,0)",
  //             fontColor: "#fff",
  //           },
  //           //max: 100
  //         },
  //       ],
  //     },
  //   };

  //   return (
  //     <Card className="flex-fill w-100">
  //       <Card.Header>
  //         <Card.Title tag="h5">{t("Overall Equipment Effectiveness (OEE) | History")}</Card.Title>
  //       </Card.Header>
  //       <Card.Body>
  //         {
  //           isLoading === true ?

  //             showLoadingSpinner() :

  //             DetailedUsageAmountArray.length === 0

  //               ?

  //               <h6 className="card-subtitle text-muted">
  //                 {t("No production data available.")}
  //               </h6>

  //               :

  //               <div className="chart">
  //                 <Line data={data} options={options} />
  //               </div>
  //         }

  //         {/* <Line data={data} options={options} /> */}
  //       </Card.Body>
  //     </Card>
  //   );
  // };

  // const AssetStats = () => {

  //   var percentChangeUsageToday = ((TodayUsageSum - TodayLastWeekUsageSum) / TodayLastWeekUsageSum * 100);
  //   // var percentChangeUsageMonth = ((MonthUsageSum - LastMonthUsageSum) / LastMonthUsageSum * 100);

  //   return (
  //     <div className="w-100">
  //       <Row>
  //         <Col md="6" xl className="d-flex">
  //           <Card className="flex-fill">
  //             <Card.Body className=" py-4">
  //               <Row>
  //                 <Col className="mt-0">
  //                   <h5 className="card-title">{t("Units • Today")}</h5>
  //                 </Col>
  //                 <Col xs="auto">
  //                   <div className="stat stat-sm">
  //                     <ArrowUpRight className="align-middle text-success" />
  //                   </div>
  //                 </Col>
  //               </Row>
  //               <span className="h1 d-inline-block mt-1 mb-4">{TodayUsageSum == null ? "0" : TodayUsageSum.toLocaleString()}</span>
  //               <div className="mb-0">
  //                 <Badge bg=""
  //                   className={TodayUsageSum > TodayLastWeekUsageSum ? "badge-soft-success me-2" : "badge-soft-warning me-2"}>
  //                   {TodayLastWeekUsageSum == null ? 0 : TodayLastWeekUsageSum.toLocaleString(undefined, { maximumFractionDigits: 2 })}
  //                 </Badge>
  //                 <span className="text-muted">{t("Same day last week")}</span>
  //               </div>
  //             </Card.Body>
  //           </Card>
  //         </Col>
  //         <Col md="6" xl className="d-flex">
  //           <Card className="flex-fill">
  //             <Card.Body className=" py-4">
  //               <Row>
  //                 <Col className="mt-0">
  //                   <h5 className="card-title">{t("OEE")}</h5>
  //                 </Col>

  //                 <Col xs="auto">
  //                   <div className="stat stat-sm">
  //                     <Settings className="align-middle text-success" />
  //                   </div>
  //                 </Col>
  //               </Row>
  //               <span className="h1 d-inline-block mt-1 mb-4">{last_OEE.oee == null ? "0" : last_OEE.oee.toLocaleString(i18n.language === "de" ? de : "en", {
  //                 style: 'percent',
  //                 maximumFractionDigits: 0
  //               })}</span>
  //               <div className="mb-0">
  //                 <Badge bg="" className="badge-soft-success me-2">

  //                 </Badge>
  //                 <span className="text-muted">{last_OEE.interval_start == null ? "n/a" : format(parseISO(last_OEE.interval_start), 'd MMMM yyyy', { locale: i18n.language === "de" ? de : null })}</span>
  //               </div>
  //             </Card.Body>
  //           </Card>
  //         </Col>
  //         {/* <Col md="6" xl className="d-flex">
  //           <Card className="flex-fill">
  //             <Card.Body className=" py-4">
  //               <Row>
  //                 <Col className="mt-0">
  //                   <h5 className="card-title">{t("Units • Current Month")}</h5>
  //                 </Col>

  //                 <Col xs="auto">
  //                   <div className="stat stat-sm">
  //                     <ArrowUpRight className="align-middle text-success" />
  //                   </div>
  //                 </Col>
  //               </Row>
  //               <span className="h1 d-inline-block mt-1 mb-4">{MonthUsageSum == null ? "0" : MonthUsageSum.toLocaleString()}</span>
  //               <div className="mb-0">
  //                 <Badge bg="" className={percentChangeUsageMonth > 0 ? "badge-soft-success me-2" : "badge-soft-warning me-2"}>
  //                   {percentChangeUsageMonth.toLocaleString(undefined, { maximumFractionDigits: 2 })}%
  //                 </Badge>
  //                 <span className="text-muted">{t("Last month")}: {LastMonthUsageSum == null ? 0 : LastMonthUsageSum.toLocaleString()}</span>
  //               </div>
  //             </Card.Body>
  //           </Card>
  //         </Col> */}
  //         <Col md="6" xl className="d-flex">
  //           <Card className="flex-fill">
  //             <Card.Body className=" py-4">
  //               <Row>
  //                 <Col className="mt-0">
  //                   <h5 className="card-title">{t("Net Asset Value")}</h5>
  //                 </Col>
  //                 <Col xs="auto">
  //                   <div className="stat stat-sm">
  //                     <Activity className="align-middle text-success" />
  //                   </div>
  //                 </Col>
  //               </Row>
  //               <span className="h1 d-inline-block mt-1 mb-4">{data.market_value == null ? "n/a" : data.market_value.toLocaleString(i18n.language === "de" ? de : "en", {
  //                 style: 'currency',
  //                 maximumFractionDigits: 0,
  //                 currency: 'EUR',
  //               })}</span>
  //               <div className="mb-0">
  //                 <Badge bg="" className="badge-soft-success me-2">
  //                   {/* {AssetState == null ? "n/a" : formatDistanceToNow(parseISO(AssetState), { addSuffix: true })} */}
  //                 </Badge>
  //                 <span className="text-muted">{t("Current Market Value:")} TBA</span>
  //               </div>
  //             </Card.Body>
  //           </Card>
  //         </Col>
  //         <Col md="6" xl className="d-flex">
  //           <Card className="flex-fill">
  //             <Card.Body className=" py-4">
  //               <Row>
  //                 <Col className="mt-0">
  //                   <h5 className="card-title">{t("Next Service")}</h5>
  //                 </Col>
  //                 <Col xs="auto">
  //                   <div className="stat stat-sm">
  //                     <Users className="align-middle text-success" />
  //                   </div>
  //                 </Col>
  //               </Row>
  //               <span className="h1 d-inline-block mt-1 mb-4">{next_service == null || next_service.usages_until_next_service === undefined ? t("Not planned") : (next_service.usages_until_next_service + " " + t("units"))}</span>
  //               <ProgressBar
  //                 className="mb-3"
  //                 variant="primary"
  //                 striped
  //                 animated
  //                 now={next_service == null ? "" : next_service.percentage_since_last_service * 100}
  //                 label=""
  //               />
  //             </Card.Body>
  //           </Card>
  //         </Col>
  //       </Row>
  //     </div>
  //   );
  // };

  // const palette = usePalette();

  // const options = {
  //   map: "world_mill",
  //   regionStyle: {
  //     initial: {
  //       fill: palette["gray-200"],
  //     },
  //   },
  //   markerStyle: {
  //     initial: {
  //       r: 9,
  //       fill: palette.primary,
  //       "fill-opacity": 0.9,
  //       stroke: palette.white,
  //       "stroke-width": 7,
  //       "stroke-opacity": 0.4,
  //     },
  //     hover: {
  //       fill: palette.primary,
  //       "fill-opacity": 0.9,
  //       stroke: palette.primary,
  //       "stroke-width": 7,
  //       "stroke-opacity": 0.4,
  //     },
  //   },
  //   backgroundColor: "transparent",
  //   containerStyle: {
  //     width: "100%",
  //     height: "100%",
  //   },
  //   zoomOnScroll: true,
  //   markers: [
  //     {
  //       latLng: location,
  //       name: data.title,
  //     },
  //   ]
  // };

  // function openRealTimeMode(e) {
  //   e.preventDefault();

  //   const url = "http://5.45.97.158:3000/d/sF7d-FHZz/raspberry-pi-monitoring?orgId=1&refresh=1m&from=now-24h&to=now";
  //   open(url);
  // }

  // function open(url) {
  //   const win = window.open(url, "_blank");
  //   if (win != null) {
  //     win.focus();
  //   }
  // }

  // const renderConnectionState = (status) => {

  //   switch (status) {
  //     case "connected":
  //       return (
  //         <span className="badge bg-success">{t(status)}</span>
  //       )
  //     case "connection_lost":
  //       return (
  //         <span className="badge bg-warning">{t(status)}</span>
  //       )
  //     default:
  //       break;
  //   }
  // }

  return (
    <React.Fragment>
      <Helmet title={data.title} />
      <Container fluid className="p-0">
        <Breadcrumb>
          {/* <div className="vertical-center-all"> */}
          <Button variant="outline-primary" size="sm" className="me-2" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faArrowLeft} /> {t("Back")}
          </Button>
          <Breadcrumb.Item href="/assets">{t("Assets")}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t("Asset Request Details")}</Breadcrumb.Item>
          {/* </div> */}
        </Breadcrumb>
        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>{data.title}</h3>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Dropdown align={{ lg: 'end' }} className="d-inline">
              <Dropdown.Toggle variant="light" className="bg-white shadow-sm">
                <MoreHorizontal className="feather align-middle mt-n1" /> {t("More")}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Divider />
                <Dropdown.Item onClick={e => window.confirm(t("Are you sure you wish to delete this asset request?")) && handleDelete(e)}>{t("Remove asset")}</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={refreshPage}>{t("Refresh")}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        
        <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>
        <Row>
          <Col md="12">
            <Card>
              <Card.Body>
                <p>Coming soon - Dieses Feature wird erst freigegeben, wenn wir Crefo-Daten abfragen können.</p>

              </Card.Body>
            </Card>
          </Col>
          <Col>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

function refreshPage() {
  window.location.reload(false);
}


export default AssetRequestDetails;
