import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Dropdown, Form, Modal, OverlayTrigger, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import { parseISO } from "date-fns";
import UserDataManager from "../Helper/UserDataManager";
import { Crosshair, Plus } from "react-feather";
import { isMobile } from "react-device-detect";
import NotyfContext from "../../contexts/NotyfContext";

const EditGatewayrModal = (props) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [openModal, setOpenModal] = useState(false);

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  const notyf = useContext(NotyfContext);
  const [data, setData] = useState([]);

  useEffect(() => {

    if (openModal) {
      axios
        .get("/iot_gateways/" + id)
        .then(function (response) {
          // handle success
          console.log(response.status, "Loaded gateway details");
          setData(response.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }
  }, [openModal]);

  const [gateway_serial, set_gateway_serial] = useState(data.serial);
  const [passcode, set_passcode] = useState(data.passcode);
  const [internal_ip, set_internal_ip] = useState(data.internal_ip);
  const [tailscale_node_id, set_tailscale_node_id] = useState(data.tailscale_node_id);

  function handleSubmit(e) {
    e.preventDefault();

    if (gateway_serial === "") {
      setError({ showMessage: true, errorData: null, customMessage: t("Please enter a gateway serial.") })
    } else {
      setError({ showMessage: false, errorData: null })

      // hier sollte man im dropdown einen Kunden auswählen können, dessen ID dann im Post-Request gesetzt wird
      axios
        .put("/iot_gateways/" + id, {
          serial: gateway_serial,
          passcode: passcode,
          tailscale_node_id: tailscale_node_id,
          internal_ip: internal_ip,
        })
        .then(function (response) {
          // handle success
          console.log(response.status, "Load");
          navigate("/gateways/" + response.data.id);

          notyf.open({
            type: "success",
            message: t("New item \"") + gateway_serial + t("\" has been edited."),
            duration: "4500",
            ripple: false,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          })

          refreshPage();

        })
        .catch(function (error) {
          // handle error
          setError({ showMessage: true, errorData: error })
          console.log(error);
        });
    }
  }

  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <>
      <Dropdown.Item onClick={() => toggle()}>{t("Edit gateway")}</Dropdown.Item>
      <Modal size="ms" show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t("Edit gateway")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Gateway Serial")}</Form.Label>
                  <Form.Control
                    placeholder="CBIOT2050X__"
                    defaultValue={data.serial}
                    onChange={(e) => set_gateway_serial(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Passcode")}</Form.Label>
                  <Form.Control
                    placeholder="3478232"
                    defaultValue={data.passcode}
                    onChange={(e) => set_passcode(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Tailscale Node-ID")}</Form.Label>
                  <Form.Control
                    placeholder="Tailscale ID"
                    defaultValue={data.tailscale_node_id}
                    onChange={(e) => set_tailscale_node_id(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Internal IP")}</Form.Label>
                  <Form.Control
                    placeholder={t("Internal IP")}
                    defaultValue={data.internal_ip}
                    onChange={(e) => set_internal_ip(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <br />
            <hr />
            <Row>
              <Col xs="auto" className="ms-auto text-end mt-n1">
                <Button variant="secondary" onClick={() => toggle(false)}>
                  {t("Cancel")}
                </Button>{" "}
                <Button variant={"primary"} type="submit">
                  {t("Save")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditGatewayrModal;
