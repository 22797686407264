import {
  Col,
  Row,
  Modal,
  Card,
} from "react-bootstrap";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import energyImage from "../../assets/img/photos/energy.jpg";
import outputImage from "../../assets/img/photos/output.jpg";
import financingImage from "../../assets/img/photos/financing.jpg";
import maintenanceImage from "../../assets/img/photos/maintenance.jpg";
import SuccessStoryModal from "./SuccessStoryModal";

const ScheduleEnhanceModal = (props) => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  const NewCardWith = (props) => (
    <Col md={3}>
      <Card>
        <Card.Img width="100%" src={props.image} alt={"Card image" + props.title} />
        <Card.Header>
          <Card.Title tag="h5" className="mb-0">
            {props.title}
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Card.Text>
            {props.text}
          </Card.Text>
          {
            props.successStory !== "" && <SuccessStoryModal data={props} />
          }
          <Button href="mailto:sales@cap-on.de?subject=cap-on%20Enhance&body=Dear%20cap-on%20Team" variant="primary">
            {props.buttonTitle}
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );

  return (
    <>
      <Card.Link onClick={() => toggle()}>{t("Optimieren Sie Ihre Produktion")}</Card.Link>
      <Modal size="xl" show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t("Optimieren Sie Ihre Produktion")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <NewCardWith
              title="Energiemanagement"
              text="Behalten Sie den Energieverbrauch im Blick und senken Sie Ihre Kosten durch intelligentes Energiemanagement."
              image={energyImage}
              buttonTitle={t("Request a quote")}
              successStory={`Ein produzierendes Unternehmen in der Metallverarbeitung setzte sich zum Ziel, seinen Energieverbrauch zu senken, um sowohl die Kosten zu reduzieren als auch seine CO\u2082-Emissionen zu minimieren. Nach einer Analyse des Energieverbrauchs wurden mehrere Möglichkeiten zur Optimierung identifiziert, darunter die Erneuerung der Beleuchtungssysteme, die Optimierung von Druckluftsystemen und die Überarbeitung des Produktionsprozesses, um Energieverluste zu minimieren.

Das Unternehmen setzte diese Maßnahmen um und installierte beispielsweise energieeffiziente Beleuchtung, die den Energieverbrauch um 50% reduzierte, und optimierte seine Druckluftsysteme, wodurch der Energieverbrauch um 25% gesenkt wurde. Darüber hinaus wurden die Produktionsprozesse so umgestaltet, dass weniger Energie für die Herstellung der Produkte benötigt wurde.

Durch diese Optimierungsmaßnahmen konnte das Unternehmen den Energieverbrauch um insgesamt 30% senken und dadurch nicht nur seine Kosten um ein Viertel reduzieren, sondern auch seine CO\u2082-Emissionen signifikant senken. Außerdem verbesserte sich das Image des Unternehmens als umweltbewusster und nachhaltiger Betrieb, was sich positiv auf das Ansehen des Unternehmens bei Kunden und Geschäftspartnern auswirkte.

Diese Erfolgsgeschichte zeigt, dass durch eine systematische Analyse und Optimierung des Energieverbrauchs in der Industrie erhebliche Einsparungen möglich sind, die nicht nur die Kosten senken, sondern auch die Umwelt schützen und das Image des Unternehmens verbessern können.`} />
            <NewCardWith
              title="Ausbringungsoptimierung"
              text="Optimieren Sie Ihre Prozesse und erhöhen Sie die Effizienz Ihrer Produktion durch intelligente Bottleneckanalyse, Takzeitanalyse und Schichtmodelloptimierung."
              image={outputImage}
              buttonTitle={t("Request a quote")}
              successStory={`Ein produzierendes Unternehmen stellte fest, dass seine Produktionsprozesse nicht effizient genug waren und es dadurch zu Engpässen und Verzögerungen in der Fertigung kam. Um dieses Problem zu lösen, beschloss das Unternehmen, eine Ausbringungsoptimierung (Taktzeitoptimierung) durchzuführen, um die Produktionsprozesse zu verbessern und die Taktzeit zu reduzieren.

Nach einer detaillierten Analyse der Produktionsprozesse wurden mehrere Maßnahmen zur Optimierung identifiziert, darunter die Reduzierung von Rüstzeiten, die Optimierung der Arbeitsabläufe und die Automatisierung bestimmter Prozesse.
              
Das Unternehmen setzte diese Maßnahmen um und konnte dadurch die Taktzeit um 20% reduzieren, was zu einer erheblichen Kostenreduktion führte. Durch die Reduzierung der Rüstzeiten konnte das Unternehmen schneller auf Kundenanfragen reagieren und die Durchlaufzeiten für die Produktion reduzieren, was wiederum zu einer höheren Ausbringung und damit zu einer weiteren Kostenreduktion führte.
              
Darüber hinaus verbesserte sich durch die Taktzeitoptimierung auch die Qualität der Produkte, da es weniger Verzögerungen und Fehler in der Fertigung gab. Dies führte zu höherer Kundenzufriedenheit und mehr Aufträgen für das Unternehmen.
              
Insgesamt konnte das Unternehmen durch die Ausbringungsoptimierung (Taktzeitoptimierung) eine erhebliche Kostenreduktion erzielen, die sich positiv auf die Wirtschaftlichkeit des Unternehmens auswirkte und seine Wettbewerbsfähigkeit stärkte.`} />
            <NewCardWith
              title="(Predictive) Maintenance / Wartung"
              text="Reduzieren Sie Störzeiten und senken Sie Ihre Ersatzteilmanagement-Kosten durch datengesteuerte Maintenance-Optimierung, egal ob Sie bereits ein eigene SAP PM-Modul nutzen oder auf cap-on Datenbasis zurückgreifen."
              image={maintenanceImage}
              buttonTitle={t("Request a quote")}
              successStory={`Ein produzierendes Unternehmen stellte fest, dass es häufig zu unerwarteten Maschinenstillständen kam, die zu erheblichen Produktionsausfällen und damit zu hohen Kosten führten. Um dieses Problem zu lösen, entschied sich das Unternehmen, eine Wartungsoptimierung durchzuführen, um die Maschinenverfügbarkeit zu erhöhen und das Ersatzteilmanagement zu optimieren.

Zunächst analysierte das Unternehmen seine Wartungsprozesse und identifizierte mehrere Möglichkeiten zur Verbesserung, darunter die Reduzierung von Rüstzeiten, die Optimierung von Arbeitsabläufen und die Implementierung eines Ersatzteilmanagement-Systems.
              
Das Unternehmen setzte diese Maßnahmen um und konnte dadurch die Störzeiten um 30% reduzieren. Durch die Implementierung des Ersatzteilmanagement-Systems konnte das Unternehmen den Ersatzteilverbrauch loggen und in Relation zur Wartung setzen, um somit eine präzisere Planung und Vorhersage der Ersatzteilverfügbarkeit zu ermöglichen. Dies führte zu einer deutlichen Reduzierung der Kosten für Ersatzteile und zu einer besseren Planung der Wartung.
              
Darüber hinaus verbesserte sich die Qualität der Wartung und Reparatur, da die Daten und Informationen zum Ersatzteilmanagement zur Verfügung standen und die Wartungsintervalle und -verfahren entsprechend angepasst werden konnten. Das Unternehmen konnte somit präventiv handeln, bevor es zu Maschinenstillständen kam.
              
Insgesamt konnte das Unternehmen durch die Wartungsoptimierung seine Maschinenverfügbarkeit erhöhen, Störzeiten reduzieren und das Ersatzteilmanagement optimieren. Dies führte zu einer erheblichen Kostenreduktion und verbesserte die Produktivität und Wettbewerbsfähigkeit des Unternehmens.`} />
            <NewCardWith
              title="Smart Financing"
              text="Senken Sie Ihre Produktionskosten und steigern Sie Ihre Liquidität durch innovative Finanzierungslösungen, die auf Ihre individuellen Bedürfnisse zugeschnitten sind."
              image={financingImage}
              buttonTitle={t("Request a quote")}
              successStory={``} />
          </Row>
          {/* <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Payment Date")}</Form.Label>
                  <Form.Control
                    type="date"
                    onChange={e => setPaidOn(parseISO(e.target.value))}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Bank Reference")}</Form.Label>
                  <Form.Control
                    placeholder={t("Bank Reference")}
                    onChange={(e) => setBankRef(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => toggle(false)}>
            {t("Cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ScheduleEnhanceModal;
