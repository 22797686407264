import { Badge, Card, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Activity, Tool } from "react-feather";
import UserDataManager from "../Helper/UserDataManager";
import LoadingSpinner from "../Helper/LoadingSpinner";

const AssetTable = (data, title, isLoading, slim) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  function getConnectionStatus(item) {
    switch (item.connection_status) {
      case "connected":
        return <Badge bg="" className="badge-soft-success me-2">{t("connected")}</Badge>
      case "no_connection":
        return <Badge bg="" className="badge-soft-secondary me-2">{t("no_connection")}</Badge>
      case "disconnected":
        return <Badge bg="" className="badge-soft-danger me-2">{t("disconnected")}</Badge>
      default:
        return <span>-</span>
    }
  }

  const renderTable = () => {
    return data.map((asset) => {
      return (
        <tr key={asset.id} style={{ cursor: "pointer" }}>
          {/* <td>
            <span className={asset.status === "active" || asset.status === "approved" ? "badge bg-success" : "badge bg-warning"}>{getStatus(asset.status)}</span>
          </td> */}
          {/* {!slim && <td onClick={() => navigate(asset.status === "request" ? "/asset-requests/" + asset.id : "/assets/" + asset.id)} >{getRecentProductionStatus(asset)}</td>} */}
          {!slim && <td onClick={() => navigate(asset.status === "request" ? "/asset-requests/" + asset.id : "/assets/" + asset.id)} >{getConnectionStatus(asset)}</td>}
          {/* <td onClick={() => navigate(asset.status === "request" ? "/asset-requests/" + asset.id : "/assets/" + asset.id)} >{asset?.asset_number}</td> */}
          <td onClick={() => navigate(asset.status === "request" ? "/asset-requests/" + asset.id : "/assets/" + asset.id)} >{asset?.title}</td>
          <td onClick={() => navigate(asset.status === "request" ? "/asset-requests/" + asset.id : "/assets/" + asset.id)} >{asset?.client_name}</td>

          <td className="table-action">
            {/* <ArrowUpRight
                alt={t("Remote Configuration")}
                className="align-middle"
                size={16}
                style={{ cursor: "pointer" }}
                onClick={() => window.confirm(t("Dies funktioniert nur, wenn Sie sich im cap-on VPN befinden.")) && handleButtonClick("http://" + gateway?.internal_ip + ":1880")}
              />
              &nbsp;&nbsp;&nbsp; */}

            &nbsp;&nbsp;&nbsp;
            <Activity
              alt={t("Activity")}
              className="align-middle"
              size={16}
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/assets/" + asset?.id + "/?focus=digital-twin")}
            />

            {UserDataManager.getInstance().isAdmin() && <>
              &nbsp;&nbsp;&nbsp;
              <Tool
                alt={t("IoT-Setup")}
                className="align-middle"
                size={16}
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/assets/" + asset?.id + "/setup")}
              /></>}

            {/* &nbsp;&nbsp;&nbsp;
            <BarChart2
              alt={t("Usage Details")}
              className="align-middle"
              size={16}
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/assets/" + asset?.id + "/usage")}
            /> */}
            {/* &nbsp;&nbsp;&nbsp;
              <Eye
                alt={t("Insights")}
                className="align-middle"
                size={16}
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/assets/" + asset?.id + "/insights")}
              /> */}
          </td>

          {/* <td>{asset?.investment_price?.toLocaleString(i18n.language === "de" ? de : "en", {
            style: 'currency',
            maximumFractionDigits: 0,
            currency: 'EUR',
          })}</td> */}
        </tr>
      );
    });
  };

  return (
    <Card className="flex-fill w-100">
      {
        title === null ?
          <></>
          :
          <Card.Header>
            <Card.Title tag="h5" className="mb-0">
              {t(title)}
            </Card.Title>
          </Card.Header>
      }
      {/* <Card.Body> */}
      {
        isLoading ?
          <LoadingSpinner withText /> :
          data != null && data.length > 0
            ?
            <Table responsive hover>
              <thead>
                <tr>
                  {/* <th scope="col">{t("Status")}</th> */}
                  {/* {!slim && <th scope="col">{t("Recent Production")}</th>} */}
                  {!slim && <th scope="col">{t("PLC Connection")}</th>}
                  {/* <th scope="col">#</th> */}
                  <th scope="col">{t("Title")}</th>
                  <th scope="col">{t("Client")}</th>
                  <th scope="col">{t("Quick Actions")}</th>
                  {/* <th scope="col">{t("Investment Volume")}</th> */}
                  {/* <th scope="col">{t("Live View")}</th> */}
                </tr>
              </thead>
              <tbody>{renderTable()}</tbody>
            </Table>
            :
            <Card.Body>
              <p>{t("No data found.")}</p>
            </Card.Body>
      }
      {/* </Card.Body> */}
    </Card>
  );
};

export default AssetTable;
