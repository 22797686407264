import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Dropdown, Form, Modal, OverlayTrigger, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import { parseISO } from "date-fns";
import UserDataManager from "../Helper/UserDataManager";
import { Crosshair, Plus } from "react-feather";
import { isMobile } from "react-device-detect";
import NotyfContext from "../../contexts/NotyfContext";

const EditManufacturerModal = (props) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [openModal, setOpenModal] = useState(false);

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  const notyf = useContext(NotyfContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("/manufacturers/" + id)
      .then(function (response) {
        // handle success
        console.log(response.status, "Load");
        setData(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, []);

  const [name, setName] = useState(data.name);
  const [registration_number, set_registration_number] = useState(data.registration_number);
  const [tax_number, set_tax_number] = useState(data.tax_number);
  const [website, setWebsite] = useState(data.website);

  function handleSubmit(e) {
    e.preventDefault();

    setError({ showMessage: false, errorData: null })

    axios
      .put("/manufacturers/" + id, {
        "name": name,
        "registration_number": registration_number,
        "tax_number": tax_number,
        "website": website
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "Load");

        notyf.open({
          type: "success",
          message: t("Item \"") + name + t("\" updated."),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })

        refreshPage();
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <>
      <Dropdown.Item onClick={() => toggle()}>{t("Edit manufacturer")}</Dropdown.Item>
      <Modal size="xl" show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t("Edit manufacturer")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Name")}</Form.Label>
                  <Form.Control required onChange={(e) => setName(e.target.value)} defaultValue={data.name} placeholder="Name" />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Registration Number")}</Form.Label>
                  <Form.Control onChange={(e) => set_registration_number(e.target.value)} defaultValue={data.registration_number} placeholder="HRB 1234" />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Tax Number")}</Form.Label>
                  <Form.Control onChange={(e) => set_tax_number(e.target.value)} defaultValue={data.tax_number} placeholder="ABC 123 DEF" />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Website")}</Form.Label>
                  <Form.Control onChange={(e) => setWebsite(e.target.value)} placeholder="https://" />
                </Form.Group>
              </Col>
            </Row>
            <br />
            <hr />
            <Row>
              <Col xs="auto" className="ms-auto text-end mt-n1">
                <Button variant="secondary" onClick={() => toggle(false)}>
                  {t("Cancel")}
                </Button>{" "}
                <Button variant={"primary"} type="submit">
                  {t("Save")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditManufacturerModal;
