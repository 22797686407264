import {
  Modal,
} from "react-bootstrap";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const SuccessStoryModal = (props) => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  return (
    <>
      <Button className="me-1 mb-3" onClick={() => toggle()} variant="outline-primary">Success Story</Button>
      <Modal size="xl" show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{props.data?.title}: Success Story</Modal.Header>
        <Modal.Body className="m-3">
          { props.data.successStory }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => toggle(false)}>
            {t("OK")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SuccessStoryModal;
