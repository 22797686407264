import React, { useEffect, useState } from "react";
import { Alert, Badge, Button, Card, Col, Form, Modal, Nav, Row, Tab, Table } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import { endOfDay, format, parseISO, startOfDay } from "date-fns";
import { de } from "date-fns/locale";
import { zonedTimeToUtc } from "date-fns-tz";
import usePalette from "../../hooks/usePalette";
import GenericChart from "./Charts/GenericChartComponent";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import energyImage from "../../assets/img/photos/energy.jpg";
import outputImage from "../../assets/img/photos/output.jpg";
import financingImage from "../../assets/img/photos/financing.jpg";
import maintenanceImage from "../../assets/img/photos/maintenance.jpg";
import maagBackgroundImage from '../../assets/img/backgroundImages/maagBackground.jpg';
import mainLogoEttligner from "../../assets/img/companys/maag.png";
import connectivityLogo from "../../assets/maag/connectivity.png";
import UserDataManager from "../Helper/UserDataManager";

const AssetServices = ({ asset_id, data, show, handleClose }) => {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

  const [title, setTitle] = useState("");
  const [text, setText] = useState("");

  const cardDataEttlinger = [
    {
      title: "Monitoring & Watch Tower",
      text: (
        <>
          - <strong>Equipment purchased</strong><br />
          - Hardware Connectivity<br />
          - Ethernet - WiFi Access - IoT Gate<br />
          - API - Interface<br />
          - 360° View on the Asset - Basic Analytics<br />
          - Alert Functions<br />
          - Integration of different equipment and parameters<br />
        </>
      ),
      size: "4",
      buttonTitle: "Request a quote",
      image: "https://source.unsplash.com/800x600/?monitoring,watchtower"
    },
    {
      title: "Service and More",
      text: (
        <>
          - <strong>Equipment purchased or rented</strong><br />
          - Hardware Connectivity<br />
          - Ethernet - Cellular Access - IoT Gate<br />
          - API - Interface<br />
          - 360° View on the Asset - Advanced Analytics<br />
          - Alert Functions<br />
          - Integration of different equipment and parameters<br />
          - Service Flat Rate online/remote<br />
          - KI based analytics<br />
          - Predictive maintenance<br />
          - Spare parts on demand - invoiced<br />
        </>
      ),
      size: "4",
      buttonTitle: "Request a quote",
      image: "https://source.unsplash.com/800x600/?service,more"
    },
    {
      title: "Asset as a Service",
      text: (
        <>
          - <strong>Rented equipment</strong><br />
          - Hardware Connectivity<br />
          - Cellular Access - IoT Gate<br />
          - API - Interface<br />
          - 360° View on the Asset - Advanced Analytics<br />
          - Alert Functions<br />
          - Integration of different equipment and parameters<br />
          - Service Flat Rate online/remote<br />
          - KI based analytics<br />
          - Predictive maintenance<br />
          - Flat Rate Spare parts<br />
          - Flat Rate Spares & Service<br />
          - Read and write from remote/Remote troubleshooting<br />
        </>
      ),
      size: "4",
      buttonTitle: "Request a quote",
      image: "https://source.unsplash.com/800x600/?asset,service"
    }
  ];

  const cardData = [
    {
      title: "Data-Driven Insights and Analytics",
      text: (
        <>
          - <strong>Access to a comprehensive analytics platform</strong><br />
          - Custom reports on machine and process performance<br />
          - Trend and pattern recognition<br />
          - Recommendations for efficiency improvements
        </>
      ),
      size: "4",
      buttonTitle: "Active",
      image: "https://source.unsplash.com/800x600/?data,analytics"
    },
    {
      title: "Remote Monitoring and Support Services",
      text: (
        <>
          - <strong>Real-time machine condition monitoring</strong><br />
          - <strong>Remote error diagnosis and resolution</strong><br />
          - 24/7 technical support<br />
          - History and logging of all support cases
        </>
      ),
      size: "4",
      buttonTitle: "Request info",
      image: "https://source.unsplash.com/800x600/?remote,monitoring"
    },
    {
      title: "Predictive Maintenance as a Service",
      text: (
        <>
          - <strong>Real-time machine data monitoring</strong><br />
          - <strong>Predictive maintenance needs forecasting</strong><br />
          - Automated notifications for upcoming maintenance<br />
          - Detailed performance reports<br />
          - Remote error diagnosis
        </>
      ),
      size: "4",
      buttonTitle: "Request info",
      image: "https://source.unsplash.com/800x600/?maintenance,service"
    },
    {
      title: "Pay-per-Use (Equipment as a Service)",
      text: (
        <>
          - <strong>Provision of machines without purchase costs</strong><br />
          - <strong>Usage-based billing</strong> (per operating hour or produced unit)<br />
          - Includes maintenance and support<br />
          - Flexible contract durations
        </>
      ),
      size: "4",
      buttonTitle: "Request info",
      image: "https://source.unsplash.com/800x600/?machine,rental"
    },
    {
      title: "Performance-Based Contracts",
      text: (
        <>
          - <strong>Contracts based on machine performance</strong><br />
          - Defined performance metrics (availability, efficiency)<br />
          - Regular performance reports<br />
          - Incentives for optimal machine performance
        </>
      ),
      size: "4",
      buttonTitle: "Request info",
      image: "https://source.unsplash.com/800x600/?performance,contract"
    },
    {
      title: "Digital Twin Services",
      text: (
        <>
          - <strong>Creation of digital twins of machines</strong><br />
          - Simulation and optimization of machine behavior<br />
          - Detailed analysis and forecasting<br />
          - Integration into existing systems
        </>
      ),
      size: "4",
      buttonTitle: "Request info",
      image: "https://source.unsplash.com/800x600/?digital,twin"
    },
    // {
    //   title: "Consulting Services Based on Data Insights",
    //   text: (
    //     <>
    //       - <strong>Data-driven consulting services</strong><br />
    //       - Optimization of production processes<br />
    //       - Implementation of best practices<br />
    //       - Regular workshops and training sessions
    //     </>
    //   ),
    //   size: "4",
    //   buttonTitle: "Request info",
    //   image: "https://source.unsplash.com/800x600/?consulting,data"
    // },
  ];

  const NewCard = (props) => (
    <Col md={props.size} className="d-flex">
      <Card className="flex-fill">
        {props.image && <Card.Img width="100%" src={props.image} alt={"Card image" + props.title} />}
        <Card.Header style={props.isEttlinger && { backgroundColor: "rgba(255, 179, 43, 1.00)" }}>
          <Card.Title tag="h5" className="mb-0">
            {props.title}
          </Card.Title>
        </Card.Header>
        <Card.Body className="d-flex flex-column flex-grow-1">
          <Card.Text>
            {props.text}
          </Card.Text>
          <div className="mt-auto">
            <Button size="sm" href="mailto:peter.schilling@maag.com?subject=Ettlinger%20Service&body=Dear%20Ettlinger%20Team" variant={props.buttonTitle === "Active" ? "primary" : "outline-primary"}>
              {t(props.buttonTitle)}
            </Button>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header closeButton>{t(title)}</Modal.Header>
      <Modal.Body className="m-3" style={{ background: "#f5f5f5", padding: "20px" }}>
        <Row className="mb-4">
          <Col md="3">
            <img src={mainLogoEttligner} alt="test" height={"50"} />
          </Col>
          <Col md="9" className="ps-4">
            <h3>{t("MAAG Services")}</h3>
            <p>{t("We offer our customers professional support in the use and optimization of systems and machines.")} </p>
          </Col>
        </Row>

        {/* <Row>
          <Col md={8} className="d-flex">
            <Card className="flex-fill">
              <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                  {"Connectivity Kit"}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Card.Img width="100%" src={connectivityLogo} alt={"Card image"} />
                <Button className="mt-4" size="sm" href="mailto:peter.schilling@maag.com?subject=Ettlinger%20Service&body=Dear%20Ettlinger%20Team" variant="outline-primary">
                  {t("Request a quote")}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <NewCard
            title={t("Spare parts and consumables management")}
            text={t("Maximize efficiency and minimize downtime with our consumption and spare parts management service. Our 24/7 online ordering system ensures lightning-fast spare parts deliveries, while our preventive maintenance services optimize the condition of your equipment. Rely on us to reduce your operating costs and extend product life.")}
            size="4"
            buttonTitle={t("Request a quote")}
            successStory={``} />
        </Row> */}
        <hr />
        <Row className="mt-4">
          <h3 className="mb-4">Connectivity Services</h3>

          {cardDataEttlinger.map((card, index) => (
            <NewCard
              key={index}
              isEttlinger
              title={card.title}
              text={card.text}
              size={card.size}
              buttonTitle={card.buttonTitle}
            />
          ))}
        </Row>

        <hr />

        <Row className="mt-4">
          <h3 className="mb-4">More Services (coming soon)</h3>

          {cardData.map((card, index) => (
            <NewCard
              key={index}
              isEttlinger
              title={card.title}
              text={card.text}
              size={card.size}
              buttonTitle={card.buttonTitle}
            />
          ))}
        </Row>

        <hr />
        <Row className="mt-4">
          <h3 className="mb-4">More Services</h3>

          <NewCard
            title={t("Output optimization")}
            size="3"
            text={t("Optimize your processes and increase the efficiency of your production with intelligent bottleneck analysis, cycle time analysis and shift model optimization.")}
            image={outputImage}
            buttonTitle={t("Request a quote")}
            successStory={`Ein produzierendes Unternehmen stellte fest, dass seine Produktionsprozesse nicht effizient genug waren und es dadurch zu Engpässen und Verzögerungen in der Fertigung kam. Um dieses Problem zu lösen, beschloss das Unternehmen, eine Ausbringungsoptimierung (Taktzeitoptimierung) durchzuführen, um die Produktionsprozesse zu verbessern und die Taktzeit zu reduzieren.

Nach einer detaillierten Analyse der Produktionsprozesse wurden mehrere Maßnahmen zur Optimierung identifiziert, darunter die Reduzierung von Rüstzeiten, die Optimierung der Arbeitsabläufe und die Automatisierung bestimmter Prozesse.
              
Das Unternehmen setzte diese Maßnahmen um und konnte dadurch die Taktzeit um 20% reduzieren, was zu einer erheblichen Kostenreduktion führte. Durch die Reduzierung der Rüstzeiten konnte das Unternehmen schneller auf Kundenanfragen reagieren und die Durchlaufzeiten für die Produktion reduzieren, was wiederum zu einer höheren Ausbringung und damit zu einer weiteren Kostenreduktion führte.
              
Darüber hinaus verbesserte sich durch die Taktzeitoptimierung auch die Qualität der Produkte, da es weniger Verzögerungen und Fehler in der Fertigung gab. Dies führte zu höherer Kundenzufriedenheit und mehr Aufträgen für das Unternehmen.
              
Insgesamt konnte das Unternehmen durch die Ausbringungsoptimierung (Taktzeitoptimierung) eine erhebliche Kostenreduktion erzielen, die sich positiv auf die Wirtschaftlichkeit des Unternehmens auswirkte und seine Wettbewerbsfähigkeit stärkte.`} />

          <NewCard
            title={t("Energy Management")}
            size="3"
            text="Keep an eye on your energy consumption and reduce your costs with intelligent energy management."
            image={energyImage}
            buttonTitle={t("Request a quote")}
            successStory={`Ein produzierendes Unternehmen in der Metallverarbeitung setzte sich zum Ziel, seinen Energieverbrauch zu senken, um sowohl die Kosten zu reduzieren als auch seine CO\u2082-Emissionen zu minimieren. Nach einer Analyse des Energieverbrauchs wurden mehrere Möglichkeiten zur Optimierung identifiziert, darunter die Erneuerung der Beleuchtungssysteme, die Optimierung von Druckluftsystemen und die Überarbeitung des Produktionsprozesses, um Energieverluste zu minimieren.

Das Unternehmen setzte diese Maßnahmen um und installierte beispielsweise energieeffiziente Beleuchtung, die den Energieverbrauch um 50% reduzierte, und optimierte seine Druckluftsysteme, wodurch der Energieverbrauch um 25% gesenkt wurde. Darüber hinaus wurden die Produktionsprozesse so umgestaltet, dass weniger Energie für die Herstellung der Produkte benötigt wurde.

Durch diese Optimierungsmaßnahmen konnte das Unternehmen den Energieverbrauch um insgesamt 30% senken und dadurch nicht nur seine Kosten um ein Viertel reduzieren, sondern auch seine CO\u2082-Emissionen signifikant senken. Außerdem verbesserte sich das Image des Unternehmens als umweltbewusster und nachhaltiger Betrieb, was sich positiv auf das Ansehen des Unternehmens bei Kunden und Geschäftspartnern auswirkte.

Diese Erfolgsgeschichte zeigt, dass durch eine systematische Analyse und Optimierung des Energieverbrauchs in der Industrie erhebliche Einsparungen möglich sind, die nicht nur die Kosten senken, sondern auch die Umwelt schützen und das Image des Unternehmens verbessern können.`} />
          <NewCard
            title="(Predictive) Maintenance"
            size="3"
            text={t("Reduce downtimes and lower your spare parts management costs through data-driven maintenance optimization, regardless of whether you already use your own SAP PM module or have access to a cap-on database.")}
            image={maintenanceImage}
            buttonTitle={t("Request a quote")}
            successStory={`Ein produzierendes Unternehmen stellte fest, dass es häufig zu unerwarteten Maschinenstillständen kam, die zu erheblichen Produktionsausfällen und damit zu hohen Kosten führten. Um dieses Problem zu lösen, entschied sich das Unternehmen, eine Wartungsoptimierung durchzuführen, um die Maschinenverfügbarkeit zu erhöhen und das Ersatzteilmanagement zu optimieren.

Zunächst analysierte das Unternehmen seine Wartungsprozesse und identifizierte mehrere Möglichkeiten zur Verbesserung, darunter die Reduzierung von Rüstzeiten, die Optimierung von Arbeitsabläufen und die Implementierung eines Ersatzteilmanagement-Systems.
              
Das Unternehmen setzte diese Maßnahmen um und konnte dadurch die Störzeiten um 30% reduzieren. Durch die Implementierung des Ersatzteilmanagement-Systems konnte das Unternehmen den Ersatzteilverbrauch loggen und in Relation zur Wartung setzen, um somit eine präzisere Planung und Vorhersage der Ersatzteilverfügbarkeit zu ermöglichen. Dies führte zu einer deutlichen Reduzierung der Kosten für Ersatzteile und zu einer besseren Planung der Wartung.
              
Darüber hinaus verbesserte sich die Qualität der Wartung und Reparatur, da die Daten und Informationen zum Ersatzteilmanagement zur Verfügung standen und die Wartungsintervalle und -verfahren entsprechend angepasst werden konnten. Das Unternehmen konnte somit präventiv handeln, bevor es zu Maschinenstillständen kam.
              
Insgesamt konnte das Unternehmen durch die Wartungsoptimierung seine Maschinenverfügbarkeit erhöhen, Störzeiten reduzieren und das Ersatzteilmanagement optimieren. Dies führte zu einer erheblichen Kostenreduktion und verbesserte die Produktivität und Wettbewerbsfähigkeit des Unternehmens.`} />
          <NewCard
            title={t("Smart Financing")}
            size="3"
            text={t("Reduce your production costs and increase your liquidity with innovative financing solutions tailored to your individual needs.")}
            image={financingImage}
            buttonTitle={t("Request a quote")}
            successStory={``} />
        </Row>
        <br />
        <br />
        <hr />
        <br />
        <Row>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button variant="secondary" onClick={handleClose}>
              {t("Close")}
            </Button>
          </Col>
        </Row>
      </Modal.Body >
    </Modal >
  );
};

export default AssetServices;
