import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Breadcrumb, Card, Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import { Button } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { Play, RefreshCw } from "react-feather";
import { useTranslation } from "react-i18next";
import NotyfContext from "../../contexts/NotyfContext";
import ErrorHandler from "../Helper/ErrorHandler";
import { formatDistanceToNow, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "../Helper/LoadingSpinner";

const SystemRoutineDetails = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  let { name } = useParams();
  const [data, setData] = useState([]);
  const notyf = useContext(NotyfContext);
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [day_of_week, set_day_of_week] = useState();
  const [day_of_month, set_day_of_month] = useState();
  const [month_of_year, set_month_of_year] = useState();
  const [hour, setHour] = useState();
  const [minute, setMinute] = useState();
  const [timeDelta, setTimeDelta] = useState();
  const [active, setActive] = useState();

  useEffect(() => {
    getRoutine(null)
  }, []);

  function getRoutine(item) {
    item?.preventDefault();

    setIsLoading(true);

    setError({ showMessage: false, errorData: null })

    axios
      .get("/routines/" + name)
      .then(function (response) {
        // handle success
        // console.log(response.status, "routine")

        setData(response.data);

        set_day_of_week(response.data.schedule?.day_of_week)
        set_day_of_month(response.data.schedule?.day_of_month)
        set_month_of_year(response.data.schedule?.month_of_year)
        setHour(response.data.schedule?.hour)
        setMinute(response.data.schedule?.minute)
        setTimeDelta(response.data.schedule?.timedelta)
        setActive(response.data.active)

        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        setIsLoading(false);
        console.log(error);
      });
  }

  function handleSubmit() {

    var schedule = {
      day_of_month: day_of_month,
      day_of_week: day_of_week,
      month_of_year: month_of_year,
      hour: hour,
      minute: minute,
    }

    axios
      .put("/routines/" + name, {
        "schedule": schedule,
        "active": active,
        "options": null
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "Updated routine");

        notyf.open({
          type: "success",
          message: t("Routine " + t(name) + " updated."),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  function triggerRoutine() {
    axios
      .post("/routines/" + name)
      .then(function (response) {
        // handle success
        console.log(response.status, "Triggered routine");

        notyf.open({
          type: "success",
          message: t("Routine " + t(name) + " triggered."),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })

        getRoutine();

      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  const ScheduleUpdater = () => {
    return (
      <Form onSubmit={handleSubmit}>
        <Row>

          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>{t("day_of_week")}</Form.Label>
              <Form.Control
                value={day_of_week}
                onChange={(e) => set_day_of_week(e.target.value)}
                placeholder="*" />
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>{t("day_of_month")}</Form.Label>
              <Form.Control
                value={day_of_month}
                onChange={(e) => set_day_of_month(e.target.value)}
                placeholder="*" />
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>{t("month_of_year")}</Form.Label>
              <Form.Control
                value={month_of_year}
                onChange={(e) => set_month_of_year(e.target.value)}
                placeholder="*" />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("hour")}</Form.Label>
              <Form.Control
                type="number"
                value={hour}
                onChange={(e) => setHour(e.target.value)}
                placeholder="12" />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("minute")}</Form.Label>
              <Form.Control
                type="number"
                value={minute}
                onChange={(e) => setMinute(e.target.value)}
                placeholder="0" />
            </Form.Group>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col md="6">
            <Form.Group className="mb-3">
              <Form.Label>{t("Active")}</Form.Label>
              <Form.Check
                type="switch"
                onChange={() => setActive(!active)}
                defaultChecked={active}
                label={t("Activate routine")}
              />
            </Form.Group>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Button type="submit" variant="primary">
              {t("Save")}
            </Button>
          </Col>
        </Row>
      </Form >
    );
  };

  const ScheduleUpdaterTimeDelta = () => {
    return (
      <Form onSubmit={handleSubmit}>
        <Row>

          <Col md={12}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Time Delta")}</Form.Label>
              <Form.Control
                value={timeDelta}
                onChange={(e) => setTimeDelta(e.target.value)}
                placeholder="*" />
            </Form.Group>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col md="6">
            <Form.Group className="mb-3">
              <Form.Label>{t("Active")}</Form.Label>
              <Form.Check
                type="switch"
                onChange={() => setActive(!active)}
                defaultChecked={active}
                label={t("Activate routine")}
              />
            </Form.Group>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Button type="submit" variant="primary">
              {t("Save")}
            </Button>
          </Col>
        </Row>
      </Form >
    );
  };

  return (
    <React.Fragment>
      <Helmet title="cap-on • System Routine" />
      <Container fluid className="p-0">
        <Breadcrumb>
          {/* <div className="vertical-center-all"> */}
          <Button variant="outline-primary" size="sm" className="me-2" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faArrowLeft} /> {t("Back")}
          </Button>
          <Breadcrumb.Item href="/system-routines">{t("System Routines")}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t("Details")}</Breadcrumb.Item>
          {/* </div> */}
        </Breadcrumb>
        <Row className="mb-2 mb-xl-3">
          <Col>
            <h3>Routine Details: {t(name)}</h3>
          </Col>

          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button
              onClick={triggerRoutine}
              variant="outline-primary"
              className="shadow-sm me-1"
            >
              <Play className="feather" /> {t("Trigger")}
            </Button>
            <Button
              onClick={refreshPage}
              variant="primary"
              className="shadow-sm"
            >
              <RefreshCw className="feather" />
            </Button>
          </Col>
        </Row>
        {isLoading ?
          <LoadingSpinner />
          : <>
            <Row>
              <Col>
                <ErrorHandler error={error} />
              </Col>
            </Row>
            <Row>
              <Col md="6" className="d-flex">
                <Card className="flex-fill w-100">
                  {/* <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                  {t("Update backend-configuration")}
                </Card.Title>
              </Card.Header> */}
                  <Card.Header>
                    <Card.Title>
                      {t("Details")}
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Table>
                      <tbody>
                        <tr>
                          <th>{t("Status")}</th>
                          <td>
                            <span className={data.active === true ? "badge bg-success" : "badge bg-warning"}>{data?.active === true ? t("Active") : t("Inactive")}</span>
                          </td>
                        </tr>
                        <tr>
                          <th>{t("Name")}</th>
                          <td>{t(data.name)}</td>
                        </tr>
                        <tr>
                          <th>{t("Task")}</th>
                          <td>{t(data.task)}</td>
                        </tr>
                        <tr>
                          <th>{t("Last run at")}</th>
                          <td>{data == null ? "" : data.last_run_at == null ? "" : formatDistanceToNow(parseISO(data?.last_run_at), { locale: i18n.language === "de" ? de : null, addSuffix: true })}</td>
                        </tr>
                        <tr>
                          <th>{t("Total Run Count")}</th>
                          <td>{data.total_run_count}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="6" className="d-flex">
                <Card className="flex-fill w-100">
                  {/* <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                  {t("Update backend-configuration")}
                </Card.Title>
              </Card.Header> */}
                  <Card.Header>
                    <Card.Title>
                      {t("Schedule")}
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    {
                      data?.schedule?.timedelta ? <ScheduleUpdaterTimeDelta /> : <ScheduleUpdater />
                    }
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </>}
      </Container>
    </React.Fragment>
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default SystemRoutineDetails;
