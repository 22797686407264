import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row } from "react-bootstrap";
import React, { useEffect } from "react";
import WatchTowerList from "./WatchTowerList";
import { useTranslation } from "react-i18next";

const WatchTower = () => {
  const { t, i18n } = useTranslation();

  return (
    <React.Fragment>

      <Helmet title="cap-on • System Configuration" />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>{t("Watch Tower")}</h3>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            {/* <Button
              onClick={() => {
                setSelectedWatchTower(null)
                setShowWatchTowerModal(true)
              }}
              variant="primary"
              className="shadow-sm">
              <Plus className="feather" /> {t("New")}
            </Button> */}

            {/* <Button
              onClick={refreshPage}
              variant="primary"
              className="shadow-sm"
            >
              <RefreshCw className="feather" />
            </Button> */}
          </Col>
        </Row>
        <Row>
          <Col className="d-flex">
            <WatchTowerList watchtowers={null}/>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default WatchTower;
