import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import AddAssetModal from "./AssetModal_Add";
import AddContractModal from "../Contracts/ContractModal_Add";
import { Plus } from "react-feather";

const AddAssetToContractModal = (props) => {
  let { id } = useParams();
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [openModal, setOpenModal] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [selected_contract_id, set_selected_contract_id] = useState("id");

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  useEffect(() => {

    if (openModal) {

      // axios
      //   .get(getCoreBaseURL() + "/openapi.json")
      //   .then(function (response) {
      //     // handle success
      //     console.log(response.status, "Load");

      //   })
      //   .catch(function (error) {
      //     // handle error
      //     console.log(error);
      //   });

      axios
        .get("/contracts/")
        .then(function (response) {
          // handle success
          console.log(response.status, "/contracts");
          setContracts(response.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }
  }, [openModal]);


  function handleSubmit(e) {
    e.preventDefault();

  }

  const loadContracts = () => {
    return contracts?.map((asset) => {
      return (
        <option key={asset.id} value={asset.id} selected={asset.id === id}>{asset.title}</option>
      );
    });
  };


  return (
    <>
      <Button
        onClick={() => toggle()}
        variant="primary"
        className="shadow-sm me-1">
        <Plus className="feather" /> {t("Create asset")}
      </Button>
      <Modal size="m" show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t("Add Asset to existing contract")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>
          <Form onSubmit={handleSubmit}>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>{t("Contract")}</Form.Label>
                <Form.Select
                  name="state"
                  required
                  onChange={e => set_selected_contract_id(e.target.value)}>
                  <option value="">{t("-- Select --")}</option>
                  {loadContracts()}
                </Form.Select>
                <AddContractModal type="link" />
              </Form.Group>
            </Col>
            <br />
            <hr />
            <Row>
              <Col xs="auto" className="ms-auto text-end mt-n1">
                <Button variant="secondary" onClick={() => toggle(false)}>
                  {t("Cancel")}
                </Button>{" "}
                <AddAssetModal contract_id={selected_contract_id} />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddAssetToContractModal;
