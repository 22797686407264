import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../../Helper/ErrorHandler";
import ReactJson from 'react-json-view';
import DigitalTwinCard from "../AssetDigitalTwin/DigitalTwinCard";
import DefaultTwinStructure from "../AssetDigitalTwin/DigitalTwinStructures/DigitalTwinStructureDefault.json"
import { Clipboard } from "react-feather";

const SingleTwinStructureModal = ({ show, asset_id, configData, handleClose }) => {
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [twinJSON, setTwinJSON] = useState("{}");

  useEffect(() => {
    if (show && configData) {

      // console.log("---->", JSON.stringify(configData?.twin_structure, null, 2))
      try {
        // Convert the twin_structure object to a JSON string and set it in the state
        setTwinJSON(JSON.stringify(configData?.twin_structure, null, 2));
      } catch (error) {
        console.error("Error parsing twin_structure", error);
        setTwinJSON("{}"); // Optionally set an empty JSON object
      }
    } else {
      setTwinJSON("{}");
    }
  }, [show, configData]);

  function handleUpdate(e) {
    e.preventDefault();

    // Ensure twinJSON is parsed into a JSON object if it's currently a string
    let twinStructure;
    try {
      twinStructure = JSON.parse(twinJSON);
    } catch (error) {
      console.error("Invalid JSON format", error);
      setError({ showMessage: true, errorData: "Invalid JSON format" });
      return;
    }

    axios
      .put(`/assets/${asset_id}/iot_configs`, {
        twin_structure: twinStructure,
      })
      .then(function (response) {
        console.log(response.status, "/iot_configs PUT");
        // handle success
        handleClose();
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setError({ showMessage: true, errorData: error });
      });
  }

  let parsedJSON;
  try {
    parsedJSON = JSON.parse(twinJSON);
  } catch (error) {
    parsedJSON = {}; // Set to empty object if parsing fails
  }

  const extractPLCNodeIds = (structure) => {
    let plcNodeIds = [];

    const traverse = (node) => {
      if (node.plc_node_id) {
        plcNodeIds.push(node.plc_node_id);
      }

      // Traverse "children" if they exist
      if (node.children && node.children.length > 0) {
        node.children.forEach(child => traverse(child));
      }

      // Traverse "tabs" if they exist
      if (node.tabs && node.tabs.length > 0) {
        node.tabs.forEach(tab => traverse(tab));
      }
    };

    traverse(structure);
    return plcNodeIds;
  };

  const UnusedDataPoints = ({ updatedTwinJSON }) => {

    const twinStructurePLCNodeIds = extractPLCNodeIds(updatedTwinJSON);

    // console.log("--->", twinStructurePLCNodeIds)

    // configData?.items?.forEach(item => {
    //   console.log("--->", item.log_type.plc_node_id)
    // })

    let missingDataPoints = configData?.items?.filter(dataPoint =>
      // !twinStructurePLCNodeIds.includes(dataPoint.log_type.plc_node_id)
      !twinStructurePLCNodeIds.includes(dataPoint.log_type.plc_node_id)
    );

    // console.log(`Found ${twinStructurePLCNodeIds.length} plc_node_ids. Missing data points ${missingDataPoints.length}`)


    return (<Card>
      <Card.Body>
        <div className="h4 mb-3">{t("Unused data points")}</div>
        <ul>
          {missingDataPoints?.length > 0
            ? missingDataPoints?.map((item, index) => {

              const escapedPlcNodeId = item?.log_type?.plc_node_id.replace(/\\/g, '\\\\').replace(/"/g, '\\"');

              return (
                <li key={index}>
                  <strong>{t(item?.log_type?.technical_name || item?.log_type?.display_name)}</strong>: {t(item?.log_type?.plc_node_id)} <Button onClick={() => navigator.clipboard.writeText(escapedPlcNodeId)} size="sm"
                    variant="outline-primary">
                    <Clipboard className="feather" /></Button>
                </li>)
            }
            )
            : <li key={"index"}>All used</li>
          }
        </ul>
      </Card.Body>
    </Card>)
  }

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header closeButton>{t("Editor: Digital Twin Structure")}</Modal.Header>
      <Modal.Body className="m-3">
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        <Row>
          <Form onSubmit={handleUpdate}>
            <Row>
              <Col md={6}>
                <Row>
                  <Col xs="auto" className="ms-auto text-end">
                    <Button size="sm" variant="outline-primary" className="shadow-sm me-1" onClick={() => setTwinJSON(JSON.stringify(DefaultTwinStructure, null, 2))}>
                      {t("Use default")}
                    </Button>
                  </Col>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Formatted")}</Form.Label>

                  <Form.Control
                    rows="30"
                    as="textarea"
                    onChange={e => setTwinJSON(e.target.value)}
                    value={twinJSON}
                    style={{ display: 'none' }} // Hide the textarea
                  />
                  <ReactJson
                    src={parsedJSON}
                    collapsed
                    onEdit={edit => setTwinJSON(JSON.stringify(edit.updated_src, null, 2))}
                    onAdd={add => setTwinJSON(JSON.stringify(add.updated_src, null, 2))}
                    onDelete={del => setTwinJSON(JSON.stringify(del.updated_src, null, 2))}
                    theme="monokai"
                    style={{ padding: '20px', backgroundColor: '#2c2c2c', borderRadius: '5px' }}
                  />
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label >{t("Raw")}</Form.Label>
                  <Form.Control
                    rows="24"
                    as="textarea"
                    onChange={e => setTwinJSON(e.target.value)}
                    value={twinJSON}
                  />
                </Form.Group>
              </Col>

              <Col md="6">
                <DigitalTwinCard simulatedJsonData={parsedJSON} slim />

                <UnusedDataPoints updatedTwinJSON={parsedJSON} />
              </Col>
            </Row>

            <br />
            <br />
            <Row>
              <Col xs="auto" className="ms-auto text-end mt-n1">
                <Button variant="secondary" onClick={handleClose}>
                  {t("Cancel")}
                </Button>{" "}
                <Button variant={"primary"} type="submit">
                  {t("Apply")}
                </Button>
              </Col>
            </Row>
          </Form >
        </Row>
      </Modal.Body >
    </Modal >
  );
};

export default SingleTwinStructureModal;