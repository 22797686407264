import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Popover,
  Row,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {
  Eye,
  HelpCircle,
} from "react-feather";
import { useTranslation } from "react-i18next";
import { de } from 'date-fns/locale';
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import PowerConsumptionChart from "../Charts/PowerConsumptionChart";
import axios from "axios";
import usePalette from "../../../hooks/usePalette";

const ESGSection = ({ assetData, ESGContentLoaded }) => {

  const navigate = useNavigate();
  const [powerData, setPowerData] = useState([]);
  const { t, i18n } = useTranslation();
  const palette = usePalette();

  const [assetEcoData, setAssetEcoData] = useState();
  // ESG Simulator
  const [simulated_kWh, set_simulated_kWh] = useState(0);
  const [simulated_emissions, set_simulated_emissions] = useState(0);
  const [simulated_emissions_average, set_simulated_emissions_average] = useState(0);

  useEffect(() => {

    // Calculate initial kWh based on current time of day
    const calculateInitialKWh = () => {
      const now = new Date();
      const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const elapsedSeconds = (now - startOfDay) / 1000; // Convert milliseconds to seconds
      const energyConsumptionRatePerSecond = 0.0097;
      return elapsedSeconds * energyConsumptionRatePerSecond;
    };

    const calculateEmissionComparisons = () => {
      const householdAvgEmissions = 877 * 0.233;  // Avg household monthly kWh and CO2 factor
      return (calculateInitialKWh() * 0.233 / householdAvgEmissions);
    }

    // Set initial kWh
    set_simulated_kWh(calculateInitialKWh());
    set_simulated_emissions(calculateInitialKWh() * 0.434);
    set_simulated_emissions_average(calculateEmissionComparisons());

    // Update kWh every second

    const interval = setInterval(() => {
      set_simulated_kWh((prevKWh) => prevKWh + 0.0097);
      set_simulated_emissions(calculateInitialKWh() * 0.434);
      set_simulated_emissions_average(calculateEmissionComparisons());
      console.log("Ran simulation")
    }, 2500);

    // Cleanup on component unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {

    if (ESGContentLoaded) {

      axios
        .get("/webservice/assets/" + assetData?.id + "/eco")
        .then(function (response) {
          // handle success
          console.log(response.status, "/eco");
          setAssetEcoData(response.data)

        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      axios
        .get("/webservice/assets/" + assetData?.id + "/clustered_power_usage", {
          params: {
            "period_start": new Date(new Date() - 24 * 60 * 60 * 1000 * 14).toISOString(),
            "clustering_unit": "1 day"
          }
        })
        .then(function (response) {
          // handle success
          console.log(response.status, "/clustered_power_usage");
          setPowerData(response.data.sort((a, b) => (a.interval_start > b.interval_start)))
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }

  }, [ESGContentLoaded, assetData?.id]);

  const EmissionsCalculator = ({ kWh }) => {
    const [emissions, setEmissions] = useState({});
    const [householdComparison, setHouseholdComparison] = useState('');

    useEffect(() => {
      // const interval = setInterval(() => {
      calculateEmissions()
      //   console.log("Ran simulation()")
      // }, 1000);

      // // Cleanup on component unmount
      // return () => clearInterval(interval);
    }, []);

    const calculateEmissions = () => {
      const electricityCO2 = kWh * 0.233;  // Example factor
      const drivingCO2 = (kWh / 0.6) * 0.242;
      const heatingCO2 = (kWh / 8) * 2.93;

      const householdAvgEmissions = 877 * 0.233;  // Avg household monthly kWh and CO2 factor
      const householdComparison = (electricityCO2 / householdAvgEmissions * 100).toFixed(0);

      setEmissions({
        electricity: electricityCO2.toFixed(2),
        driving: drivingCO2.toFixed(2),
        heating: heatingCO2.toFixed(2)
      });
      setHouseholdComparison(
        `Today's CO2 emissions alone are equivalent to ${householdComparison}% of the average monthly household electricity emissions.`
      );
    };

    return (
      <div>
        <h1>CO2 Emissions Calculator</h1>
        <div>
          {/* <p>Electricity CO2 Emissions: {emissions?.electricity} kg</p>
          <p>Driving CO2 Emissions: {emissions?.driving} kg</p>
          <p>Heating CO2 Emissions: {emissions?.heating} kg</p> */}
          <p>{householdComparison}</p>
        </div>
      </div>
    );
  }

  return (<div style={{ background: palette["gray-200"], padding: "20px" }}>
    <Row className="mt-3 mb-4">
      <Col>
        <div className="h3">{t("Environmental Social Governance (ESG) Data")}</div>
      </Col>
      <Col xs="auto" className="ms-auto text-end mt-n1">
        <Button
          onClick={() => navigate("/assets/" + assetData?.id + "/insights")}
          variant="outline-primary"
          size="sm"
          className="shadow-sm me-1">
          <Eye className="feather" /> {!isMobile && t("Insights")}
        </Button>
      </Col>
    </Row>
    <Row>
      <Col md="3" xs="6" className="d-flex">
        <Card className="flex-fill">
          <Card.Body>
            <Row>
              <Col className="mb-2">
                <h5 className="card-title">{t("Power Consumption")}</h5>
              </Col>
              <Col xs="auto" className="ms-auto text-end">
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    assetEcoData?.power_usage_current_day !== 0 ?
                      <Popover>
                        <Popover.Header as='h3'>CO<sub>2</sub> {t("Emissions")}</Popover.Header>
                        <Popover.Body>
                          Die folgenden Stromverbrauchsdaten beziehen sich auf die <strong>heutige</strong> Produktion.
                          <br /> <br />

                          {t("Power Consumption")}: <strong>{(assetEcoData?.power_usage_current_day / 1000)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0 }) + " kWh"}</strong><br />
                          {t("Power Consumption")}/{t("unit")}: <strong>{(assetEcoData?.power_per_use_current_day / 1000)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 }) + " kWh/" + t("unit")}</strong>

                          <br /><br />

                          CO<sub>2</sub> {t("Emissions")}: <strong className="h5">{(assetEcoData?.power_usage_current_day * assetData?.co2_kg_per_wh)?.toLocaleString(i18n.language === "de" ? de : "en", {
                            style: 'decimal',
                            maximumFractionDigits: 2,
                          })} kg CO<sub>2</sub>e</strong><br />
                          CO<sub>2</sub> {t("Emissions")}/{t("unit")}: <strong className="h5">{(assetEcoData?.power_per_use_current_day * assetData?.co2_kg_per_wh)?.toLocaleString(i18n.language === "de" ? de : "en", {
                            style: 'decimal',
                            maximumFractionDigits: 2,
                          })} kg CO<sub>2</sub>e</strong>

                          <br /><br />

                          {/* Bei der Herstellung des <strong>heutigen</strong> Stroms wurden <strong className="h5">{(PowerUsageToday * assetData?.co2_kg_per_wh)?.toLocaleString(i18n.language === "de" ? de : "en", {
                                style: 'decimal',
                                maximumFractionDigits: 2,
                              })} kg CO<sub>2</sub>e</strong> freigesetzt.<br /><br /> */}
                          • 1 kWh entspricht {assetData?.co2_kg_per_wh * 1000} kg CO<sub>2</sub>e<br />
                          {/* {assetData?.co2_compensation === false ? t("Not compensated") : t("Compensated")}<br /> */}
                          • 1 kWh Strom kostet für Industriekunden ca. 0.25 EUR
                        </Popover.Body>
                      </Popover>
                      :
                      <Popover>
                        <Popover.Header as='h3'>CO<sub>2</sub> {t("Emissions")}</Popover.Header>
                        <Popover.Body>
                          CO<sub>2</sub> {t("Emissions")}/{t("unit")}: <strong>{assetData?.co2_per_use} g</strong> <br />
                          {t("Today")}: <strong>{(assetData?.co2_per_use * assetData?.usage_section?.usages_today).toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0 }) + " g"}</strong><br /><br />
                          {assetData?.co2_compensation === false ? t("Not compensated") : t("Compensated")}<br />
                        </Popover.Body>
                      </Popover>

                  }
                >
                  <HelpCircle className="feather" />
                </OverlayTrigger>
              </Col>
            </Row>
            {(assetData?.id === "aOVeu55" || assetData?.id === "al4VVdk") ? <span className="h1 d-inline-block mt-1 mb-4">{(simulated_kWh).toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 })} kWh</span> : assetEcoData?.power_usage_current_day !== 0
              ? <span className="h1 d-inline-block mt-1 mb-4">{(assetEcoData?.power_usage_current_day / 1000)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0 })} kWh</span>
              : <span className="h1 d-inline-block mt-1 mb-4">{((assetData?.co2_per_use * assetData?.usage_section?.usages_today) / 1000).toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 }) + " kg"}</span>}
            <div className="mb-0">
              <span className="text-muted">{t("Today")}</span>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md="3" xs="6" className="d-flex">
        <Card className="flex-fill">
          <Card.Body>
            <Row>
              <Col className="mb-2">
                <h5 className="card-title">
                  {
                    (assetData?.id === "aOVeu55" || assetData?.id === "al4VVdk")
                      ? t("Today's emission is about")
                      : t("Power per Unit")
                  }
                </h5>
              </Col>
            </Row>

            {
              (assetData?.id === "aOVeu55" || assetData?.id === "al4VVdk")
                ? <span className="h1 d-inline-block mt-1 mb-4">
                  {(simulated_emissions_average)?.toLocaleString(i18n.language === "de" ? de : "en", {
                    style: 'percent',
                    // currency: "EUR",
                    maximumFractionDigits: 2,
                  })
                  }</span>
                : <span className="h1 d-inline-block mt-1 mb-4">{(assetEcoData?.power_per_use_current_day / 1000)?.toLocaleString(i18n.language === "de" ? de : "en", {
                  style: 'decimal',
                  maximumFractionDigits: 2,
                })} kWh/{t("unit")}</span>
            }
            <div className="mb-0">
              {
                (assetData?.id === "aOVeu55" || assetData?.id === "al4VVdk")
                  ? <span className="text-muted">{t("of a U.S. household's monthly electricity consumption")}</span>
                  : <span className="text-muted">{t("Today")}</span>
              }

            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md="3" xs="6" className="d-flex">
        <Card className="flex-fill">
          <Card.Body>
            <Row>
              <Col className="mb-2">
                <h5 className="card-title">{t("Emissions")}</h5>
              </Col>
            </Row>

            <span className="h1 d-inline-block mt-1 mb-4">ca. {
              (assetData?.id === "aOVeu55" || assetData?.id === "al4VVdk")
                ? (simulated_emissions)?.toLocaleString(i18n.language === "de" ? de : "en", {
                  style: 'decimal',
                  maximumFractionDigits: 2,
                })
                : (assetEcoData?.power_usage_current_day * assetData?.co2_kg_per_wh)?.toLocaleString(i18n.language === "de" ? de : "en", {
                  style: 'decimal',
                  maximumFractionDigits: 2,
                })
            } kg CO<sub>2</sub>e</span>
            <div className="mb-0">
              <span className="text-muted">{t("Today")}</span>
              {/* <CompensationModal buttonSize="small" offsettable_co2e={assetData?.power_consumption?.all_time / 1000 * assetData?.co2_kg_per_wh} /> */}
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md="3" xs="6" className="d-flex">
        <Card className="flex-fill">
          <Card.Body>
            <Row>
              <Col className="mb-2">
                <h5 className="card-title">{t("Electricity Costs")}</h5>
              </Col>
            </Row>
            <span className="h1 d-inline-block mt-1 mb-4">
              ca. {
                (assetData?.id === "aOVeu55" || assetData?.id === "al4VVdk")
                  ? (simulated_kWh * 0.25)?.toLocaleString(i18n.language === "de" ? de : "en", {
                    style: 'currency',
                    currency: "EUR",
                    maximumFractionDigits: 2,
                  })
                  : (assetEcoData?.power_usage_current_day / 1000 * 0.25)?.toLocaleString(i18n.language === "de" ? de : "en", {
                    style: 'currency',
                    currency: "EUR",
                    maximumFractionDigits: 2,
                  })
              }</span>
            <div className="mb-0">
              <span className="text-muted">{t("Today")}</span>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
    {
      (assetData?.id !== "aOVeu55" && assetData?.id !== "al4VVdk") && <Row>
        <Col>
          <PowerConsumptionChart PowerData={powerData} />
        </Col>
      </Row>
    }

    {/* {
      (assetData?.id === "aOVeu55" || assetData?.id === "al4VVdk") && <Row>
        <Col>
          <EmissionsCalculator kWh={simulated_kWh} />
        </Col>
      </Row>
    } */}


  </div>)
}

export default ESGSection;
