import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card } from "react-bootstrap";
import SignIn from "../../components/auth/SignIn";
import mainLogo from "../../assets/company/cap-on_logo_black.png";
import mainLogoLight from "../../assets/company/cap-on_logo.png";
import mainLogoEttligner from "../../assets/img/companys/maag.png";
import mainLogotkAB from "../../assets/img/companys/tkAB.png";
import useTheme from "../../hooks/useTheme";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Divider } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserDataManager from "../Helper/UserDataManager";
import { faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import tqrQzGBLogo from "../../assets/company/tqrQzGB.png";
import tJYKgNALogo from "../../assets/company/tJYKgNA.png";
import sdlLogo from "../../assets/company/sdl.png";
import kontron_light from "../../assets/company/kontron_light.png";

import { getIAMBaseURL, isProdEnvironment } from "../../config";
import defaultBackgroundImage from '../../assets/img/backgroundImages/background_3.jpg';
import tkBackgroundImage from '../../assets/img/backgroundImages/tkImage3.jpg';
import wegomaBackgroundImage from "../../assets/company/wegomabackground.jpg"
import maagBackgroundImage from '../../assets/img/backgroundImages/maagBackground.jpg';
import isDemoMode from "../Helper/DemoMode";
import wegomaLogo from "../../assets/company/wegoma.png"
import linde_wiemann from "../../assets/company/linde-wiemann-light.png"

const SignInPageNew = () => {
  const { theme } = useTheme(); //onClick={setTheme(THEME.DEFAULT)}
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [passcodeParam, setPasscodeParam] = useSearchParams();
  const [backgroundImage, setBackgroundImage] = useState("");

  checkIfIsUserLoggedIn();

  function checkIfIsUserLoggedIn() {

    // This is relevant for Microsoft Login. If access_token is sent as a param in the URL the token is stored in localstorage
    if (passcodeParam.get("access_token") !== null && passcodeParam.get("access_token") !== undefined) {
      console.log("-heere->", passcodeParam.get("access_token"));
      localStorage.setItem('token', passcodeParam.get("access_token"))

      axios
        .get(getIAMBaseURL() + "/users/me", {
          headers: {
            'Authorization': "Bearer " + passcodeParam.get("access_token") //passcodeParam.get("access_token") !== null ? passcodeParam.get("access_token") : localStorage.getItem('token')
          }
        })
        .then(function (response) {
          // handle success
          console.log("Already logged in");
          navigate("/dashboard");
          UserDataManager.getInstance().setUserData(response.data);
          window.location.reload(false);
        })
        .catch(function (error) {
          // handle error
          console.log("Error with mechanism", passcodeParam.get("access_token"));
          localStorage.removeItem('token');
          console.log(error);
        });
    } else if (localStorage.getItem('token') === null || localStorage.getItem('token') === undefined) {
      console.log("Login required");
    } else {
      axios
        .get(getIAMBaseURL() + "/users/me")
        .then(function (response) {
          // handle success
          console.log("Already logged in");
          navigate("/dashboard");
          UserDataManager.getInstance().setUserData(response.data);
          // window.location.reload(false);
        })
        .catch(function (error) {
          // handle error
          console.log("Error with mechanism", passcodeParam.get("access_token"));
          localStorage.removeItem('token');
          console.log(error);
        });
    }
  }

  function isCustomerSubdomain() {
    switch (window.location.host) {
      case "ettlinger.cap-on.de":
      case "ettlinger.cap-on.io":
      case "maag.cap-on.de":
      case "maag.cap-on.io":
      case "siemens.cap-on.de":
      case "siemens.cap-on.io":
      case "kontron.cap-on.de":
      case "kontron.cap-on.io":
      case "sueddeutsche-leasing.cap-on.de":
      case "sueddeutsche-leasing.cap-on.io":
      case "thyssenkruppAB.cap-on.de":
      case "thyssenkruppAB.cap-on.io":
      case "linde-wiemann.cap-on.de":
      case "linde-wiemann.cap-on.io":
      case "wegoma.cap-on.de":
      case "wegoma.cap-on.io":
        return true
      default:
        return false
    }
  }

  const CustomLogo = () => {

    switch (window.location.host.toLowerCase()) {

      case "ettlinger.cap-on.de":
      case "ettlinger.cap-on.io":
        localStorage.setItem("tenant_id", "tWMPWtl");
        setBackgroundImage(maagBackgroundImage)
        return (
          <div className="text-center">
            {theme === "dark"
              ? <img src={tqrQzGBLogo} padding-top="20" alt="cap-on" style={{ height: "200px", paddingTop: "10px", paddingBottom: "10px" }} />
              : <img src={mainLogoEttligner} padding-top="20" alt="cap-on" style={{ height: "140px", paddingTop: "20px", paddingBottom: "20px" }} />
            }
          </div>
        )

      case "maag.cap-on.de":
      case "maag.cap-on.io":
        localStorage.setItem("tenant_id", "tWMPWtl");
        setBackgroundImage(maagBackgroundImage)
        return (
          <div className="text-center">
            {theme === "dark"
              ? <img src={tqrQzGBLogo} padding-top="20" alt="cap-on" style={{ height: "200px", paddingTop: "10px", paddingBottom: "10px" }} />
              : <img src={mainLogoEttligner} padding-top="20" alt="cap-on" style={{ height: "140px", paddingTop: "20px", paddingBottom: "20px" }} />
            }
          </div>
        )

      case "sueddeutsche-leasing.cap-on.de":
      case "sueddeutsche-leasing.cap-on.io":
        setBackgroundImage(defaultBackgroundImage)
        return (
          <div className="text-center">
            <img src={sdlLogo} padding-top="20" alt="cap-on" style={{ height: "140px", paddingTop: "20px", paddingBottom: "20px" }} />
          </div>
        )

      case "siemens.cap-on.de":
      case "siemens.cap-on.io":
        // localStorage.setItem("tenant_id", "tJYKgNA");
        setBackgroundImage(defaultBackgroundImage)
        return (
          <div className="text-center">
            <img src={tJYKgNALogo} padding-top="20" alt="cap-on" style={{ height: "200px", paddingTop: "0px", paddingBottom: "0px" }} />
          </div>
        )

      case "kontron.cap-on.de":
      case "kontron.cap-on.io":
        // localStorage.setItem("tenant_id", "tJYKgNA");
        setBackgroundImage(defaultBackgroundImage)
        return (
          <div className="text-center">
            <img src={kontron_light} padding-top="20" alt="cap-on" style={{ height: "160px", paddingTop: "0px", paddingBottom: "0px" }} />
          </div>
        )

      case "linde-wiemann.cap-on.de":
      case "linde-wiemann.cap-on.io":
        // localStorage.setItem("tenant_id", "tJYKgNA");
        setBackgroundImage(defaultBackgroundImage)
        return (
          <div className="text-center">
            <img src={linde_wiemann} padding-top="20" alt="cap-on" style={{ height: "140px", paddingTop: "50px", paddingBottom: "50px" }} />
          </div>
        )

      case "thyssenkruppab.cap-on.de":
      case "thyssenkruppab.cap-on.io":
        // localStorage.setItem("tenant_id", "tFER6vx");
        setBackgroundImage(tkBackgroundImage)
        return (
          <div className="text-center">
            <img src={mainLogotkAB} padding-top="20" alt="cap-on" style={{ height: "200px", paddingTop: "10px", paddingBottom: "10px" }} />
          </div>
        )

      case "wegoma.cap-on.de":
      case "wegoma.cap-on.io":
        // localStorage.setItem("tenant_id", "tFER6vx");
        setBackgroundImage(wegomaBackgroundImage)
        return (
          <div className="text-center">
            <img src={wegomaLogo} padding-top="20" alt="cap-on" style={{ height: "100px", paddingTop: "30px", paddingBottom: "30px" }} />
          </div>
        )

      // Bsp. localhost:300x, app.cap-on.de, hub.cap-on.de, test-app.cap-on.de
      default:

        setBackgroundImage(defaultBackgroundImage)
        return (
          <div className="text-center">
            {theme === "dark"
              ? <img src={mainLogoLight} padding-top="50" alt="cap-on" style={{ height: "140px", paddingTop: "10px", paddingBottom: "30px" }} />
              : <img src={mainLogo} padding-top="50" alt="cap-on" style={{ height: "140px", paddingTop: "10px", paddingBottom: "30px" }} />
            }
          </div>
        )
    }
  }

  const badgeStyle = {
    position: 'fixed',
    // top: 0,
    // right: 0,
    // position: 'absolute',
    top: '-20px',
    right: '80px',
    backgroundColor: 'orange',
    color: 'white',
    padding: '10px 100px',
    transform: 'translateX(50%) translateY(-50%) rotate(25deg)',
    transformOrigin: '0 0', // pivot from top right corner
    textAlign: 'center',
    fontWeight: 'bold',
    overflow: "hidden",
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 1)',
    zIndex: 1000, // Ensure it's above other elements
  };

  return (
    <React.Fragment>
      <Helmet title={t("Sign In")} />

      {/* <style>{'body { background: linear-gradient(45deg, #00A0C8, #F0007D) }'}</style>
      <Particles
        id="tsparticles"
        options={{
          background: {
            color: {
              value: "#000000000",
            },
          },
          fpsLimit: 60,
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: false,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 2,
                opacity: 0.8,
                size: 40,
              },
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 1.0,
              },
            },
          },
          particles: {
            color: {
              value: "#ffffff",
            },
            links: {
              color: "#ffffff",
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outMode: "bounce",
              random: false,
              speed: 0.5,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 80,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              random: true,
              value: 5,
            },
          },
          detectRetina: true,
        }}
      /> */}

      <style>{`
    body {
      background: url(${backgroundImage}) no-repeat center center fixed;
      background-size: cover;
      margin: 0; /* Remove default margin */
      height: 100%; /* Ensure minimum height is full viewport */
      overflow: auto; /* Fix overflow issue */
    }
  `}</style>

      {(isProdEnvironment()) || isCustomerSubdomain() ?
        null :
        <div style={badgeStyle}>
          Testumgebung
        </div>}


      <Card style={{ boxShadow: '0 4px 8px 0 rgba(0,0,0,0.8)' }}>
        <Card.Body>
          <div className="m-sm-4">

            <CustomLogo />

            {isCustomerSubdomain() && <div>
              <div className="text-center mt-0 mb-3">
                <span>Enabled by {theme === "dark"
                  ? <img src={mainLogoLight} alt="cap-on" style={{ height: "60px", paddingTop: "10px", paddingBottom: "10px" }} />
                  : <img src={mainLogo} alt="cap-on" style={{ height: "60px", paddingTop: "10px", paddingBottom: "10px" }} />
                }</span>
              </div>
            </div>}

            <SignIn />

            {isCustomerSubdomain() ? null : <>
              <Divider className="mt-4">{t("or log in with")}</Divider>
              <div className="text-center ">
                <Button
                  variant="outline-danger"
                  size="sm"
                  href={getIAMBaseURL() + `/login/microsoft/?current_url=${window.location.href}`} //?current_url="
                  className="btn-outline-microsoft mt-2 w-100 mt-4"
                >
                  <FontAwesomeIcon icon={faMicrosoft} />
                  &nbsp;
                  &nbsp;
                  {t("Sign in with Microsoft")}
                </Button>
              </div>
            </>}

          </div>
        </Card.Body>
      </Card>
    </React.Fragment >
  );
};

export default SignInPageNew;
